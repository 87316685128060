import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import zh from "react-intl/locale-data/zh";
import localeData from "./locales";

addLocaleData([...en, ...zh]);

// const defaultLocale = "en";

const defaultLocale =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  "en";

const languageWithoutRegionCode = defaultLocale.toLowerCase().split(/[_-]+/)[0];

const getMessages = locale => {
  return (
    localeData[locale] ||
    localeData[languageWithoutRegionCode] ||
    localeData[defaultLocale]
  );
};

export { defaultLocale, getMessages };
