import Enums from "../../../utils/Enums";

let doc = [
  {
    desc: "Group Hospital & Surgical Claim Form",
    file: require("./Form - Group GHS Claim. V03.2018.pdf"),
    fileName: "Form - Group GHS Claim. V03.2018.pdf"
  },
  {
    desc: "PRUworks Claims Portal Guide",
    file: require("./PRUworks Claims Portal Guide.pdf"),
    fileName: "PRUworks Claims Portal Guide.pdf",
    type: [Enums.P_CODE.PRU_CE, Enums.P_CODE.PRU_CE_EMULATE]
  },
  {
    desc: "PRUworks Claims Portal FAQ ",
    file: require("./Pruworks Claims Portal FAQ.pdf"),
    fileName: "Pruworks Claims Portal FAQ.pdf",
    type: [Enums.P_CODE.PRU_CE, Enums.P_CODE.PRU_CE_EMULATE]
  },
  {
    desc: "PRUworks Employee User Guide",
    file: require("./PRUworks Employee Portal Guide 2023.pdf"),
    fileName: "PRUworks Employee User Guide.pdf",
    type: [Enums.P_CODE.UOB_ASSISTED, Enums.P_CODE.UOB_TEAM_2]
  },
  {
    desc: "PRUworks Employee User Guide",
    file: require("./PRUworks Employee Portal Guide 2023.pdf"),
    fileName: "PRUworks Employee User Guide.pdf",
    type: [
      Enums.P_CODE.PRU_AGENCY,
      Enums.P_CODE.PRU_AGENCY_EMULATE,
      Enums.P_CODE.SCB_ASSISTED,
      Enums.P_CODE.PFA
    ]
  },
  {
    desc: "MHC Guide",
    file: require("./GCP_MHC_Guide.pdf"),
    fileName: "MHC Guide.pdf",
    type: [Enums.P_CODE.PRU_AGENCY, Enums.P_CODE.PRU_AGENCY_EMULATE],
    code: { GCP: [Enums.PLANS_CODE.GHSB] }
  },
  {
    desc: "Panel GP Listing",
    file: require("./GCP_Panel_GP_Listing.pdf"),
    fileName: "Panel GP Listing.pdf",
    type: [Enums.P_CODE.PRU_AGENCY, Enums.P_CODE.PRU_AGENCY_EMULATE],
    code: { GCP: [Enums.PLANS_CODE.GHSB] }
  },
  {
    desc: "Panel SP Listing",
    file: require("./GCP_Panel_SP_Listing.pdf"),
    fileName: "Panel SP Listing.pdf",
    type: [Enums.P_CODE.PRU_AGENCY, Enums.P_CODE.PRU_AGENCY_EMULATE],
    code: { GCP: [Enums.PLANS_CODE.GHSB] }
  },
  {
    desc: "Hospital Ward Charges",
    file: require("./GCP_Hospital_Ward_Charges.pdf"),
    fileName: "Hospital Ward Charges.pdf",
    type: [Enums.P_CODE.PRU_AGENCY, Enums.P_CODE.PRU_AGENCY_EMULATE],
    code: { GCP: [Enums.PLANS_CODE.GHSB] }
  }
];

export default doc;
