import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import MobileDetect from "mobile-detect";
import PDIH from "../../assets/data/plans/PF3/GPPD.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InfoButton from "../InfoButton";
import InfoPopover from "../InfoPopover";
import SimpleMessageDialog from "../../components/SimpleMessageDialog";
import Util from "../../utils/Util";
import "./style.css";

const mobileDetect = new MobileDetect(window.navigator.userAgent);

class PlanTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }
  render() {
    const {
      classes,
      tiers,
      selectionDisplayMode,
      isGFWM,
      isGADD,
      isGAMR,
      code,
      hasGAMR,
      extendedBenefit,
      isDependent
    } = this.props;
    const { data } = this.props;

    return (
      <div className={classes.root}>
        <Table className={"plans"}>
          <TableHead>
            <TableRow>
              {data.headers.map((header, _idxHeader) => {
                let classHighlight;
                if (tiers !== null && selectionDisplayMode !== null) {
                  let isSelected =
                    header.mandatory ||
                    (tiers && tiers.includes(header.tier)) ||
                    Util.isEmpty(tiers);
                  if (selectionDisplayMode === "SHOW") {
                    if (!isSelected) {
                      return null;
                    }
                  } else if (selectionDisplayMode === "HIGHLIGHT") {
                    classHighlight = !isSelected
                      ? classes.thCellDataDim
                      : classes.thCellDataHighlight;
                  }
                }

                return (
                  <TableCell
                    key={_idxHeader}
                    colSpan={
                      (header.attributes && header.attributes.colspan) || 1
                    }
                    style={header.attributes && header.attributes.style}
                  >
                    <div
                      className={classNames(classes.thCellData, classHighlight)}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: header.title
                        }}
                      />{" "}
                      {/* Render additional info (optional) */}
                      {header.info && (
                        <InfoButton
                          ref={`btnInfoHeader${_idxHeader}`}
                          id={`btnInfoHeader${_idxHeader}`}
                          data={header.info}
                          handleClickInfoButton={this.handleClickInfoButton}
                        />
                      )}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rows.map((row, _idxRow) => {
              let _colArray = [];
              let isShow = false;

              if (isGFWM && extendedBenefit) {
                if (_idxRow == 13 && extendedBenefit.benefitCode == "B") {
                  isShow = true;
                } else if (
                  _idxRow == 14 &&
                  extendedBenefit.benefitCode == "C"
                ) {
                  isShow = true;
                } else if (extendedBenefit.benefitCode == "D") {
                  isShow = true;
                } else if (
                  (_idxRow == 12 ||
                    _idxRow == 13 ||
                    _idxRow == 14 ||
                    _idxRow == 15) &&
                  extendedBenefit.benefitCode == "A"
                ) {
                  isShow = false;
                } else if (
                  (_idxRow != 13 && _idxRow != 14 && _idxRow != 15) ||
                  extendedBenefit.benefitCode == "A"
                ) {
                  isShow = true;
                }
              } else if (isGADD) {
                if (hasGAMR && _idxRow == 1) {
                  isShow = true;
                } else {
                  if (_idxRow == 0) {
                    isShow = true;
                  }
                }
              } else {
                isShow = true;
              }

              return (
                isShow && (
                  <TableRow key={_idxRow}>
                    {data.headers.map((header, _idx) => {
                      let col = Util.clone(row.columns[_idx]);

                      // Goes over the first 2 columns, corresponding to the index and name
                      if (_idx > 1) {
                        if (!Util.isEmpty(col)) {
                          if (Util.isEmpty(col.attributes)) {
                            //if attributes do not exist, populate
                            col.attributes = {
                              colspan: 1
                            };
                          } else if (Util.isEmpty(col.attributes.colspan)) {
                            //if attributes exist but no colspan, populate
                            col.attributes.colspan = 1;
                          }
                          const range =
                            col && col.attributes && col.attributes.colspan
                              ? col.attributes.colspan
                              : 1;
                          if (_colArray && _colArray.length == 0) {
                            //For the first element of the array
                            col.attributes.col_span_lower_bound = 0;
                            col.attributes.col_span_upper_bound = range;
                          } else {
                            //Logic for all the subsequent elements in the benefit
                            col.attributes.col_span_lower_bound =
                              _colArray[
                                _colArray.length - 1
                              ].attributes.col_span_upper_bound;
                            col.attributes.col_span_upper_bound =
                              _colArray[_colArray.length - 1].attributes
                                .col_span_upper_bound + range;
                          }
                          _colArray.push(col); //push to the array the elements with their new assigned upper and lower bounds
                        }

                        if (code !== PDIH.code) {
                          col = _colArray.find(i => {
                            return (
                              i.attributes.col_span_lower_bound < header.tier &&
                              header.tier <= i.attributes.col_span_upper_bound
                            );
                          });
                        }
                      } //Find the adequate benefit from the array based on their assigned bounds and selected tier

                      let classHighlight;
                      if (tiers !== null && selectionDisplayMode !== null) {
                        let isSelected =
                          header.mandatory ||
                          (tiers && tiers.includes(header.tier));
                        if (selectionDisplayMode === "SHOW") {
                          if (!isSelected) {
                            return null;
                          }
                        } else if (selectionDisplayMode === "HIGHLIGHT") {
                          let overwrite = false;
                          let hasColSpan =
                            col && col.attributes && col.attributes.colspan > 1;
                          if (hasColSpan) {
                            if (col.attributes.overwrite) {
                              overwrite = true;
                            }
                          }
                          classHighlight =
                            isSelected ||
                            (hasColSpan && !Util.isEmpty(tiers)) ||
                            overwrite
                              ? classes.thCellDataHighlight
                              : classes.thCellDataDim;
                        }
                      }
                      if (Util.isEmpty(col)) return null;

                      let gtlSA;
                      if (_idx === 1) {
                        gtlSA = "S$ 500,000";
                      } else if (_idx === 2) {
                        gtlSA = "S$ 200,000";
                      } else if (_idx === 3) {
                        gtlSA = "S$ 150,000";
                      } else if (_idx === 4) {
                        gtlSA = "S$ 80,000";
                      } else {
                        gtlSA = "S$ 50,000";
                      }

                      return (
                        <TableCell
                          key={_idx}
                          rowSpan={
                            (col.attributes && col.attributes.rowspan) || 1
                          }
                          colSpan={
                            (col.attributes && col.attributes.colspan) || 1
                          }
                          classes={{
                            root: classes.tbCellLast
                          }}
                        >
                          <div
                            className={classNames(
                              classes.tbCellData,
                              classHighlight
                            )}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  (code === "GTLB" ||
                                    code === "GCCA" ||
                                    code === "GEAC") &&
                                  isDependent
                                    ? col.dependentData
                                    : code === "GTLB" &&
                                      _idxRow === 0 &&
                                      _idx !== 0
                                    ? gtlSA
                                    : col.data
                              }}
                            />{" "}
                            {/* Render additional info (optional) */}
                            {col.info && (
                              <InfoButton
                                ref={`btnInfoBody${_idxRow}-${_idx}`}
                                id={`btnInfoBody${_idxRow}-${_idx}`}
                                data={col.info}
                                handleClickInfoButton={
                                  this.handleClickInfoButton
                                }
                              />
                            )}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )
              );
            })}
          </TableBody>
        </Table>

        {/* Info Popover || Dialog*/}
        {mobileDetect.phone() === null
          ? this.renderInfoPopover()
          : this.renderInfoDialog()}
      </div>
    );
  }

  handleClickInfoButton = (data, id) => event => {
    let infoPopover = {
      ...this.state.infoPopover
    };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this.refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
  };

  handleCloseDialog() {
    let infoPopover = {
      ...this.state.infoPopover,
      open: false
    };
    this.setState({ infoPopover });
  }

  renderInfoPopover = () => {
    const { infoPopover } = this.state;

    return (
      <InfoPopover
        open={infoPopover.open}
        data={infoPopover.data}
        element={infoPopover.element}
        handleClickInfoButton={this.handleClickInfoButton}
      />
    );
  };

  renderInfoDialog = () => {
    const { infoPopover } = this.state;

    return (
      <SimpleMessageDialog
        type="none"
        name="info"
        title="Info"
        description={infoPopover.data}
        alignCenter={false}
        isHtml={true}
        open={infoPopover.open}
        closeHandler={this.handleCloseDialog.bind(this)}
      />
    );
  };
}

const styles = theme => ({
  root: {
    width: "100%",
    overflow: "auto"
  },
  thCellData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tbCellData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  thCellDataHighlight: {
    opacity: 1
  },
  thCellDataDim: {
    opacity: 0.5
  },
  tbCellLast: {
    border: `1px solid ${theme.colors.blackScale.black70}`,
    "&:last-child": {
      padding: "4px 10px 4px 10px"
    }
  }
  // infoContainer: {   maxWidth: "60%" }, infoContent: {   padding: 10,
  // fontSize: ".75em" }
});

export default withStyles(styles, { withTheme: true })(PlanTable);
