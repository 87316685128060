import Enums from "../../../../utils/Enums";

// GHS ----------------------
let GHSBenefits = {
  headers: [
    {
      title: ``,
      mandatory: true,
      attributes: {
        style: {
          width: 8,
          padding: 2
        }
      }
    },
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "40%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    }
    // {
    //   title: `Plan 4`,
    //   tier: 4
    // },
    // {
    //   title: `Plan 5`,
    //   tier: 5
    // }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `1`
        },
        {
          data: `<strong>Daily Room and Board Benefit</strong>`,
          info: `(Per day, up to 120 days per disability)`
        },
        {
          data: `1-Bed Ward Private`
        },
        {
          data: `1-Bed Ward Government Restructured`
        },
        {
          data: `4-Bed Ward Government Restructured`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `2`
        },
        {
          data: `<strong>Intensive Care Unit (ICU)</strong>`,
          info: `(Max. per day, up to 30 days per disability)`
        },
        {
          data: `3 x 1-Bedded`
        },
        {
          data: `S$20,000`,
          info: `per disability limit for items 2 to 8`,
          attributes: {
            rowspan: 7
          }
        },
        {
          data: `S$15,000`,
          info: `per disability limit for items 2 to 8`,
          attributes: {
            rowspan: 7
          }
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `3`
        },
        {
          data: `<strong>High Dependency Ward (HDW)</strong>`,
          info: `<em>(Max. per day, up to 30 days per disability)</em>`
        },
        {
          data: `2 x 1-Bedded`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `4`
        },
        {
          data: `<strong>Other Hospital Services Benefits – Including implants</strong>`,
          info: `<em>(Max. per disability)</em>`
        },
        {
          data: `S$20,000`,
          info: `per disability<br> limit for items<br> 4 to 8`,
          attributes: {
            rowspan: "5"
          }
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `5`
        },
        {
          data: `<strong>Surgical Fees</strong>`,
          info: `<em>(subject to surgical schedule)</em>`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `6`
        },
        {
          data: `<strong>In-Hospital Doctor's Consultation Benefit</strong>`,
          info: `<em>(Max. 120 days)</em>`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `7`
        },
        {
          data: `<strong>Pre</strong> <em>(90 days)</em> <strong>&amp; Post</strong> <em>(90 days)</em>`,
          info: `<strong>Hospitalisation/Surgery, Specialist
          Consultation, Diagnostic X-Ray and
          Lab Test, Traditional Chinese Medicine</strong>`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `8`
        },
        {
          data: `<strong>Emergency Accidental Outpatient<br> Treatment Benefit</strong>`,
          info: `<em>(Including Accidental Dental Treatment)</em>`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `9`
        },
        {
          data: `<strong>Miscarriage Benefit</strong>`
        },
        {
          data: `Covered under benefits (items 1 to 8) ,<br> as per the respective benefit limits`,
          attributes: {
            colspan: "3"
          }
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `10`
        },
        {
          data: `<strong>Overseas Hospitalisation for Accident Benefit</strong>`
        },
        {
          data: `150% of GHS benefit (<em>for item 1 to 7</em>)`,
          attributes: {
            colspan: "3"
          }
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `11`
        },
        {
          data: `<strong>Rehabilitation Benefit</strong>`,
          info: `<em>(Max. per disability, up to 31 days)</em>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 3,000`
        },
        {
          data: `N.A.`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `12`
        },
        {
          data: `<strong>Death Benefit</strong>`
        },
        {
          data: `S$ 5,000`
        },
        {
          data: `S$ 3,000`
        },
        {
          data: `S$ 3,000`
        }
      ]
    }
  ],
  footnotes: []
};

let GHSPremiumEmployee = {
  title: ``,
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    }
    // {
    //   title: `Plan 4`,
    //   tier: 4
    // },
    // {
    //   title: `Plan 5`,
    //   tier: 5
    // }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 350.00`
        },
        {
          data: `S$ 177.00`
        },
        {
          data: `S$ 124.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 357.00`
        },
        {
          data: `S$ 184.00`
        },
        {
          data: `S$ 129.00`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 357.00`
        },
        {
          data: `S$ 191.00`
        },
        {
          data: `S$ 134.00`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 386.00`
        },
        {
          data: `S$ 203.00`
        },
        {
          data: `S$ 143.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 391.00`
        },
        {
          data: `S$ 234.00`
        },
        {
          data: `S$ 165.00`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 421.00`
        },
        {
          data: `S$ 303.00`
        },
        {
          data: `S$ 214.00`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 598.00`
        },
        {
          data: `S$ 370.00`
        },
        {
          data: `S$ 262.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 724.00`
        },
        {
          data: `S$ 503.00`
        },
        {
          data: `S$ 357.00`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 895.00`
        },
        {
          data: `S$ 658.00`
        },
        {
          data: `S$ 468.00`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 1,254.00`
        },
        {
          data: `S$ 874.00`
        },
        {
          data: `S$ 622.00`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`
        },
        {
          data: `S$ 1,795.00`
        },
        {
          data: `S$ 1,148.00`
        },
        {
          data: `S$ 818.00`
        }
      ]
    },
    {
      id: 12,
      columns: [
        {
          data: `<strong>70</strong>`
        },
        {
          data: `S$ 1,795.00`
        },
        {
          data: `S$ 1,148.00`
        },
        {
          data: `S$ 818.00`
        }
      ]
    }
  ],
  footnotes: []
};

// let GHSPremiumSpouseOrChild = {
//   title: `For Employee and Spouse or Children Coverage Only (inclusive of 7% GST)`,
//   headers: [
//     {
//       title: `Age Last Birthday`,
//       mandatory: true
//     },
//     {
//       title: `Plan 1`,
//       tier: 1
//     },
//     {
//       title: `Plan 2`,
//       tier: 2
//     },
//     {
//       title: `Plan 3`,
//       tier: 3
//     },
//     {
//       title: `Plan 4`,
//       tier: 4
//     },
//     {
//       title: `Plan 5`,
//       tier: 5
//     }
//   ],
//   rows: [
//     {
//       id: 1,
//       columns: [
//         {
//           data: `<strong>16 - 19</strong>`
//         },
//         {
//           data: `S$ 888.75`
//         },
//         {
//           data: `S$ 729.00`
//         },
//         {
//           data: `S$ 567.00`
//         },
//         {
//           data: `S$ 290.25`
//         },
//         {
//           data: `S$ 405.00`
//         }
//       ]
//     },
//     {
//       id: 2,
//       columns: [
//         {
//           data: `<strong>20 - 24</strong>`
//         },
//         {
//           data: `S$ 918.00`
//         },
//         {
//           data: `S$ 738.00`
//         },
//         {
//           data: `S$ 585.00`
//         },
//         {
//           data: `S$ 301.50`
//         },
//         {
//           data: `S$ 420.75`
//         }
//       ]
//     },
//     {
//       id: 3,
//       columns: [
//         {
//           data: `<strong>25 - 29</strong>`
//         },
//         {
//           data: `S$ 999.00`
//         },
//         {
//           data: `S$ 767.25`
//         },
//         {
//           data: `S$ 616.50`
//         },
//         {
//           data: `S$ 312.75`
//         },
//         {
//           data: `S$ 436.50`
//         }
//       ]
//     },
//     {
//       id: 4,
//       columns: [
//         {
//           data: `<strong>30 - 34</strong>`
//         },
//         {
//           data: `S$ 1,062.00`
//         },
//         {
//           data: `S$ 821.25`
//         },
//         {
//           data: `S$ 661.50`
//         },
//         {
//           data: `S$ 333.00`
//         },
//         {
//           data: `S$ 463.50`
//         }
//       ]
//     },
//     {
//       id: 5,
//       columns: [
//         {
//           data: `<strong>35 - 39</strong>`
//         },
//         {
//           data: `S$ 1,136.25`
//         },
//         {
//           data: `S$ 873.00`
//         },
//         {
//           data: `S$ 702.00`
//         },
//         {
//           data: `S$ 382.50`
//         },
//         {
//           data: `S$ 533.25`
//         }
//       ]
//     },
//     {
//       id: 6,
//       columns: [
//         {
//           data: `<strong>40 - 44</strong>`
//         },
//         {
//           data: `S$ 1,239.75`
//         },
//         {
//           data: `S$ 967.50`
//         },
//         {
//           data: `S$ 771.75`
//         },
//         {
//           data: `S$ 492.75`
//         },
//         {
//           data: `S$ 688.50`
//         }
//       ]
//     },
//     {
//       id: 7,
//       columns: [
//         {
//           data: `<strong>45 - 49</strong>`
//         },
//         {
//           data: `S$ 1,442.25`
//         },
//         {
//           data: `S$ 1,129.50`
//         },
//         {
//           data: `S$ 902.25`
//         },
//         {
//           data: `S$ 600.75`
//         },
//         {
//           data: `S$ 839.25`
//         }
//       ]
//     },
//     {
//       id: 8,
//       columns: [
//         {
//           data: `<strong>50 - 54</strong>`
//         },
//         {
//           data: `S$ 2,031.75`
//         },
//         {
//           data: `S$ 1,604.25`
//         },
//         {
//           data: `S$ 1,266.75`
//         },
//         {
//           data: `S$ 814.50`
//         },
//         {
//           data: `S$ 1,138.50`
//         }
//       ]
//     },
//     {
//       id: 9,
//       columns: [
//         {
//           data: `<strong>55 - 59</strong>`
//         },
//         {
//           data: `S$ 2,605.50`
//         },
//         {
//           data: `S$ 2,072.25`
//         },
//         {
//           data: `S$ 1,622.25`
//         },
//         {
//           data: `S$ 1,064.25`
//         },
//         {
//           data: `S$ 1,487.25`
//         }
//       ]
//     },
//     {
//       id: 10,
//       columns: [
//         {
//           data: `<strong>60 - 64</strong>`
//         },
//         {
//           data: `S$ 3,568.50`
//         },
//         {
//           data: `S$ 2,810.25`
//         },
//         {
//           data: `S$ 2,178.00`
//         },
//         {
//           data: `S$ 1,410.75`
//         },
//         {
//           data: `S$ 1,973.25`
//         }
//       ]
//     },
//     {
//       id: 11,
//       columns: [
//         {
//           data: `<strong>65 - 69</strong>`
//         },
//         {
//           data: `S$ 5,625.00`
//         },
//         {
//           data: `S$ 4,335.75`
//         },
//         {
//           data: `S$ 3,507.75`
//         },
//         {
//           data: `S$ 1,851.75`
//         },
//         {
//           data: `S$ 2,589.75`
//         }
//       ]
//     },
//     {
//       id: 12,
//       columns: [
//         {
//           data: `<strong>70 - 74</strong>`,
//           info: `For renewals only`
//         },
//         {
//           data: `S$ 7,733.25`
//         },
//         {
//           data: `S$ 5,960.25`
//         },
//         {
//           data: `S$ 4,817.25`
//         },
//         {
//           data: `S$ 2,533.50`
//         },
//         {
//           data: `S$ 3,543.75`
//         }
//       ]
//     }
//   ],
//   footnotes: []
// };

// let GHSPremiumSpouseAndChild = {
//   title: `For Employee and Spouse and Children Coverage Only (inclusive of 7% GST)`,
//   headers: [
//     {
//       title: `Age Last Birthday`,
//       mandatory: true
//     },
//     {
//       title: `Plan 1`,
//       tier: 1
//     },
//     {
//       title: `Plan 2`,
//       tier: 2
//     },
//     {
//       title: `Plan 3`,
//       tier: 3
//     },
//     {
//       title: `Plan 4`,
//       tier: 4
//     },
//     {
//       title: `Plan 5`,
//       tier: 5
//     }
//   ],
//   rows: [
//     {
//       id: 1,
//       columns: [
//         {
//           data: `<strong>16 - 19</strong>`
//         },
//         {
//           data: `S$ 1,382.50`
//         },
//         {
//           data: `S$ 1,134.00`
//         },
//         {
//           data: `S$ 882.00`
//         },
//         {
//           data: `S$ 451.50`
//         },
//         {
//           data: `S$ 630.00`
//         }
//       ]
//     },
//     {
//       id: 2,
//       columns: [
//         {
//           data: `<strong>20 - 24</strong>`
//         },
//         {
//           data: `S$ 1,428.00`
//         },
//         {
//           data: `S$ 1,148.00`
//         },
//         {
//           data: `S$ 910.00`
//         },
//         {
//           data: `S$ 469.00`
//         },
//         {
//           data: `S$ 654.50`
//         }
//       ]
//     },
//     {
//       id: 3,
//       columns: [
//         {
//           data: `<strong>25 - 29</strong>`
//         },
//         {
//           data: `S$ 1,554.00`
//         },
//         {
//           data: `S$ 1,193.50`
//         },
//         {
//           data: `S$ 959.00`
//         },
//         {
//           data: `S$ 486.50`
//         },
//         {
//           data: `S$ 679.00`
//         }
//       ]
//     },
//     {
//       id: 4,
//       columns: [
//         {
//           data: `<strong>30 - 34</strong>`
//         },
//         {
//           data: `S$ 1,652.00`
//         },
//         {
//           data: `S$ 1,277.50`
//         },
//         {
//           data: `S$ 1,029.00`
//         },
//         {
//           data: `S$ 518.00`
//         },
//         {
//           data: `S$ 721.00`
//         }
//       ]
//     },
//     {
//       id: 5,
//       columns: [
//         {
//           data: `<strong>35 - 39</strong>`
//         },
//         {
//           data: `S$ 1,767.50`
//         },
//         {
//           data: `S$ 1,358.00`
//         },
//         {
//           data: `S$ 1,092.00`
//         },
//         {
//           data: `S$ 595.00`
//         },
//         {
//           data: `S$ 829.50`
//         }
//       ]
//     },
//     {
//       id: 6,
//       columns: [
//         {
//           data: `<strong>40 - 44</strong>`
//         },
//         {
//           data: `S$ 1,928.50`
//         },
//         {
//           data: `S$ 1,505.00`
//         },
//         {
//           data: `S$ 1,200.50`
//         },
//         {
//           data: `S$ 766.50`
//         },
//         {
//           data: `S$ 1,071.00`
//         }
//       ]
//     },
//     {
//       id: 7,
//       columns: [
//         {
//           data: `<strong>45 - 49</strong>`
//         },
//         {
//           data: `S$ 2,243.50`
//         },
//         {
//           data: `S$ 1,757.00`
//         },
//         {
//           data: `S$ 1,403.50`
//         },
//         {
//           data: `S$ 934.50`
//         },
//         {
//           data: `S$ 1,305.50`
//         }
//       ]
//     },
//     {
//       id: 8,
//       columns: [
//         {
//           data: `<strong>50 - 54</strong>`
//         },
//         {
//           data: `S$ 3,160.50`
//         },
//         {
//           data: `S$ 2,495.50`
//         },
//         {
//           data: `S$ 1,970.50`
//         },
//         {
//           data: `S$ 1,267.00`
//         },
//         {
//           data: `S$ 1,771.00`
//         }
//       ]
//     },
//     {
//       id: 9,
//       columns: [
//         {
//           data: `<strong>55 - 59</strong>`
//         },
//         {
//           data: `S$ 4,053.00`
//         },
//         {
//           data: `S$ 3,223.50`
//         },
//         {
//           data: `S$ 2,523.50`
//         },
//         {
//           data: `S$ 1,655.50`
//         },
//         {
//           data: `S$ 2,313.50`
//         }
//       ]
//     },
//     {
//       id: 10,
//       columns: [
//         {
//           data: `<strong>60 - 64</strong>`
//         },
//         {
//           data: `S$ 5,551.00`
//         },
//         {
//           data: `S$ 4,371.50`
//         },
//         {
//           data: `S$ 3,388.00`
//         },
//         {
//           data: `S$ 2,194.50`
//         },
//         {
//           data: `S$ 3,069.50`
//         }
//       ]
//     },
//     {
//       id: 11,
//       columns: [
//         {
//           data: `<strong>65 - 69</strong>`
//         },
//         {
//           data: `S$ 8,750.00`
//         },
//         {
//           data: `S$ 6,744.50`
//         },
//         {
//           data: `S$ 5,456.50`
//         },
//         {
//           data: `S$ 2,880.50`
//         },
//         {
//           data: `S$ 4,028.50`
//         }
//       ]
//     },
//     {
//       id: 12,
//       columns: [
//         {
//           data: `<strong>70 - 74</strong>`,
//           info: `For renewals only`
//         },
//         {
//           data: `S$ 12,029.50`
//         },
//         {
//           data: `S$ 9,271.50`
//         },
//         {
//           data: `S$ 7,493.50`
//         },
//         {
//           data: `S$ 3,941.00`
//         },
//         {
//           data: `S$ 5,512.50`
//         }
//       ]
//     }
//   ],
//   footnotes: []
// };

// let GHSGroupDiscount = {
//   headers: [
//     {
//       title: `Group Size <i>Insured Employee<i>`
//     },
//     {
//       title: `Discount`
//     }
//   ],
//   rows: [
//     {
//       id: 1,
//       columns: [
//         {
//           data: `<strong>11 - 15</strong>`
//         },
//         {
//           data: `5%`
//         }
//       ]
//     },
//     {
//       id: 2,
//       columns: [
//         {
//           data: `<strong>16 and above</strong>`
//         },
//         {
//           data: `10%`
//         }
//       ]
//     }
//   ],
//   footnotes: []
// };

//   ----------------------------------------------------------------------

const GHS = {
  id: 2,
  code: "GHSB",
  label: "Group Hospital & Surgical (GHS)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GHSBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [
        GHSPremiumEmployee
        // GHSPremiumSpouseOrChild,
        // GHSPremiumSpouseAndChild
      ]
    }
    /*{
      id: 4,
      order: 4,
      type: Enums.PLANS_TYPE.GROUP_DISCOUNT,
      label: "Group Size Discount",
      data: [GHSGroupDiscount]
    }*/
  ]
};

export default GHS;
