import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {
    height: "100%",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
      marginRight: 10,
      marginTop: 80,
      paddingBottom: 10
    }
  },
  tabs: { marginBottom: theme.spacing.unit * 2 },
  content: {
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10
  },
  paperTitle: { marginBottom: theme.spacing.unit },
  titleBoxText: {
    fontSize: 16,
    fontWeight: "bold"
  },
  dependent: {
    flexGrow: 1
  },
  contentBoxText: {
    fontSize: 16
  },
  dependentText: {
    fontSize: 14,
    marginLeft: 30
  },
  titleLinkText: {
    fontWeight: 400,
    fontSize: 14,
    marginLeft: 15
  },
  paperTileStyle: {
    position: "relative",
    float: "left",
    width: "100%",
    minHeight: "200px",
    overflow: "hidden",
    height: "100% !important"
  },
  titleText: {
    fontWeight: 400,
    marginBottom: theme.spacing.unit,
    fontSize: 20
  },
  paper: {
    //padding: theme.spacing.unit * 2,
    height: "100%"
  },
  button: {
    margin: theme.spacing.unit
  },
  avatar: {
    width: 60,
    height: 60
  },
  tabContent: {
    padding: 20
  },
  dataContainer: {
    padding: 10,
    paddingBottom: 0
  },
  greyOutline: {
    borderColor: "silver",
    borderWidth: 2,
    borderRadius: 3,
    paddingBottom: 10,
    borderStyle: "solid",
    boxSizing: "border-box"
  }
});

const parseGender = (gender, props) =>
  props.intl.messages["user.gender." + gender];

const parseNationality = (nationality, props) => {
  return props.intl.messages["user.nationality." + nationality] || nationality;
};

const dependentData = [
  [
    {
      desc: "Relationship To Employee",
      key: "dependent"
    },
    {
      desc: "Dependant Full Name",
      key: "full_name"
    },
    {
      desc: "NRIC/FIN/Passport",
      key: "id_no"
    }
  ],
  [
    {
      desc: "Gender",
      key: "gender",
      fn: parseGender
    },
    { desc: "Date Of Birth", key: "dob" },
    {
      desc: "Nationality",
      key: "nationality",
      fn: parseNationality
    }
  ]
];

const dependentView = (props, dependent, index) => {
  const md = props.user.dependencies.length === 1 ? 12 : 6;
  return (
    <Grid
      item
      key={dependent.id}
      sm={12}
      md={md}
      className={props.classes.dependent}
    >
      <Paper className={props.classes.tabContent}>
        <Typography variant={"subheading"} className={props.classes.paperTitle}>
          Dependent {index + 1}
        </Typography>
        <CoverageBox
          startDate={dependent.coverage_start_date}
          endDate={dependent.coverage_end_date}
          {...props}
        />
        <Grid container>
          {dependentData.map((column, index) =>
            DataColumn({
              item: dependent,
              column,
              index,
              ...props
            })
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

const dependentViewWrapper = props => (dependent, index) =>
  dependentView(props, dependent, index);

const DependentsDetails = props => {
  const dependentWrapper = dependentViewWrapper(props);
  return (
    <Grid container spacing={"24px"}>
      {props.user.dependencies.map(dependentWrapper)}
    </Grid>
  );
};

const employeeData = [
  [
    {
      desc: "Employee Full Name",
      key: "fullName"
    },
    {
      desc: "NRIC/FIN/Passport",
      key: "passport_nric"
    },
    {
      desc: "Gender",
      key: "gender",
      fn: parseGender
    },
    {
      desc: "Date Of Birth",
      key: "dob"
    }
  ],
  [
    {
      desc: "Marital Status",
      key: "marital_status",
      fn: (ms, props) => {
        return props.intl.messages["user.maritalStatus." + ms];
      }
    },
    {
      desc: "Nationality",
      key: "nationality",
      fn: parseNationality
    },
    {
      desc: "Country Of Residence",
      key: "country_of_residence"
    },
    {
      desc: "Employment Start Date",
      key: "date_of_employment"
    },
    {
      desc: "Corporate Email Address",
      key: "email"
    }
  ]
];

const identity = i => i;

const DataView = ({ desc, data, key, fn, classes, item, ...props }) => {
  const itemRaw = item && item[key];
  const itemText = (item && (fn || identity)(itemRaw, props)) || data || "-";
  return (
    <div key={desc} className={classes.dataContainer}>
      <Typography variant={"caption"}>{desc}</Typography>
      <Typography variant={"body1"}>{itemText}</Typography>
    </div>
  );
};

const DataColumn = ({ column, index, ...props }) => (
  <Grid key={"column" + index} item sm={6} xs={12}>
    {column.map(view => DataView({ ...view, ...props }))}
  </Grid>
);

const CoverageBox = ({ startDate, endDate, ...props }) => {
  return (
    <Grid container spacing={"0px"} className={props.classes.greyOutline}>
      <Grid item xs={12} sm={6}>
        {DataView({ ...props, desc: "Coverage Start Date", data: startDate })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {DataView({ ...props, desc: "Coverage End Date", data: endDate })}
      </Grid>
    </Grid>
  );
};

const MyDetails = props => (
  <Paper className={props.classes.tabContent}>
    <CoverageBox
      {...props}
      startDate={props.user.coverage_start_date}
      endDate={props.user.coverage_end_date}
    />
    <Grid container spacing={"0px"}>
      {employeeData.map((column, index) =>
        DataColumn({
          item: props.user,
          column,
          index,
          ...props
        })
      )}
    </Grid>
  </Paper>
);

const tabs = [
  {
    name: "My Details",
    component: MyDetails
  },
  {
    name: "Dependant's Details",
    component: DependentsDetails
  }
];

const TabContent = props => {
  const SelectedTabContent = tabs[props.tabIndex].component;
  return <SelectedTabContent {...props} />;
};

class TabMenu extends React.Component {
  state = {
    tabIndex: 0
  };
  handleChange = (event, tabIndex) => {
    this.setState({ tabIndex });
  };

  render() {
    const dependentsCount = this.props.user.dependencies.length;
    const dependentsTab = dependentsCount === 0;
    const dependentsLabel = `Dependant's Details (${dependentsCount})`;
    return (
      <div>
        <Tabs
          value={this.state.tabIndex}
          onChange={this.handleChange}
          className={this.props.classes.tabs}
          indicatorColor={"primary"}
        >
          <Tab label={"My Details"} />
          <Tab label={dependentsLabel} disabled={dependentsTab} />
        </Tabs>
        <TabContent {...this.props} tabIndex={this.state.tabIndex} />
      </div>
    );
  }
}

function MyInfo(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Grid container spacing={"24px"} className={classes.content}>
        <Grid item xs={12}>
          <Typography variant={"title"}>My Info</Typography>
          <TabMenu {...props} />
        </Grid>
      </Grid>
    </div>
  );
}

function mapStateToProps(state, prop) {
  return {
    user: state.user
  };
}

MyInfo.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(injectIntl(withStyles(styles)(MyInfo)));
