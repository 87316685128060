export const APP_RESET = "APP_RESET";
export const APP_SET_ERROR = "APP_SET_ERROR";

export const USER_UPDATE_TOKEN = "USER_UPDATE_TOKEN";
export const USER_POPUP_NOTICE = "USER_POPUP_NOTICE";
export const USER_REFRESH_TOKEN = "USER_REFRESH_TOKEN";
export const USER_REFRESH_TOKEN_PENDING = "USER_REFRESH_TOKEN_PENDING";
export const USER_REFRESH_TOKEN_FULFILLED = "USER_REFRESH_TOKEN_FULFILLED";
export const USER_REFRESH_TOKEN_REJECTED = "USER_REFRESH_TOKEN_REJECTED";
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_EMULATE_LOGIN_SUCCESS = "USER_EMULATE_LOGIN_SUCCESS";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_GET_INFO = "USER_GET_INFO";
export const USER_GET_INFO_SUCCESS = "USER_GET_INFO_SUCCESS";
export const USER_GET_INFO_ERROR = "USER_GET_INFO_ERROR";
export const USER_GET_POLICY_SUCCESS = "USER_GET_POLICY_SUCCESS";
export const USER_GET_POLICY_ERROR = "USER_GET_POLICY_ERROR";
export const USER_GET_GA_POLICY_FULFILLED = "USER_GET_GA_POLICY_FULFILLED";

export const EMPLOYEE_GET_INFO = "EMPLOYEE_GET_INFO";
export const EMPLOYEE_GET_INFO_SUCCESS = "EMPLOYEE_GET_INFO_SUCCESS";
export const EMPLOYEE_GET_INFO_ERROR = "EMPLOYEE_GET_INFO_ERROR";

export const GET_MAKERS = "GET_MAKERS";
export const GET_MAKERS_SUCCESS = "GET_MAKERS_SUCCESS";
export const GET_MAKERS_ERROR = "GET_MAKERS_ERROR";

export const GET_CLAIMANT_SUCCESS = "GET_CLAIMANT_SUCCESS";
export const GET_CLAIMANT_ERROR = "GET_CLAIMANT_ERROR";

export const GET_CLAIM_ADD_SUCCESS = "GET_CLAIM_ADD_SUCCESS";
export const GET_CLAIM_ADD_ERROR = "GET_CLAIM_ADD_ERROR";
export const GET_CLAIM_UPLOAD_FILE_ERROR = "GET_CLAIM_UPLOAD_FILE_ERROR";

export const GET_CLAIM_SUCCESS = "GET_CLAIM_SUCCESS";
export const LOADING_CLAIM = "LOADING_CLAIM";
export const GET_CLAIM_ERROR = "GET_CLAIM_ERROR";
export const CLEAR_CLAIM_LIST_ERROR = "CLEAR_CLAIM_LIST_ERROR";

export const VIEW_CLAIM_DIALOG = "VIEW_CLAIM_DIALOG";
export const CLOSE_CLAIM_DIALOG = "CLOSE_CLAIM_DIALOG";

export const GET_CLAIM_EDIT_SUCCESS = "GET_CLAIM_EDIT_SUCCESS";
export const GET_CLAIM_EDIT_ERROR = "GET_CLAIM_EDIT_ERROR";

export const SHOW_CLAIM_DIALOG_ERROR = "SHOW_CLAIM_DIALOG_ERROR";
export const CLOSE_CLAIM_DIALOG_ERROR = "CLOSE_CLAIM_DIALOG_ERROR";

export const CLEAR_UPLOAD_FILE_ERROR = "CLEAR_UPLOAD_FILE_ERROR";

export const EMPLOYEE_GET_BANNER_SUCCESS = "EMPLOYEE_GET_BANNER_SUCCESS";
export const EMPLOYEE_GET_BANNER_ERROR = "EMPLOYEE_GET_BANNER_ERROR";

export const EMPLOYEE_COVERAGE_SUM_ASSURED = "EMPLOYEE_COVERAGE_SUM_ASSURED";
export const EMPLOYEE_COVERAGE_SUM_ASSURED_SUCCESS =
  "EMPLOYEE_COVERAGE_SUM_ASSURED_SUCCESS";
export const EMPLOYEE_COVERAGE_SUM_ASSURED_FULFILLED =
  "EMPLOYEE_COVERAGE_SUM_ASSURED_FULFILLED";
export const EMPLOYEE_COVERAGE_SUM_ASSURED_ERROR =
  "EMPLOYEE_COVERAGE_SUM_ASSURED_ERROR";
