import React, { Component } from "react";
import { withStyles } from "@mui/styles";

import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

class InfoButton extends Component {
  render() {
    const { classes, id, data, handleClickInfoButton } = this.props;
    return (
      <IconButton
        id={id}
        aria-label="Info"
        className={classes.infoButton}
        onClick={handleClickInfoButton(data, id)}
      >
        <Icon>information</Icon>
      </IconButton>
    );
  }
}

const styles = theme => ({
  root: {},
  infoButton: {
    width: "inherit",
    height: "inherit",
    paddingLeft: "5px",
    color: "#c0c0c0"
  }
});

export default withStyles(styles, { withTheme: true })(InfoButton);
