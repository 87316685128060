import Enums from "./Enums.js";

const CodeCompDisabledConfig = {
  GCP: [
    Enums.COMPONENT.FIND_DOC_SP_TAB,
    Enums.COMPONENT.DASHBOARD_MY_BENEFIT_GRID
  ]
};

export default CodeCompDisabledConfig;
