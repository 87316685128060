import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import React, { Component } from "react";
import { Interweave } from "interweave";
import Typography from "@mui/material/Typography";

class BoxItem extends Component {
  render() {
    const { classes, data } = this.props;

    return (
      <Card className={classes.card} style={{ height: "100%" }}>
        <CardContent className={classes.content}>
          <div
            className={classes.banner}
            style={{ background: "url(" + data.backgroundImg + ")" }}
          />
          <div className={classes.info}>
            <Typography className={classes.title}>
              <Interweave content={data.title} />
            </Typography>
            <Typography component="p" className={classes.description}>
              {data.description}
            </Typography>
          </div>
        </CardContent>
      </Card>
    );
  }
}

const styles = theme => ({
  banner: {
    width: "100%",
    height: 250,
    //backgroundSize: "cover!important",
    backgroundRepeat: "no-repeat!important",
    backgroundPosition: "center center!important",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "rgba(0, 0, 0, 0.54)"
  },
  icon: {
    maxWidth: "18%",
    height: "auto",
    paddingLeft: "41%"
  },
  myfiziqIcon: {
    maxWidth: "24%",
    height: "auto",
    paddingLeft: "38%"
  },
  content: {
    padding: 0
  },
  info: {
    padding: "10px 30px"
  },
  info_ms: {
    padding: "95px 30px"
  },
  card: {
    maxWidth: 300,
    minHeight: 350
  },
  card_ms: {
    maxWidth: 370,
    minHeight: 350
  },
  description: {
    color: "#333",
    fontSize: "14px"
  },
  description_ms: {
    color: "#333",
    fontSize: "14px",
    textAlign: "center"
  },
  title: {
    fontSize: "22px",
    color: "#848689",
    verticalAlign: "text-top",
    paddingTop: "11px",
    paddingBottom: "15px"
  },
  title_ms: {
    fontSize: "22px",
    color: "#848689",
    verticalAlign: "text-top",
    paddingTop: "11px",
    paddingBottom: "15px",
    textAlign: "center"
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(BoxItem));
