import { withStyles } from "@mui/styles";
import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { injectIntl } from "react-intl";
import { Interweave } from "interweave";

import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import injectValidator from "../utils/ValidatorExtended";

class RequestRedeemPromo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      promoCode: "",
      contact: {
        name: "",
        email: "",
        phone: ""
      }
    };
  }

  handleSubmit = () => {
    this.setState({
      open: false
    });
    const { contact, promoCode } = this.state;
    this.props.callback(contact, promoCode);
  };

  handleSave = () => {
    this.refs.form.submit();
  };

  handleChange = event => {
    const { contact } = this.state;
    contact[event.target.name] = event.target.value;
    this.setState({ contact });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentWillReceiveProps(newProps) {
    this.setState({ open: newProps.open });
  }

  componentDidMount() {
    this.setState({
      open: this.props.open,
      promoCode: this.props.promoCode
    });
  }

  onEnter = () => {
    this.setState({
      contact: {
        name: this.props.initData ? this.props.initData.contact_name : "",
        email: this.props.initData ? this.props.initData.contact_email : "",
        phone: this.props.initData ? this.props.initData.contact_no : ""
      },
      promoCode: this.props.promoCode
    });
  };

  render() {
    const { classes, intl } = this.props;
    const { contact } = this.state;
    return (
      <div>
        <Dialog
          onEnter={this.onEnter}
          maxWidth="md"
          classes={{
            paper: classes.dialogWrapper
          }}
          open={this.state.open || false}
          onClose={this.handleClose}
          aria-labelledby="redeem-promo-dialog"
        >
          <DialogTitle id="redeem-promo-dialog">
            {intl.formatMessage({ id: "benefit.redeemPromo.title" })}
          </DialogTitle>
          <DialogContent className={classes.rootDialog}>
            <DialogContentText className={classes.topContent}>
              {intl.formatMessage({
                id: "benefit.redeemPromo.details"
              })}
            </DialogContentText>

            <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
              <Grid container spacing={"24px"}>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth={true}
                    autoComplete="[off]"
                    name="name"
                    id="contactName"
                    label="Contact name"
                    value={contact.name}
                    placeholder="Contact Name"
                    validators={["required", "isTrim"]}
                    errorMessages={[
                      intl.formatMessage({ id: "validator.required" }),
                      intl.formatMessage({ id: "validator.trim" })
                    ]}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth={true}
                    autoComplete="[off]"
                    name="email"
                    id="email"
                    label="Your email"
                    value={contact.email}
                    placeholder="Email Address"
                    onChange={this.handleChange}
                    validators={["required", "isEmail", "isTrim"]}
                    errorMessages={[
                      intl.formatMessage({ id: "validator.required" }),
                      intl.formatMessage({ id: "validator.email" }),
                      intl.formatMessage({ id: "validator.trim" })
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth={true}
                    autoComplete="[off]"
                    name="phone"
                    id="phone"
                    label="Your phone"
                    type="number"
                    value={contact.phone}
                    placeholder="Phone Number"
                    validators={["required", "isTrim"]}
                    errorMessages={[
                      intl.formatMessage({ id: "validator.required" }),
                      intl.formatMessage({ id: "validator.trim" })
                    ]}
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
            </ValidatorForm>
            {/*  <Typography component="p" className={classes.bottomContent}>
              {intl.formatMessage({ id: this.props.policyMessageID })}
            </Typography> */}

            <DialogTitle style={{ width: "100%", textAlign: "center" }}>
              {intl.formatMessage({ id: "benefit.redeemPromo.policy.title" })}
            </DialogTitle>
            <Typography component="p" style={{ whiteSpace: "pre-line" }}>
              {intl.formatMessage({
                id: "benefit.redeemPromo.policy.details"
              })}
              <Interweave
                content={intl.formatMessage({
                  id: "benefit.redeemPromo.policy.privacy"
                })}
              />
            </Typography>
          </DialogContent>

          <DialogActions
            classes={{
              root: classes.dialogActionRoot,
              action: classes.dialogAction
            }}
          >
            <Button
              color="secondary"
              onClick={this.handleClose}
              className={classes.cancelButton}
              variant="raised"
              fullWidth={true}
            >
              Cancel
            </Button>

            <Button
              onClick={this.handleSave}
              color="primary"
              variant="contained"
              autoFocus
              fullWidth={true}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const styles = theme => ({
  root: {},
  dialogWrapper: {
    padding: "15px 25px"
  },
  rootDialog: {
    overflowX: "hidden"
  },
  dialogContentHeight: {
    height: "65%"
  },
  dialogActionRoot: {
    display: "flex",
    justifyContent: "space-between"
  },
  dialogAction: {
    width: "45%"
  },
  cancelButton: {
    backgroundColor: theme.palette.default.main,
    border: `2px solid ${theme.palette.secondary.main}`,
    fontWeight: 500
  },
  bottomContent: {
    paddingTop: 50
  },
  topContent: {
    paddingBottom: 20
  }
});

export default injectValidator(
  injectIntl(withStyles(styles, { withTheme: true })(RequestRedeemPromo))
);
