import React, { Component } from "react";

import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import GoogleMapComp from "../../../../components/GoogleMapComp";
import { geolocated } from "react-geolocated";
import { bindActionCreators } from "redux";
import { clinicActions } from "../../../../actions";
import { connect } from "react-redux";
import { PulseLoader } from "react-spinners";
import Hidden from "@mui/material/Hidden";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

class FindNearMe extends Component {
  static REACT_GOOGLE_MAP_URL =
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing";

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }
  componentWillMount() {
    this.props.fetchClinics();
  }

  renderMap() {
    const { classes } = this.props;

    if (!this.props.isGeolocationAvailable) {
      return <div>Your browser does not support Geolocation</div>;
    }

    if (!this.props.isGeolocationEnabled) {
      return <div>Geolocation is not enabled</div>;
    }

    if (this.props.coords) {
      return (
        <GoogleMapComp
          isMarkerShown
          geoLocation={this.props.coords}
          makers={this.props.clinics.makers}
          googleMapURL={FindNearMe.REACT_GOOGLE_MAP_URL}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div className={classes.mapContent} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      );
    } else {
      return (
        <div
          style={{
            height: `400px`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <PulseLoader color={"#123abc"} />
        </div>
      );
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <Grid container spacing={24}>
            <Hidden smDown>
              <Grid item xs={12}>
                <Typography className={classes.titleLinkText}>
                  <Link
                    to={{
                      pathname: "/auth/clinics/search",
                      state: { showTable: true }
                    }}
                  >
                    {" "}
                    View All Clinics{" "}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  margin={"normal"}
                >
                  <Input
                    placeholder="What kind of doctor do you need to see?"
                    type={"text"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon>search</Icon>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <span>OR</span>
                <Button
                  variant="raised"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    this.props.history.push("/auth/clinics/find-near-me");
                  }}
                >
                  Find clinics near me
                </Button>
              </Grid>
            </Hidden>
            <Grid item xs={12}>
              {this.renderMap()}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    width: "100%",
    height: "100vh",
    zIndex: 1,
    overflow: "hidden"
  },
  content: {
    backgroundColor: theme.palette.background.default,
    height: "calc(100% - 56px)",
    marginTop: 80,
    [theme.breakpoints.up("sm")]: {
      height: "calc(100% - 64px)",
      marginTop: 64
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 30,
      marginRight: 30
    }
  },
  mapContent: {
    height: window.innerHeight - 130,
    [theme.breakpoints.up("md")]: {
      height: window.innerHeight - 300
    }
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit,
    textTransform: "none"
  },
  titleText: {
    fontWeight: 400,
    fontSize: 20
  },
  titleLinkText: {
    fontWeight: 400,
    fontSize: 14,
    marginLeft: 15
  }
});

function mapStateToProps(state, prop) {
  return {
    clinics: state.clinics
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(clinicActions, dispatch);
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true
  },
  userDecisionTimeout: 10000
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(FindNearMe))
);
