import { APIResource, APIResponse, APIError } from '../utils/APIResource'

export default class ClinicServices {
    
    static fetchClinics() {
        return APIResource.get("/v1/clinics").then(response => {
            return Promise.resolve(new APIResponse(response));
        }, error => {
            return Promise.reject(new APIError(error))
        });
    }
}