import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import React, { Component } from "react";
import Search from "../../../containers/Auth/Clinic/Search/Search";
import Specialist from "./Specialist";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import assets from "../../../assets";
import _ from "lodash";
import Util from "../../../utils/Util.js";
import Enums from "../../../utils/Enums.js";

class FindADoctor extends Component {
  constructor(props) {
    super(props);
    const productInfo = assets.productInfo;
    const env = Util.getEnvFromHostname();
    this.state = {
      value: 0,
      showSpecialist: this.hasProductBenefits(
        props.agentType,
        productInfo.productKeys.DOCDOC
      )
    };
  }

  hasProductBenefits = (agentType, key) => {
    const availableProducts = assets.productInfo.availableProducts[agentType];
    return _.includes(availableProducts, key);
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes, user, productCode } = this.props;
    const { showSpecialist } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography className={classes.heading} variant="h5" color="inherit">
            Find a Doctor
          </Typography>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            classes={{ indicator: classes.indicator }}
          >
            <Tab label="General Practitioner" />
            {!Util.isCompDisabledForCode(
              productCode,
              Enums.COMPONENT.FIND_DOC_SP_TAB
            ) &&
              showSpecialist && <Tab label="Specialist" />}
          </Tabs>
          {this.state.value === 0 && <Search />}
          {this.state.value === 1 && showSpecialist && <Specialist />}
        </div>
      </div>
    );
  }
}
const styles = theme => ({
  root: {
    height: "100%",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
      marginRight: 10,
      marginTop: 80,
      paddingBottom: 10
    }
  },
  content: {
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10
  },
  tabRoot: {
    minWidth: "auto",
    marginRight: theme.spacing.unit * 3
  },
  labelContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    textTransform: "none"
  },
  indicator: {
    backgroundColor: theme.colors.primary.red,
    height: 3
  }
});

function mapStateToProps(state, prop) {
  return {
    agentType: state.user.auth.pcode,
    productCode: state.user.policy.product_code,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FindADoctor));
