import React from 'react';

const NotFound = () => {
  return (
    <div>
      <div>
        404 Page Not Found
      </div>
    </div>
  );
};

export default NotFound;
