/**
 * Inject Polyfill
 * For fixing issues on IE
 * Some functions from Javascript didn't have on IE11
 * For example: .includes and etc...
 */
import "core-js";
import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import App from "./containers/App/App";
import { store, persistor } from "./store";
import registerServiceWorker from "./registerServiceWorker";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import "./ie.css";
import "./typography.css";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
