import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { Accordion as ExpansionPanel } from "@mui/material";
import { AccordionSummary as ExpansionPanelSummary } from "@mui/material";
import { AccordionDetails as ExpansionPanelDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MobileDetect from "mobile-detect";
import PlanContent from "./PlanContent";
import pruPlans from "../../assets/data/plans/index";
import config from "../../config/config";
import InfoButton from "../../components/InfoButton";
import InfoPopover from "../../components/InfoPopover";
import SimpleMessageDialog from "../../components/SimpleMessageDialog";
import { cloneDeep } from "lodash";
import moment from "moment";

const mobileDetect = new MobileDetect(window.navigator.userAgent);

class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }

  handleClickInfoButton = (data, id) => event => {
    let infoPopover = {
      ...this.state.infoPopover
    };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this.refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
    event.stopPropagation();
  };

  handleCloseDialog() {
    let infoPopover = {
      ...this.state.infoPopover,
      open: false
    };
    this.setState({ infoPopover });
  }

  getHelpText = code => {
    if (code === "GTL" || code === "GTLB") {
      let data =
        "<ul>" +
        "<li>Employee and Dependant (16 to 64 age last birthday) selecting Plan 1 of Group Term Life will require underwriting. The employee will be covered and invoiced at Plan 2 sum assured while pending for fulfillment of underwriting.</li>" +
        "<br/><li>Employee and Dependant (from 65 to 69 age last birthday) selecting Group Term Life will require underwriting.</li>" +
        "<br/><li>Employee and Dependant (from 70 to 74 age last birthday) will require underwriting at each renewal.</li>" +
        "<br/><li>All applications from sub-standard live that require premium loading will be declined.</li>" +
        "</ul>";
      return data;
    } else if (code === "GCCA") {
      let data =
        "<ul>" +
        "<li>Employee and Dependant (16 to 64 age last birthday) selecting Plan 1 of Group Crisis Cover Accelerated will require underwriting.</li>" +
        "<br/><li>All applications from sub-standard live that require premium loading will be declined.</li>" +
        "</ul>";
      return data;
    } else if (code === "GEAC") {
      let data =
        "<ul>" +
        "<li>All employee and dependant will require underwriting, regardless of age or plan selected..</li>" +
        "<br/><li>All applications from sub-standard live that require premium loading will be declined.</li>" +
        "</ul>";
      return data;
    }
  };

  getPlans() {
    const {
      tiers = null,
      productCode = config.products.defaultCode
    } = this.props;

    const enrolledPlans = Object.keys(tiers);
    const formattedPlan = [];

    pruPlans[productCode].forEach(type => {
      const formattedType = type.plans.map(plan => {
        if (enrolledPlans.includes(plan.code)) return plan;
        return null;
      });

      if (formattedType && !formattedType.every(p => p === null))
        formattedPlan.push({ title: type.title, plans: formattedType });
    });
    return formattedPlan || pruPlans[productCode];
  }

  getPlansShow(policyEffectiveDate, basePlansClone, productCode) {
    let basePlans = basePlansClone;
    if (basePlans.length > 0) {
      if (
        moment(policyEffectiveDate).isSameOrAfter("2021-01-01") &&
        productCode !== "PTF"
      ) {
        // removing the old rates of GHS and GTL
        // id 1 signifies GTL old rates and id 2 signifies GHS old rates
        let oldPlanIdx1 = basePlans[0].plans.findIndex(
          x => x !== null && x.id === 1
        );
        if (oldPlanIdx1 !== -1) {
          basePlans[0].plans.splice(oldPlanIdx1, 1);
        }

        let oldPlanIdx2 = basePlans[0].plans.findIndex(
          x => x !== null && x.id === 2
        );
        if (oldPlanIdx2 !== -1) {
          basePlans[0].plans.splice(oldPlanIdx2, 1);
        }

        let oldPlanIdx3 = basePlans[0].plans.findIndex(
          x => x !== null && x.id === 4
        );
        if (oldPlanIdx3 !== -1) {
          basePlans[0].plans.splice(oldPlanIdx3, 1);
        }
      } else {
        // original rates
        // removing the new rates of GHS and GTL
        // id 3 signifies GTL new rates and id 4 signifies GHS new rates
        let newPlanIdx1 = basePlans[0].plans.findIndex(
          x => x !== null && x.id === 3
        );
        if (newPlanIdx1 !== -1) {
          basePlans[0].plans.splice(newPlanIdx1, 1);
        }
        let newPlanIdx2 = basePlans[0].plans.findIndex(
          x => x !== null && x.id === 4
        );
        if (newPlanIdx2 !== -1) {
          basePlans[0].plans.splice(newPlanIdx2, 1);
        }
      }
    }
    //return directly if there is no plans existing for dependents
    return basePlans;
  }

  getPlanTitle = (plan, selectedTiers) => {
    return (
      plan.types
        .find(t => t.type === this.props.types[0])
        .data[0].headers.find(h => h.tier === selectedTiers[0]).title ||
      "Unknown Plan"
    );
  };

  //This function is to update the base SumAssured Coverage with the actual SumAssured amount in GA
  getSumAssuredPlans = (basePlansInitial, planSumAssured) => {
    for (var i = 0; i < basePlansInitial.length; i++) {
      basePlansInitial[i].plans.filter(function(basePlan) {
        if (basePlan !== null && planSumAssured !== undefined) {
          planSumAssured.some(function(sumAssured) {
            if (basePlan.code === sumAssured.code) {
              let amount = sumAssured.sum_assured.toString();
              amount = amount.replace(/\.00$/, "");
              basePlan.types[0].data[0].rows[0].columns[sumAssured.tier].data =
                "S$ " + amount;
            }
          });
        }
      });
    }
    return basePlansInitial;
  };

  render() {
    const {
      classes,
      types,
      tiers = null,
      extendedBenefit,
      selectionDisplayMode,
      displayAll = true,
      policyEffectiveDate,
      productCode = config.products.defaultCode,
      planSumAssured,
      isDependent
    } = this.props;

    const { infoPopover } = this.state;
    const basePlansDefault = displayAll
      ? pruPlans[productCode]
      : this.getPlans();
    const basePlansClone = cloneDeep(basePlansDefault);
    let basePlansInitial = this.getPlansShow(
      policyEffectiveDate,
      basePlansClone,
      productCode
    );
    let updatedBasePlans = this.getSumAssuredPlans(
      basePlansInitial,
      planSumAssured
    );
    const basePlans = updatedBasePlans;
    let hasGAMR = false;
    basePlans.map(basePlan => {
      if (basePlan.title === "Supplementary Plans") {
        basePlan.plans.map(plan => {
          if (plan && plan.code === "GAMR") {
            hasGAMR = true;
          }
        });
      }
    });
    return (
      <div className={classes.root}>
        {basePlans.map((basePlan, _idxBasePlans) => {
          return (
            basePlan && (
              <div key={_idxBasePlans}>
                {_idxBasePlans > 0 && <br />}
                <Typography
                  variant="headline"
                  component="h2"
                  className={classes.headline}
                >
                  {basePlan.title}
                </Typography>

                {basePlan.plans.map((plan, _idxPlan) => {
                  let selectedTiers = tiers && plan ? tiers[plan.code] : [];
                  let isGFWM = plan && plan.code == "GFWM" ? true : false;
                  let isGADD = plan && plan.code == "GADD" ? true : false;
                  let isGAMR = plan && plan.code == "GAMR" ? true : false;
                  return (
                    plan &&
                    !isGAMR && (
                      <ExpansionPanel
                        key={_idxPlan}
                        className={classes.expansionPanel}
                      >
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          className={classes.expansionPanelSummary}
                        >
                          <Typography
                            className={classes.expansionPanelSummaryLabel}
                          >
                            {!displayAll && (
                              <span className={classes.titleContainer}>
                                <span className={classes.titleLabel}>
                                  {plan.label}
                                  {(plan.code === "GTL" ||
                                    plan.code === "GTLB" ||
                                    plan.code === "GEAC" ||
                                    plan.code === "GCCA") &&
                                    productCode !== "GCP" && (
                                      <span style={{ width: 30 }}>
                                        <InfoButton
                                          ref={`btnInfoHeader${plan.code}`}
                                          id={`btnInfoHeader${plan.code}`}
                                          data={this.getHelpText(plan.code)}
                                          handleClickInfoButton={
                                            this.handleClickInfoButton
                                          }
                                        />
                                      </span>
                                    )}
                                </span>
                                <span className={classes.titleSelected}>
                                  {this.getPlanTitle(plan, selectedTiers)}
                                </span>
                              </span>
                            )}

                            {displayAll && <span>{plan.label}</span>}
                          </Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails>
                          <PlanContent
                            code={plan.code}
                            isDependent={isDependent}
                            data={plan.types}
                            types={types}
                            tiers={selectedTiers}
                            extendedBenefit={extendedBenefit}
                            isGFWM={isGFWM}
                            isGADD={isGADD}
                            hasGAMR={hasGAMR}
                            selectionDisplayMode={selectionDisplayMode}
                          />
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )
                  );
                })}
              </div>
            )
          );
        })}
        {mobileDetect.phone() !== null ? (
          <SimpleMessageDialog
            isHtml={true}
            type="none"
            name="info"
            title="Info"
            description={infoPopover.data}
            open={infoPopover.open}
            closeHandler={this.handleCloseDialog.bind(this)}
          />
        ) : (
          <InfoPopover
            open={infoPopover.open}
            data={infoPopover.data}
            element={infoPopover.element}
            handleClickInfoButton={this.handleClickInfoButton}
          />
        )}
      </div>
    );
  }
}

Plans.defaultProps = {
  tiers: null,
  selectionDisplayMode: null
};

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: 20
  },
  flex: {
    flex: 1
  },
  expansionPanel: {
    margin: "16px 0"
  },
  expansionPanelSummary: {
    backgroundColor: theme.palette.default.main
  },
  expansionPanelSummaryLabel: {
    color: theme.palette.default.fontColor,
    width: "100%"
  },
  headline: {
    fontSize: "1em",
    fontWeight: 700
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  titleLabel: {
    width: "80%"
  },
  titleSelected: {
    flex: 1,
    fontWeight: "bold",
    color: "#C4184A"
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Plans));
