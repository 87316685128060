const ENUMS = {
  SCOPE_TYPE: {
    TC_W: "tc:w",
    DASHBOARD_EMP_R: "dashboard[emp]:r",
    INS_COV_EMP_R: "ins_cov[emp]:r",
    CLAIM_R: "claim:r",
    CLAIM_W: "claim:w",
    DOCUMENT_EMP_R: "document[emp]:r",
    DOCTOR_R: "doctor:r",
    EMP_MYBENEFIT_R: "emp_mybenefit:r"
  },
  ACCESSIBLE_ROLES: "EMPLOYEE",
  APP_TYPE: {
    EMPLOYEE: "EMPLOYEE"
  },
  P_CODE: {
    UOB_ASSISTED: "UOB_ASSISTED",
    PRU_AGENCY: "PRU_AGENCY",
    PRU_AGENCY_EMULATE: "PRU_AGENCY_EMULATE",
    SCB_ASSISTED: "SCB_ASSISTED",
    UOB_TEAM_2: "UOB_TEAM_2",
    PRU_CE: "PRU_CE",
    PRU_CE_EMULATE: "PRU_CE_EMULATE",
    PFA: "PFA"
  },
  PLANS_TYPE: {
    BENEFITS: "BENEFITS",
    PREMIUM: "PREMIUM",
    GROUP_DISCOUNT: "GROUP_DISCOUNT"
  },
  PLANS_CODE: {
    HDF: "HDF",
    GOSP: "GOSP",
    GCCA: "GCCA",
    GAMR: "GAMR",
    GEMM: "GEMM",
    GSMM: "GSMM",
    PGIH: "PGIH",
    PSIH: "PSIH",
    PSMH: "PSMH",
    GTLB: "GTLB",
    GPRU: "GPRU",
    GDEN: "GDEN",
    GHSB: "GHSB",
    GFWM: "GFWM",
    GADD: "GADD",
    P3HS: "P3HS",
    P3GP: "P3GP",
    P3SP: "P3SP",
    HSVC: "HSVC",
    GMAT: "GMAT",
    GHPS: "GHPS",
    GOPB: "GOPB",
    PEHS: "PEHS",
    PEGP: "PEGP",
    PESP: "PESP",
    PGAH: "PGAH",
    PGFH: "PGFH",
    PSAH: "PSAH",
    PSFH: "PSFH",
    PGPW: "PGPW",
    PSPW: "PSPW",
    GHSR: "GHSR",
    PDIH: "PDIH",
    GPCG: "GPCG",
    GEAC: "GEAC",
    PPIH: "PPIH",
    PGAT: "PGAT"
  },
  STAGES: {
    ESTIMATE: "ESTIMATE",
    EMPLOYEE: "EMPLOYEE",
    PROPOSAL: "PROPOSAL",
    SUBMISSION: "SUBMISSION",
    SUBMITTED: "SUBMITTED",
    INFORCE: "INFORCE" //PROCESSED (on local)
  },
  FILE_EXTENSION: {
    PDF: "pdf",
    XLSX: "xlsx",
    OTEHRS: "others"
  },
  COMPONENT: {
    FIND_DOC_GP_TAB: "FIND_DOC_GP_TAB",
    FIND_DOC_SP_TAB: "FIND_DOC_SP_TAB",
    DASHBOARD_MY_BENEFIT_GRID: "DASHBOARD_MY_BENEFIT_GRID"
  },
  VAS_PROMOCODE: {
    EYESIGHT: {
      CODE1: "#eyesightexclusive01",
      CODE2: "#eyesightexclusive02",
      CODE3: "#eyesightexclusive03"
    },
    IWELLNESS: {
      CODE1: "#i-wellnessexclusive01",
      CODE2: "#i-wellnessexclusive02",
      CODE3: "#i-wellnessexclusive03"
    },
    CRAFTNCULTURE: {
      CODE1: "#Craft&Cultureexclusive01",
      CODE2: "#Craft&Cultureexclusive02"
    },
    CLASSPASS: {
      CODE1: "#classpassexclusive01",
      CODE2: "#classpassexclusive02"
    },
    UOBTRAVEL: {
      CODE1: "#UOBhotelexclusive01",
      CODE2: "#UOBpackagesexclusive02",
      CODE3: "#UOBcruisesexclusive03"
    }
  },
  CLAIMS_STATUS: {
    SUBMITTED: "SUBMITTED",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    PENDING: "PENDING",
    PROCESSING: "PROCESSING",
    EDIT_REQUESTED: "EDIT_REQUESTED",
    ON_HOLD: "ON_HOLD"
  },
  CLAIM_TYPE: {
    INPATIENT: "INPATIENT",
    OUTPATIENT_GP: "OUTPATIENT_GP",
    OUTPATIENT_SP: "OUTPATIENT_SP",
    OUTPATIENT_DENTAL: "OUTPATIENT_DENTAL",
    OUTPATIENT_HS_VAC: "OUTPATIENT_HS_VAC",
    MATERNITY: "MATERNITY",
    OUTPATIENT: "OUTPATIENT"
  },
  CAUSE_OF_CLAIM: {
    ILLNESS: "ILLNESS",
    ACCIDENT: "ACCIDENT",
    NORMAL_CHILDBIRTH: "NORMAL_CHILDBIRTH",
    CHILDBIRTH_INVOLVING_CESAREAN_SECTION:
      "CHILDBIRTH_INVOLVING_CESAREAN_SECTION",
    MISCARRIAGE: "MISCARRIAGE"
  },
  PAYMENT_METHOD: {
    GIRO_TO_EMPLOYEE: "GIRO_TO_EMPLOYEE",
    GIRO_TO_COMPANY: "GIRO_TO_COMPANY",
    PAYNOW_TO_EMPLOYEE: "PAYNOW_TO_EMPLOYEE",
    CHEQUE: "CHEQUE"
  },
  PACKAGED_PLANS: ["PF2", "PF3", "PTF"]
};

export default Object.freeze(ENUMS);
