import { withStyles } from "@mui/styles";
import React, { useEffect, useMemo, useState } from "react";
import { injectIntl } from "react-intl";

import DatePickerValidatorElement from "../../../../components/form/DatePickerValidatorElement";
import TextValidatorElement from "../../../../components/form/TextValidatorElement";
import SearchableSelectValidatorElement from "../../../../components/form/SearchableSelectValidatorElement";
import DiagnosisOptions from "./DiagnosisListSimplified.json";
import Enums from "../../../../utils/Enums";
import PceService from "../../../../services/PceService";
import { ValidationMessage, ValidationName } from "./claimValidationRule";

const DIAGNOSIS_ID_OTHERS = "780";
/**
 * @deprecated To handle old pipeline cases which has been submitted with diagnosis code OTH, remove this in future.
 */
const OLD_DIAGNOSIS_ID_OTHERS = "OTH";
const CLINIC_ID_OTHERS = "50001047";

const ClaimEditDiagnosisSection = ({
  claim,
  isPCEClient,
  handleChange,
  handleDateChange,
  formatDate,
  readOnly,
  intl,
  classes
}) => {
  const {
    clinic_id: clinicId,
    clinic_name: clinicName,
    claim_type: claimType,
    date_of_consultation: dateOfConsultation,
    diagnosis_id: diagnosisId,
    diagnosis_name: diagnosisName
  } = claim;

  const [clinicOptions, setClinicOptions] = useState([]);
  useEffect(() => {
    if (!isPCEClient) {
      return;
    }
    const fetchData = async () => {
      const { data = [] } = await PceService.fetchProviderClinicsOptions();
      const hasOtherOption = data.some(e => e.value === CLINIC_ID_OTHERS);
      if (!hasOtherOption) {
        data.push({
          value: CLINIC_ID_OTHERS,
          label: "Others"
        });
      }
      setClinicOptions(data);
    };
    fetchData();
  }, [isPCEClient]);

  useEffect(() => {
    if (!isPCEClient || !readOnly) {
      // to handle PCE old submitted cases
      return;
    }
    if (OLD_DIAGNOSIS_ID_OTHERS !== diagnosisId) {
      // to only update if it is using old code
      return;
    }
    // set to new diagnosis ID
    handleChange("diagnosis_id")(DIAGNOSIS_ID_OTHERS);
  }, [isPCEClient, readOnly, diagnosisId]);

  const handleClinicIdChange = useMemo(() => {
    const handleRawClinicIdChange = handleChange("clinic_id");
    const handleClinicNameChange = handleChange("clinic_name");
    return selected => {
      handleRawClinicIdChange(selected);
      if (selected && selected.value !== CLINIC_ID_OTHERS) {
        handleClinicNameChange(selected.label);
      } else {
        handleClinicNameChange("");
      }
    };
  }, [handleChange]);

  const showDiagnosis = claimType => {
    return (
      claimType === Enums.CLAIM_TYPE.INPATIENT ||
      claimType === Enums.CLAIM_TYPE.MATERNITY ||
      claimType === Enums.CLAIM_TYPE.OUTPATIENT_GP ||
      claimType === Enums.CLAIM_TYPE.OUTPATIENT_SP ||
      claimType === Enums.CLAIM_TYPE.OUTPATIENT
    );
  };

  const handleDiagnosisIdChange = useMemo(() => {
    const handleRawDiagnosisIdChange = handleChange("diagnosis_id");
    const handleDiagnosisNameChange = handleChange("diagnosis_name");
    return selected => {
      handleRawDiagnosisIdChange(selected);
      if (selected && selected.value !== DIAGNOSIS_ID_OTHERS) {
        handleDiagnosisNameChange(selected.label);
      } else {
        handleDiagnosisNameChange("");
      }
    };
  }, [handleChange]);

  return (
    <div>
      <DatePickerValidatorElement
        disabled={readOnly}
        fullWidth
        name={"date_of_consultation"}
        autoComplete="[off]"
        clearable
        label="Date Of Visit"
        disableFuture
        value={formatDate(dateOfConsultation)}
        onChange={handleDateChange("date_of_consultation")}
        format="DD/MM/YYYY"
        animateYearScrolling={true}
        keyboard
        variant="standard"
        validators={[
          "required",
          "notPriorCoverageStartDate",
          ValidationName.MAX_SUBMISSION_DAY
        ]}
        errorMessages={[
          "Required",
          "The date of visit must be after the start of coverage.",
          ValidationMessage[ValidationName.MAX_SUBMISSION_DAY]
        ]}
      />
      {isPCEClient && (
        <div>
          <SearchableSelectValidatorElement
            disabled={readOnly}
            value={clinicId || ""}
            onChange={handleClinicIdChange}
            name={"clinic_id"}
            id={"clinic_id"}
            variant="standard"
            label="Service Provider / Clinic"
            autoComplete="[off]"
            validators={["isSelectRequired"]}
            suggestions={clinicOptions}
            errorMessages={["Required"]}
          />
          {clinicId === CLINIC_ID_OTHERS && (
            <TextValidatorElement
              disabled={readOnly}
              autoComplete="[off]"
              fullWidth
              variant="standard"
              className={classes.textField}
              inputProps={{
                maxLength: 200
              }}
              label="Service Provider / Clinic Others"
              onChange={handleChange("clinic_name")}
              name="clinic_name"
              value={clinicName || ""}
              validators={["required", "specialCharacterRemark"]}
              errorMessages={[
                "Required",
                intl.formatMessage({
                  id: "validator.invalid.specialCharacters"
                })
              ]}
            />
          )}
        </div>
      )}
      {showDiagnosis(claimType) && (
        <SearchableSelectValidatorElement
          disabled={readOnly}
          value={diagnosisId || ""}
          onChange={handleDiagnosisIdChange}
          name={"diagnosis_id"}
          id={"diagnosis_id"}
          label="Diagnosis"
          autoComplete="[off]"
          validators={["isSelectRequired"]}
          suggestions={DiagnosisOptions}
          errorMessages={["Required"]}
        />
      )}
      {DIAGNOSIS_ID_OTHERS === diagnosisId && (
        <TextValidatorElement
          disabled={readOnly}
          autoComplete="[off]"
          fullWidth
          className={classes.textField}
          inputProps={{
            maxLength: 200
          }}
          label="Diagnosis Others"
          onChange={handleChange("diagnosis_name")}
          name="diagnosis_name"
          value={diagnosisName || ""}
          validators={["required", "specialCharacterRemark"]}
          errorMessages={[
            "Required",
            intl.formatMessage({
              id: "validator.invalid.specialCharacters"
            })
          ]}
        />
      )}
    </div>
  );
};
const styles = theme => ({});

export default injectIntl(withStyles(styles)(ClaimEditDiagnosisSection));
