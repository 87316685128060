import CorePlans from "./PTF/core.data";
import SupplementaryPlans from "./PTF/supplementary.data";

import PF3_CorePlans from "./PF3/core.data";
import PF3_SupplementaryPlans from "./PF3/supplementary.data";

import PEP_CorePlans from "./PEP/core.data";
import PEP_SupplementaryPlans from "./PEP/supplementary.data";

import GCP_CorePlans from "./GCP/core.data";
import GCP_SupplementaryPlans from "./GCP/supplementary.data";

const plans = {
  PF3: [
    {
      title: `Core Plans`,
      plans: PF3_CorePlans
    },
    {
      title: `Supplementary Plans`,
      plans: PF3_SupplementaryPlans
    }
  ],
  PTF: [
    {
      title: `Core Plans`,
      plans: CorePlans
    },
    {
      title: `Supplementary Plans`,
      plans: SupplementaryPlans
    }
  ],
  PEP: [
    {
      title: `Core Plans`,
      plans: PEP_CorePlans
    },
    {
      title: `Supplementary Plans`,
      plans: PEP_SupplementaryPlans
    }
  ],
  GCP: [
    {
      title: `Core Plans`,
      plans: GCP_CorePlans
    },
    {
      title: `Supplementary Plans`,
      plans: GCP_SupplementaryPlans
    }
  ]
};

export default plans;
