import pi from "../../productInfo";

const {
  CRAFT_CULTURE,
  IWELLNESS,
  CLASSPASS,
  EYESIGHT,
  UOB_TRAVEL,
  MORE_SERVICES
} = pi.productKeys;

// The ordering of merchants is handled by the list below. Currently the list of merchants is ordered in ascending order

export default [
  {
    key: CLASSPASS,
    backgroundImg: require("../../../img/vas/ClassPass_Logo-Type_Blue.jpg"),
    title: "classpass",
    description:
      "The world’s largest fitness and wellness aggregator, giving you premium access to the top-rated studios, gyms and wellness services around the world with one membership."
  },
  {
    key: CRAFT_CULTURE,
    backgroundImg: require("../../../img/vas/Craft___Culture_Logo.png"),
    title: "Craft & Culture",
    description:
      "A Singaporean-Owned Brewery, specializing in fermented products like Kombucha, Milk Kefir, Water Kefir as well as Natural Probiotic skin care for glowing skin."
  },
  {
    key: EYESIGHT,
    backgroundImg: require("../../../img/vas/eyesight_logo.png"),
    title: "eyesight.sg",
    description:
      "Improving Lives with Primary Eye Care: Subjective Refraction, Myopia Control, Orthokeratology lenses, Anterior Health Assessment, Eye Pressure Measurement."
  },
  {
    key: IWELLNESS,
    backgroundImg: require("../../../img/vas/iwelllogov1__1_.png"),
    title: "i-wellness",
    description:
      "An integrate Wellness service provider that believe good health comes from the concept of pre-emptive detection and early intervention. "
  },
  {
    key: UOB_TRAVEL,
    backgroundImg: require("../../../img/vas/UOBTravel_Logo.png"),
    title: "UOB Travel",
    description:
      "Your comprehensive new travel guide to fuel your wanderlust. Get insider tips from locals, inspirational travel guides and tips, curated deals and more. Explore "
  },
  {
    key: MORE_SERVICES,
    title: "More services coming soon.",
    description:
      "As part of our continuous efforts to serve you better, new value added services will be coming your way. Please watch this space for exciting services."
  }
];
