import GCCA from "./GCCA";
import GADD from "./GADD";
import GAMR from "./GAMR";
import GEMM from "./GEMM";
import GPRU from "./GPRU";
import GOSP from "./GOSP";
import GDEN from "./GDEN";
import GEAC from "./GPCG";
import PDIH from "./GPPD";

// Plans ----------------------
const plans = [GCCA, GEMM, GPRU, GOSP, GADD, GAMR, GDEN, GEAC, PDIH];

export default plans;
