import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import { withStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import FormHelperText from "@mui/material/FormHelperText";
import classNames from "classnames";

class DatePickerValidatorElement extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      classes,
      errorMessages,
      validators,
      requiredError,
      errorText,
      validatorListener,
      helperText,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    return (
      <div>
        <DatePicker
          error={!isValid}
          variant="standard"
          {...rest}
          maxDateMessage="The Date of Visit cannot be in the future"
        />
        <FormHelperText className={classNames(!isValid && classes.error)}>
          {this.errorText(helperText)}
        </FormHelperText>
      </div>
    );
  }

  errorText(defaultText) {
    const { isValid } = this.state;
    if (isValid) {
      return defaultText;
    }

    return <span>{this.getErrorMessage()}</span>;
  }
}

const styles = theme => ({
  error: {
    color: theme.colors.primary.red
  },
  labelError: {
    color: theme.colors.primary.red
  }
});

export default withStyles(styles, { withTheme: true })(
  DatePickerValidatorElement
);
