import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import React, { Component } from "react";
import ClaimEdit from "./ClaimEdit";
import ClaimList from "./ClaimList";
import { eClaimActions } from "../../../actions";
import { bindActionCreators } from "redux";
import Typography from "@mui/material/Typography";
import SimpleMessageDialog from "../../../components/SimpleMessageDialog";
import LoadingPopup from "../../../components/LoadingPopup";
import { getEclaimState } from "../../../selectors/CommonSelectors";
import { Box, Button, Card, CardContent } from "@mui/material";

class MyClaims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClaim: null
    };
  }

  handleMakeClaim = () => {
    this.setState({ selectedClaim: null }, () => {
      this.props.viewClaim();
    });
  };

  handleViewClaim = idx => e => {
    this.props.getClaim(idx).then(
      response => {
        if (response.success) {
          this.setState({ selectedClaim: response.data }, () => {
            this.props.viewClaim();
          });
        }
      },
      errors => {
        this.props.showClaimErrorDialog();
      }
    );
  };

  fetchClaims = () => {
    const params = { filterBy: "", filterValue: "", pageIndex: 0 };
    this.props.clearClaimListError();
    this.props.fetchClaims(params);
  };

  handleCloseClaim = () => {
    this.props.closeClaim();
    this.fetchClaims();
  };

  componentDidMount() {
    this.fetchClaims();
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.showClaimDialog &&
      nextProps.showClaimDialog !== this.props.showClaimDialog
    ) {
      this.fetchClaims();
    }
  }

  getStatusText = status => {
    if (status === "SUBMITTED") {
      return (
        <Typography
          style={{
            color: "#4289e6"
          }}
        >
          Submitted
        </Typography>
      );
    } else if (status === "PROCESSING") {
      return (
        <Typography
          style={{
            color: "#4289e6"
          }}
        >
          Processing
        </Typography>
      );
    } else if (status === "ON_HOLD") {
      return (
        <Typography
          style={{
            color: "#4289e6"
          }}
        >
          On Hold
        </Typography>
      );
    } else if (status === "APPROVED") {
      return (
        <Typography
          style={{
            color: "#18c494"
          }}
        >
          Approved
        </Typography>
      );
    } else if (status === "REJECTED") {
      return (
        <Typography
          style={{
            color: "#e61928"
          }}
        >
          Rejected
        </Typography>
      );
    } else {
      return (
        <Typography
          style={{
            color: "#e61928"
          }}
        >
          Action Needed
        </Typography>
      );
    }
  };

  render() {
    const {
      classes,
      showClaimDialog,
      showClaimError,
      isLoading,
      claimErrors,
      claimsListError
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <ClaimList
            getStatusText={this.getStatusText}
            handleMakeClaim={this.handleMakeClaim}
            handleViewClaim={this.handleViewClaim}
          />

          {claimsListError && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              marginTop="10rem"
            >
              <Card>
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Typography textAlign="center">
                      Unable To Retrieve Data
                    </Typography>
                    <Typography marginBottom="1rem" textAlign="center">
                      Please Try Again
                    </Typography>
                    <Button variant="contained" onClick={this.fetchClaims}>
                      Retry
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          )}

          {showClaimDialog && (
            <ClaimEdit
              getStatusText={this.getStatusText}
              handleCloseClaim={this.handleCloseClaim}
              open={showClaimDialog}
              selectedClaim={this.state.selectedClaim}
            />
          )}

          {showClaimError && (
            <SimpleMessageDialog
              open={showClaimError}
              closeHandler={this.props.closeClaimErrorDialog}
              title="Claim Processing Error"
              description={
                claimErrors !== ""
                  ? claimErrors
                  : "Error while processing the claims."
              }
              closeButtonText={"OK"}
            />
          )}
        </div>
        <LoadingPopup name="my-claim-loading" open={isLoading} />
      </div>
    );
  }
}
const styles = theme => ({
  root: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
      marginRight: 10
    }
  },
  content: {
    padding: theme.spacing.unit * 2.5
  },
  tabRoot: {
    minWidth: "auto",
    marginRight: theme.spacing.unit * 3
  },
  labelContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    textTransform: "none"
  },
  indicator: {
    backgroundColor: theme.colors.primary.red,
    height: 3
  }
});

function mapStateToProps(state) {
  const {
    showClaimDialog,
    showClaimError,
    isLoading,
    claimErrors,
    claimsListError
  } = getEclaimState(state);

  return {
    showClaimDialog,
    showClaimError,
    isLoading,
    claimErrors,
    claimsListError
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(eClaimActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MyClaims));
