import GTL from "./GTL";
import GHS from "./GHS";
import GTL_2021 from "./GTL_2021";
import GHS_2021 from "./GHS_2021";
import GFWM from "./GFWM";

// Plans ----------------------
const CorePlans = [GTL, GHS, GTL_2021, GHS_2021, GFWM];

export default CorePlans;
