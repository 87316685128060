import Enums from "../../../../utils/Enums";

let VASdoc = [
  {
    desc: "eyesight.sg Promo 1",
    file: require("./eyesightsg1.pdf").default,
    fileName: "eyesightsg1.pdf",
    promoCode: Enums.VAS_PROMOCODE.EYESIGHT.CODE1
  },
  {
    desc: "eyesight.sg Promo 2",
    file: require("./eyesightsg2.pdf").default,
    fileName: "eyesightsg2.pdf",
    promoCode: Enums.VAS_PROMOCODE.EYESIGHT.CODE2
  },
  {
    desc: "eyesight.sg Promo 3",
    file: require("./eyesightsg3.pdf").default,
    fileName: "eyesightsg3.pdf",
    promoCode: Enums.VAS_PROMOCODE.EYESIGHT.CODE3
  },
  {
    desc: "iwellness Promo 1",
    file: require("./iwellness1.pdf").default,
    fileName: "iwellness1.pdf",
    promoCode: Enums.VAS_PROMOCODE.IWELLNESS.CODE1
  },
  {
    desc: "iwellness Promo 2",
    file: require("./iwellness2.pdf").default,
    fileName: "iwellness2.pdf",
    promoCode: Enums.VAS_PROMOCODE.IWELLNESS.CODE2
  },
  {
    desc: "iwellness Promo 3",
    file: require("./iwellness3.pdf").default,
    fileName: "iwellness3.pdf",
    promoCode: Enums.VAS_PROMOCODE.IWELLNESS.CODE3
  },
  {
    desc: "craftandculture Promo 1",
    file: require("./CraftnCulture1.pdf").default,
    fileName: "CraftnCulture1.pdf",
    promoCode: Enums.VAS_PROMOCODE.CRAFTNCULTURE.CODE1
  },
  {
    desc: "craftandculture Promo 2",
    file: require("./CraftnCulture2.pdf").default,
    fileName: "CraftnCulture2.pdf",
    promoCode: Enums.VAS_PROMOCODE.CRAFTNCULTURE.CODE2
  }
];

export default VASdoc;
