import { withStyles } from "@mui/styles";
import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import { injectIntl } from "react-intl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Paper from "@mui/material/Paper";
import MobileDetect from "mobile-detect";
import SelectValidatorElement from "../../../../components/form/SelectValidatorElement";
import DatePickerValidatorElement from "../../../../components/form/DatePickerValidatorElement";
import TextValidatorElement from "../../../../components/form/TextValidatorElement";
import Enums from "../../../../utils/Enums";

const mobileDetect = new MobileDetect(window.navigator.userAgent);

const claimTypeData = [
  {
    label: "Select One",
    value: "-"
  },
  {
    label: "Inpatient",
    value: Enums.CLAIM_TYPE.INPATIENT,
    code: {
      PTF: [Enums.PLANS_CODE.GHSB, Enums.PLANS_CODE.GEMM],
      PF3: [
        Enums.PLANS_CODE.GHSB,
        Enums.PLANS_CODE.GEMM,
        Enums.PLANS_CODE.GFWM
      ],
      PEP: [Enums.PLANS_CODE.GHSB, Enums.PLANS_CODE.GEMM],
      GCP: [
        Enums.PLANS_CODE.GHSB,
        Enums.PLANS_CODE.GSMM,
        Enums.PLANS_CODE.GEMM,
        Enums.PLANS_CODE.GFWM
      ],
      PCE: [
        Enums.PLANS_CODE.GHSB,
        Enums.PLANS_CODE.GEMM,
        Enums.PLANS_CODE.GSMM,
        Enums.PLANS_CODE.P3HS,
        Enums.PLANS_CODE.GHPS,
        Enums.PLANS_CODE.PEHS,
        Enums.PLANS_CODE.GHSR,
        Enums.PLANS_CODE.GFWM
      ]
    }
  },
  {
    label: "Outpatient - General Practitioners (GP)",
    value: Enums.CLAIM_TYPE.OUTPATIENT_GP,
    code: {
      PTF: [Enums.PLANS_CODE.GPRU, Enums.PLANS_CODE.PGIH],
      PF3: [Enums.PLANS_CODE.GPRU, Enums.PLANS_CODE.PGIH],
      PEP: [Enums.PLANS_CODE.GPRU, Enums.PLANS_CODE.PGIH],
      GCP: [Enums.PLANS_CODE.GPRU, Enums.PLANS_CODE.PGIH],
      PCE: [
        Enums.PLANS_CODE.GPRU,
        Enums.PLANS_CODE.PGIH,
        Enums.PLANS_CODE.P3GP,
        Enums.PLANS_CODE.PEGP,
        Enums.PLANS_CODE.PGAH,
        Enums.PLANS_CODE.PGFH,
        Enums.PLANS_CODE.PGPW,
        Enums.PLANS_CODE.PGAT
      ]
    }
  },
  {
    label: "Outpatient - Specialist",
    value: Enums.CLAIM_TYPE.OUTPATIENT_SP,
    code: {
      PTF: [Enums.PLANS_CODE.GOSP, Enums.PLANS_CODE.PSIH],
      PF3: [Enums.PLANS_CODE.GOSP, Enums.PLANS_CODE.PSIH],
      GCP: [
        Enums.PLANS_CODE.GOSP,
        Enums.PLANS_CODE.PSMH,
        Enums.PLANS_CODE.PPIH
      ],
      PCE: [
        Enums.PLANS_CODE.GOSP,
        Enums.PLANS_CODE.PSIH,
        Enums.PLANS_CODE.PSMH,
        Enums.PLANS_CODE.P3SP,
        Enums.PLANS_CODE.PESP,
        Enums.PLANS_CODE.PSAH,
        Enums.PLANS_CODE.PSFH,
        Enums.PLANS_CODE.PSPW,
        Enums.PLANS_CODE.PPIH
      ]
    }
  },
  {
    label: "Outpatient - Dental",
    value: Enums.CLAIM_TYPE.OUTPATIENT_DENTAL,
    code: {
      PTF: [Enums.PLANS_CODE.GDEN],
      PF3: [Enums.PLANS_CODE.GDEN, Enums.PLANS_CODE.PDIH],
      GCP: [Enums.PLANS_CODE.GDEN, Enums.PLANS_CODE.PDIH],
      PCE: [Enums.PLANS_CODE.GDEN, Enums.PLANS_CODE.PDIH]
    }
  },
  {
    label: "Outpatient - Health-Screen/Vaccinations",
    value: Enums.CLAIM_TYPE.OUTPATIENT_HS_VAC,
    code: {
      PCE: [Enums.PLANS_CODE.HSVC]
    }
  },
  {
    label: "Maternity",
    value: Enums.CLAIM_TYPE.MATERNITY,
    code: {
      PCE: [Enums.PLANS_CODE.GMAT]
    }
  },
  {
    label: "Outpatient",
    value: Enums.CLAIM_TYPE.OUTPATIENT,
    code: {
      PCE: [Enums.PLANS_CODE.GOPB]
    }
  }
];

const getSelectedTiers = loggedUser => {
  const { policy, category } = loggedUser;
  const selectedTiers = {};
  const selectedCategory = policy.data.categories.find(
    cat => cat.name === category
  );
  if (selectedCategory) {
    const { plansConfiguration } = selectedCategory;
    plansConfiguration.forEach(p => {
      if (p.code === "GFWM") {
        const extendedBenefit = policy.data.extendedBenefit.find(
          c => c.categoryName === selectedCategory.name
        );
        if (extendedBenefit) {
          if (!selectedTiers[p.code] && extendedBenefit.tier)
            selectedTiers[p.code] = [];
          if (extendedBenefit.tier)
            selectedTiers[p.code].push(extendedBenefit.tier);
        }
      } else {
        if (!selectedTiers[p.code] && p.tier) selectedTiers[p.code] = [];
        if (p.tier) selectedTiers[p.code].push(p.tier);
      }
    });
  }
  return selectedTiers;
};

const getBenefitType = loggedUser => {
  const productCode = loggedUser.policy.product_code;
  let allBenefitCodes = [{ label: "Select One", value: "-" }];
  if (Enums.PACKAGED_PLANS.includes(productCode)) {
    const planPGIH = loggedUser.policy.data.categories[0].plansConfiguration.find(
      plan => plan.code === "PGIH"
    );
    if (planPGIH && planPGIH.benefitCode) {
      const benefitCodes = planPGIH.benefitCode;

      benefitCodes.map(item => {
        if (item.code != "OP01" && item.code != "ECON") {
          allBenefitCodes.push({
            label: item.name,
            value: item.code
          });
        }
      });
    }
  }
  return allBenefitCodes;
};

const ClaimEditClaimTypeSection = ({
  claim,
  eclaim,
  isPCEClient,
  handleChange,
  handleDateChange,
  formatDate,
  loggedUser,
  readOnly,
  shouldHide,
  classes,
  intl
}) => {
  const {
    accident_date: accidentDate,
    accident_description: accidentDescription,
    accident_work_related: accidentWorkRelated,
    cause_of_claim_type: causeOfClaimType,
    claimant_id: claimantId,
    claim_status: claimStatus,
    claim_type: claimType,
    benefit_type: benefitType,
    illness_description: illnessDescription,
    illness_start_date: illnessStartDate
  } = claim;
  const productCode = loggedUser.policy.product_code;

  const benefitTypeList = getBenefitType(loggedUser);
  const claimants = useMemo(() => {
    let claimants = eclaim.claimants;
    if (!claimants || claimants.length === 0) {
      return [];
    }
    if (isPCEClient) {
      // if this client doesnt have any eligible plan code, he/she is not allowed to fill in this form.
      const pceCodes = claimTypeData.reduce((ret, e) => {
        const { code = [] } = e;
        return [...ret, ...(code.PCE || [])];
      }, []);
      claimants = claimants
        .map(({ plan_codes = [], ...rest }) => {
          const planCodes = (plan_codes || []).filter(e =>
            pceCodes.includes(e)
          );
          return {
            planCodes,
            ...rest
          };
        })
        .filter(({ planCodes }) => planCodes.length > 0);
    }
    return [
      {
        label: "Select One",
        value: "-"
      },
      ...claimants.map(
        ({
          id,
          full_name: fullName,
          first_name: firstName,
          last_name: lastName,
          planCodes = []
        }) => {
          return {
            label: `${fullName || firstName + " " + lastName}`,
            value: `${id}`,
            planCodes
          };
        }
      )
    ];
  }, [eclaim, isPCEClient]);

  const causeOfClaimData = useMemo(() => {
    switch (claimType) {
      case Enums.CLAIM_TYPE.INPATIENT:
        return [
          {
            label: "Select One",
            value: "-"
          },
          {
            label: "Illness",
            value: Enums.CAUSE_OF_CLAIM.ILLNESS
          },
          {
            label: "Accident",
            value: Enums.CAUSE_OF_CLAIM.ACCIDENT
          }
        ];
      case Enums.CLAIM_TYPE.MATERNITY:
        return [
          {
            label: "Select One",
            value: "-"
          },
          {
            label: "Normal Childbirth",
            value: Enums.CAUSE_OF_CLAIM.NORMAL_CHILDBIRTH
          },
          {
            label: "Childbirth involving Cesarean Section",
            value: Enums.CAUSE_OF_CLAIM.CHILDBIRTH_INVOLVING_CESAREAN_SECTION
          },
          {
            label: "Miscarriage",
            value: Enums.CAUSE_OF_CLAIM.MISCARRIAGE
          }
        ];
      default:
        return [];
    }
  }, [claimType]);

  const formattedClaimTypeData = useMemo(() => {
    if (readOnly) {
      return claimTypeData.map(({ label, value }) => ({ label, value }));
    }
    const getEnrolledPlanCodes = (loggedUser, isPCEClient) => {
      if (isPCEClient) {
        const targetClaimant = claimants.find(
          ({ value }) => value === claimantId
        );
        if (!targetClaimant) {
          return [];
        }
        const { planCodes } = targetClaimant;
        return planCodes || [];
      } else {
        return Object.keys(getSelectedTiers(loggedUser));
      }
    };
    const targetFilter = (prodCode = "", planCodes = []) => ({ code }) => {
      if (!code) {
        // for default "Select One" option, which is enabled for all without code filtering
        return true;
      }
      const codeList = code[prodCode];
      if (!codeList || codeList.length === 0) {
        return false;
      }
      return planCodes.some(c => codeList.includes(c));
    };
    const { policy } = loggedUser;
    const { product_code: prodCode } = policy;
    const enrolledPlanCodes = getEnrolledPlanCodes(loggedUser, isPCEClient);
    const filter = targetFilter(prodCode, enrolledPlanCodes);
    return claimTypeData
      .filter(filter)
      .map(({ label, value }) => ({ label, value }));
  }, [loggedUser, isPCEClient, readOnly, claimants, claimantId]);

  const getClaimTypeHintText = claimType => {
    switch (claimType) {
      case Enums.CLAIM_TYPE.INPATIENT:
      case Enums.CLAIM_TYPE.MATERNITY:
        return (
          <Typography className={classes.hintText}>
            Please attach:
            <br />
            1. Original final hospital bills, tax invoices and receipts
            <br />
            2. Inpatient Discharge Summary if your admission is in a Singapore
            Government Restructured Hospital
            <br />
            3. Part C of the GHS Claim Form if your admission is in a Private
            Hospital or Overseas Hospital (found in the Documents page)
            <br />
            4. Settlement letter if there is any payment from your Integrated
            Shield Plan or 3rd party&#39;s insurance plan
            <br />
            5. A copy of &#39;CPF Medisave Transaction for Medical Expenses&#39;
            showing the HRN code for bills with medisave payment
            <br />
            6. Proof of payment by you if you co-pay part of the bill. (e.g.
            copy of payslip)
          </Typography>
        );
      case Enums.CLAIM_TYPE.OUTPATIENT_GP:
        return (
          <Typography className={classes.hintText}>
            Please attach:
            <br />
            1. Original tax invoices and receipts
            <br />
            2. A copy of &#39;CPF Medisave Transaction for Medical Expenses&#39;
            showing the HRN code for bills with medisave payment
            <br />
            3. Proof of payment by you if you co-pay part of the bill. (e.g.
            copy of payslip)
          </Typography>
        );
      case Enums.CLAIM_TYPE.OUTPATIENT_SP:
        return (
          <Typography className={classes.hintText}>
            Please attach:
            <br />
            1. Original tax invoices and receipts
            <br />
            2. A copy of referral letter
            <br />
            3. A copy of &#39;CPF Medisave Transaction for Medical Expenses&#39;
            showing the HRN code for bills with medisave payment
            <br />
            4. Proof of payment by you if you co-pay part of the bill. (e.g.
            copy of payslip)
          </Typography>
        );
      case Enums.CLAIM_TYPE.OUTPATIENT:
        return (
          <Typography className={classes.hintText}>
            Please attach:
            <br />
            1. Original tax invoices and receipts
            <br />
            2. A copy of referral letter for initial visit to Specialist
            <br />
            3. A copy of &#39;CPF Medisave Transaction for Medical Expenses&#39;
            showing the HRN code for bills with medisave payment
            <br />
            4. Proof of payment by you if you co-pay part of the bill. (e.g.
            copy of payslip)
          </Typography>
        );
      case Enums.CLAIM_TYPE.OUTPATIENT_DENTAL:
        return (
          <Typography className={classes.hintText}>
            Please attach:
            <br />
            1. Original tax invoices and receipts
            <br />
            2. A copy of &#39;CPF Medisave Transaction for Medical Expenses&#39;
            showing the HRN code for bills with medisave payment
          </Typography>
        );
      case Enums.CLAIM_TYPE.OUTPATIENT_HS_VAC:
        return (
          <Typography className={classes.hintText}>
            Please attach:
            <br />
            1. Original tax invoices and receipts
          </Typography>
        );
      default:
        return null;
    }
  };

  const renderCauseOfClaim = () => {
    if (
      shouldHide ||
      ![Enums.CLAIM_TYPE.INPATIENT, Enums.CLAIM_TYPE.MATERNITY].includes(
        claimType
      )
    ) {
      return <div />;
    }
    const causeOfClaimView = [
      <SelectValidatorElement
        key={"cause_of_claim_view"}
        disabled={readOnly}
        value={causeOfClaimType || ""}
        variant="standard"
        onChange={handleChange("cause_of_claim_type")}
        name={"cause_of_claim_type"}
        id={"cause_of_claim_type"}
        label="Cause Of Claim"
        autoComplete="[off]"
        validators={["isSelectRequired"]}
        suggestions={causeOfClaimData}
        errorMessages={["Required"]}
      />
    ];

    switch (causeOfClaimType) {
      case Enums.CAUSE_OF_CLAIM.ILLNESS:
        causeOfClaimView.push([
          <TextValidatorElement
            disabled={readOnly}
            key="illness_description"
            autoComplete="[off]"
            variant="standard"
            fullWidth
            inputProps={{
              maxLength: 2000
            }}
            className={classes.textField}
            label="Describe the illness"
            onChange={handleChange("illness_description")}
            name="illness_description"
            value={illnessDescription || ""}
            validators={["required"]}
            errorMessages={["Required"]}
          />,
          <TextValidatorElement
            key="illness_start_date"
            disabled={readOnly}
            variant="standard"
            fullWidth
            inputProps={{
              maxLength: 200
            }}
            name={"illness_start_date"}
            addMargin={mobileDetect.mobile() !== null}
            autoComplete="[off]"
            label="Describe when you first experienced your symptoms"
            onChange={handleChange("illness_start_date")}
            value={illnessStartDate || ""}
            validators={["required", "isMaxCharValid"]}
            errorMessages={[
              "Required",
              intl.formatMessage({
                id: "validator.maxChars.exceeded"
              })
            ]}
          />
        ]);
        break;
      case Enums.CAUSE_OF_CLAIM.ACCIDENT:
        causeOfClaimView.push([
          <TextValidatorElement
            disabled={readOnly}
            key="accident_description"
            variant="standard"
            autoComplete="[off]"
            fullWidth
            inputProps={{
              maxLength: 2000
            }}
            className={classes.textField}
            label="Describe how the accident happened"
            onChange={handleChange("accident_description")}
            name="accident_description"
            value={accidentDescription || ""}
            validators={["required"]}
            errorMessages={["Required"]}
          />,
          <DatePickerValidatorElement
            disabled={readOnly}
            key="accident_date"
            fullWidth
            variant="standard"
            name={"accident_date"}
            autoComplete="[off]"
            clearable
            disableFuture
            label="Accident Date"
            value={formatDate(accidentDate)}
            onChange={handleDateChange("accident_date")}
            format="DD/MM/YYYY"
            animateYearScrolling={true}
            keyboard
            validators={["required"]}
            errorMessages={["Required"]}
          />,
          <FormControl
            component="fieldset"
            disabled={readOnly}
            key="accident_work_related"
            className={classes.formControl}
          >
            <FormLabel component="legend">
              Is the accident work related?
            </FormLabel>
            <RadioGroup
              aria-label="accident_work_related"
              name="accident_work_related"
              className={classes.group}
              value={accidentWorkRelated || ""}
              onChange={handleChange("accident_work_related")}
            >
              <FormControlLabel
                value="Y"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="N"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        ]);
        break;
      default:
        break;
    }
    return causeOfClaimView;
  };

  const ClaimantSelect = (
    <SelectValidatorElement
      disabled={readOnly}
      value={claimantId || ""}
      variant="standard"
      onChange={handleChange("claimant_id")}
      name={"claimant_id"}
      id={"claimant_id"}
      label="Claimant"
      autoComplete="[off]"
      validators={["isSelectRequired"]}
      suggestions={claimants}
      errorMessages={["Required"]}
    />
  );
  const planPGIH = loggedUser.policy.data.categories[0].plansConfiguration.find(
    plan => plan.code === "PGIH"
  );
  return (
    <div>
      {isPCEClient && ClaimantSelect}
      <SelectValidatorElement
        disabled={readOnly}
        variant="standard"
        value={claimType || ""}
        onChange={handleChange("claim_type")}
        name={"claim_type"}
        id={"claim_type"}
        label="Claim Type"
        autoComplete="[off]"
        validators={["isSelectRequired"]}
        suggestions={formattedClaimTypeData}
        helperText={
          shouldHide
            ? ""
            : "Please submit only claims which you are covered under. Claims where you don’t have coverage are not reimbursable. For your coverage details, please refer to “My Coverage”"
        }
        errorMessages={["Required"]}
      />
      {claimType === "OUTPATIENT_GP" && planPGIH && planPGIH.benefitCode && (
        <SelectValidatorElement
          disabled={readOnly}
          variant="standard"
          value={benefitType || ""}
          onChange={handleChange("benefit_type")}
          name={"benefit_type"}
          id={"benefit_type"}
          label="Benefit Type"
          autoComplete="[off]"
          validators={["isSelectRequired"]}
          suggestions={benefitTypeList}
          errorMessages={["Required"]}
        />
      )}

      {!claimStatus && claimType && (
        <Paper className={classes.hintTextRoot}>
          {getClaimTypeHintText(claimType)}
        </Paper>
      )}
      {renderCauseOfClaim()}
      {!isPCEClient && ClaimantSelect}
    </div>
  );
};
const styles = theme => ({
  hintTextRoot: {
    paddingLeft: 5,
    marginTop: 30,
    marginBottom: 30,
    backgroundColor: "#3BB1D5"
  },
  hintText: {
    backgroundColor: "white",
    padding: 15
  }
});

export default injectIntl(withStyles(styles)(ClaimEditClaimTypeSection));
