import React, { Component } from "react";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import classNames from "classnames";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

class ConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agree: false
    };
  }

  agreeChange = event => {
    this.setState({ agree: event.target.checked });
  };

  render() {
    const {
      open,
      closeHandler,
      okHandler,
      isPCEHr,
      isPTFHr,
      classes
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={closeHandler}
        maxWidth="xs"
        classes={{
          paper: classes.paper
        }}
      >
        <DialogTitle
          classes={{
            root: classes.dialogTitle
          }}
        >
          Are you sure you want to submit?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            classes={{
              root: classes.description
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  style={{ alignItems: "start", paddingTop: 10 }}
                  onChange={this.agreeChange}
                />
              }
              label={[
                isPCEHr || isPTFHr
                  ? "I am submitting the claim on behalf of the employee and we agree to the"
                  : "I agree to the",
                <a href="/claimTermAndConditions" target="_blank" key={0}>
                  {" "}
                  Declaration, Authorisation and Consent
                </a>
              ]}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.dialogActions
          }}
        >
          <Button
            onClick={closeHandler}
            variant="raised"
            className={classNames(classes.button, classes.cancelButton)}
          >
            Cancel
          </Button>
          <Button
            onClick={okHandler}
            disabled={!this.state.agree}
            variant="contained"
            color="primary"
            className={classNames(classes.button, classes.okButton)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = theme => ({
  paper: {
    padding: 30
  },
  cancelButton: {
    backgroundColor: theme.colors.secondary.white,
    border: `2px solid ${theme.colors.blackScale.black50}`,
    color: theme.colors.blackScale.black100,
    fontWeight: 500
  },
  button: {
    minWidth: 130
  },
  description: {
    color: theme.colors.blackScale.black100
  },
  dialogActions: {
    justifyContent: "center",
    marginTop: 20
  }
});

export default withStyles(styles, { withTheme: true })(ConfirmDialog);
