import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import BoxItem from "./boxItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { getAvaliableProductBenefits } from "../../../../selectors/CommonSelectors";

class Main extends Component {
  constructor(props) {
    super(props);

    const agentType = props.agentType || "AD";
    this.state = {
      listBenefits: getAvaliableProductBenefits(agentType)
    };
    this.route = this.props.route;
    this.showListBenefits = this.showListBenefits.bind(this);
  }

  showListBenefits(classes) {
    let listComponents = this.state.listBenefits.map((data, index) => {
      return (
        <Grid key={index} item className={classes.wrapper} sm={3}>
          <Link
            to={this.route.path + "/details/" + data.key}
            className={classes.menuItem}
          >
            <BoxItem data={data} className={classes.wrapperWithWidth} />
          </Link>
        </Grid>
      );
    });
    return listComponents;
  }

  render() {
    const { classes } = this.props;

    const showProductBenefits = this.state.listBenefits.length > 0;

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Typography
            className={classes.heading}
            variant="title"
            color="inherit"
          >
            Look out for our new and exciting partners soon!
          </Typography>
          <br />
          <Typography
            className={classes.subHeading}
            variant="title"
            color="inherit"
          ></Typography>
          <br />

          <Grid className={classes.listBenefits} container spacing={"8px"}>
            {showProductBenefits && this.showListBenefits(classes)}
          </Grid>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%"
  },
  wrapperWithWidth: {
    width: "320px"
  },

  menuItem: {
    textDecoration: "none"
  },
  heading: {
    paddingTop: "30px",
    fontSize: "28px",
    color: "#333333"
  },
  subHeading: {
    paddingBottom: "30px",
    fontSize: "22px",
    color: "#848689"
  },
  content: {
    padding: "10px 40px"
  },
  listBenefits: {
    flexGrow: 1
  },
  wrapper: {
    padding: 5
  }
});

function mapStateToProps(state) {
  return { agentType: state.user.auth.pcode };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Main));
