import { withStyles } from "@mui/styles";
import React from "react";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import { injectIntl } from "react-intl";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import classNames from "classnames";

import assets from "../../../../assets";
import Enums from "../../../../utils/Enums";

const ClaimEditSubmittedSection = ({
  claim,
  selectedClaim,
  getStatusText,
  classes
}) => {
  const {
    claim_case_notes: claimCaseNotes = "",
    claim_status: claimStatus,
    follow_up: followUp,
    rejection_reason: rejectionReason = ""
  } = claim;

  const getClaimResult = (
    status,
    follow_ups,
    claimCaseNotes,
    rejectionReason
  ) => {
    const claimResultView = subheadingText => [
      <div className={classes.boxHeader}>
        <Icon
          className={classNames(
            classes.boxIcon,
            (status === Enums.CLAIMS_STATUS.APPROVED ||
              status === Enums.CLAIMS_STATUS.ON_HOLD) &&
              classes.boxIconApprove
          )}
          style={{ padding: "1px" }}
        >
          error_outline
        </Icon>

        <Typography
          variant="subheading"
          style={{ marginLeft: 18, display: "inline-block" }}
        >
          {subheadingText}
        </Typography>
      </div>
    ];
    switch (status) {
      case Enums.CLAIMS_STATUS.EDIT_REQUESTED: {
        const view = claimResultView("Action Needed");
        view.push(
          follow_ups
            .filter(fu => fu.status === "O")
            .map((fu, idx) => {
              return (
                <Typography style={{ margin: 10 }} key={idx}>
                  {`Follow Up Reason ${idx + 1}: `}{" "}
                  {fu.extended_reason ? fu.extended_reason : fu.remarks || ""}
                </Typography>
              );
            })
        );
        return view;
      }
      case Enums.CLAIMS_STATUS.APPROVED:
      case Enums.CLAIMS_STATUS.ON_HOLD: {
        const view = claimResultView("Remarks");
        view.push(
          <Typography style={{ margin: 10 }}>{claimCaseNotes}</Typography>
        );
        return view;
      }
      case Enums.CLAIMS_STATUS.REJECTED: {
        const view = claimResultView("Reason For Rejection");
        view.push(
          <Typography style={{ margin: 10 }}>{rejectionReason}</Typography>
        );
        return view;
      }
      default:
        return [];
    }
  };

  const readOnly = !!claimStatus;
  const icons = assets.icons;

  const followUps =
    followUp && followUp["follow_up"] ? followUp["follow_up"] : [];

  const showClaimResult =
    claimStatus === Enums.CLAIMS_STATUS.EDIT_REQUESTED ||
    claimStatus === Enums.CLAIMS_STATUS.APPROVED ||
    claimStatus === Enums.CLAIMS_STATUS.ON_HOLD ||
    claimStatus === Enums.CLAIMS_STATUS.REJECTED;

  return (
    <div>
      {readOnly && (
        <Card className={classes.card} elevator={0}>
          <CardContent className={classes.cardContent}>
            <Grid container spacing={"8px"}>
              <Grid item xs={8}>
                {claimStatus === Enums.CLAIMS_STATUS.APPROVED && (
                  <Typography>Approved Amt</Typography>
                )}
                <Typography>Claim Status</Typography>
                <Typography>Reference No</Typography>
                <Typography>Claim No</Typography>
                <Typography>Submitted Date</Typography>
              </Grid>

              <Grid item xs={4}>
                {claimStatus === Enums.CLAIMS_STATUS.APPROVED && (
                  <Typography>
                    {selectedClaim.pay_back_amount
                      ? `$${selectedClaim.pay_back_amount}`
                      : "-"}
                  </Typography>
                )}
                {getStatusText(claimStatus)}
                <Typography>{selectedClaim.reference_number || "-"}</Typography>
                <Typography>{selectedClaim.claim_no || "-"}</Typography>
                <Typography>{selectedClaim.received_date}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      {showClaimResult && (
        <Card className={classes.card} elevator={0}>
          {getClaimResult(
            claimStatus,
            followUps,
            claimCaseNotes,
            rejectionReason
          )}
        </Card>
      )}

      {claimStatus === Enums.CLAIMS_STATUS.EDIT_REQUESTED && (
        <Card className={classes.card} elevator={0}>
          <CardContent className={classes.cardContent}>
            <div className={classes.boxCenter}>
              <img
                src={icons.mailboxClaim}
                alt="mail box img"
                style={{ width: 50, height: 50 }}
              />
            </div>
            <Typography variant="subheading">
              Please email the documents/information to{" "}
              <b>SGP.ES.Claims@prudential.com.sg</b> and indicate the{" "}
              <i>&lt;Claim No&gt;</i> in your email subject.
            </Typography>
          </CardContent>
        </Card>
      )}
    </div>
  );
};
const styles = theme => ({
  boxHeader: {
    position: "relative",
    borderBottom: "1px solid #d3d3d3"
  },
  boxIcon: {
    backgroundColor: "#d87474",
    color: "white",
    padding: 8,
    display: "inline-block",
    verticalAlign: "middle"
  },
  boxIconApprove: {
    backgroundColor: "#18c494"
  },
  boxCenter: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 10
  },
  card: {
    marginBottom: 20
  }
});

export default injectIntl(withStyles(styles)(ClaimEditSubmittedSection));
