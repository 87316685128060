import * as actionTypes from "../actions/types";
import moment from "moment";
import Util from "../utils/Util";
import Enums from "../utils/Enums";

const initialState = {
  id: null,
  fullName: "",
  designation: "Managing Partner",
  category: "",
  dependencies: [],
  featching: false,
  fetched: false,
  error: null,
  planDetails: null
};

const formatDate = unixTimestamp => {
  if (isNaN(unixTimestamp) || !unixTimestamp) {
    return unixTimestamp;
  } else {
    return moment(unixTimestamp).format("L");
  }
};
const formatDependent = ({ dob, date_of_employment, ...data }) => ({
  dob: formatDate(dob),
  date_of_employment: formatDate(date_of_employment),
  ...data
});

const reducer = (state = initialState, action) => {
  function decodeAccessToken(token) {
    const decoded = Util.jwt_decode(token);
    return {
      expire_time: moment.unix(decoded.exp),
      role: decoded.ar,
      pid: decoded.pid,
      pcode: decoded.pcode,
      scope: decoded.scope.join(" ")
    };
  }
  switch (action.type) {
    case actionTypes.USER_UPDATE_TOKEN: {
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        auth: Object.assign(
          {
            ...state.auth,
            access_token: action.payload.access_token,
            refresh_token: action.payload.refresh_token
          },
          decodeAccessToken(action.payload.access_token)
        )
      };
    }
    case actionTypes.USER_LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true
      };
    case actionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: action.user.scope.includes(Enums.SCOPE_TYPE.TC_W)
          ? true
          : false,
        loggedIn: action.user.scope.includes(Enums.SCOPE_TYPE.TC_W)
          ? false
          : true,
        auth: Object.assign(
          { ...action.user },
          decodeAccessToken(action.user.access_token)
        )
      };
    case actionTypes.USER_EMULATE_LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        auth: {
          ...state.auth,
          ...action.payload,
          refresh_token: undefined,
          ...decodeAccessToken(action.payload.access_token)
        }
      };
    case actionTypes.USER_LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        error: action.error
      };
    case actionTypes.USER_REFRESH_TOKEN_PENDING:
      return {
        ...state,
        isTokenRefreshing: true,
        isTokenRefreshed: false,
        isTokenRefreshError: false
      };
    case actionTypes.USER_REFRESH_TOKEN_FULFILLED:
      return {
        ...state,
        isTokenRefreshing: false,
        isTokenRefreshed: true,
        tokenRefreshedTime: new Date(),
        auth: Object.assign(
          {
            ...action.payload.data,
            access_token: action.payload.data.access_token,
            refresh_token: action.payload.data.refresh_token
          },
          decodeAccessToken(action.payload.data.access_token)
        )
      };
    case actionTypes.USER_REFRESH_TOKEN_REJECTED:
      return {
        ...state,
        isTokenRefreshing: false,
        isTokenRefreshed: false,
        isTokenRefreshError: true
      };
    case actionTypes.USER_GET_INFO_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        fullName: action.payload.full_name,
        coverage_start_date: action.payload.coverage_start_date,
        coverage_end_date: action.payload.coverage_end_date,
        dob: action.payload.dob,
        email: action.payload.email,
        date_of_employment: formatDate(action.payload.date_of_employment),
        gender: action.payload.gender,
        passport_nric: action.payload.id_no,
        country_of_residence: action.payload.country_of_residence,
        marital_status: action.payload.marital_status,
        nationality: action.payload.nationality,
        account_id: action.payload.account_id
      };
    case actionTypes.USER_GET_POLICY_SUCCESS:
      return {
        ...state,
        policy: action.payload
      };

    case actionTypes.USER_GET_GA_POLICY_FULFILLED:
      return {
        ...state,
        gaInfo: action.payload
      };
    case actionTypes.EMPLOYEE_GET_INFO_SUCCESS:
      return {
        ...state,
        category: action.payload.category,
        underwriting_status: action.payload.underwriting_status,
        dependencies: action.payload.dependants.map(formatDependent)
      };

    case actionTypes.EMPLOYEE_GET_INFO_ERROR:
      return {
        ...state,
        dependencies: []
      };

    case actionTypes.USER_LOGOUT:
      return {
        ...state,
        auth: null,
        loggedIn: false
      };

    case actionTypes.APP_SET_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case actionTypes.EMPLOYEE_GET_BANNER_SUCCESS:
      return {
        ...state,
        banner: action.payload
      };

    case actionTypes.EMPLOYEE_GET_BANNER_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case actionTypes.EMPLOYEE_COVERAGE_SUM_ASSURED_SUCCESS:
      return {
        ...state,
        planDetails: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
