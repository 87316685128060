import React, { Component } from "react";
import Icon from "@mui/material/Icon";
import { withStyles } from "@mui/styles";
import Input from "@mui/material/Input";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import MobileDetect from "mobile-detect";
import ReactList from "react-list";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { clinicActions } from "../../../../actions";

const mobileDetect = new MobileDetect(window.navigator.userAgent);

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTable: true,
      keyword: "",
      data: [],
      allData: []
    };
  }

  componentWillMount() {
    this.props.fetchClinics();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clinics.makers && nextProps.clinics.makers.length > 0) {
      let data = nextProps.clinics.makers.slice(0);
      this.setState({ data: data, allData: data });
    }
  }

  viewAllData() {
    this.searchInputRef.value = "";
    this.setState({ showTable: true, data: this.state.allData });
  }

  /**
   * Array filters items based on search criteria (query)
   */
  filterItems(arrObjects, query) {
    return arrObjects.filter(function(el) {
      return (
        el.clinic_name.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        el.address.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
        el.tel.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    });
  }

  calcLazyLoadLength = input => {
    let whole = Math.floor(input.length / 3);
    let rem = input.length % 3;
    if (rem !== 0) whole++;
    return whole;
  };

  processLazyLoadingData = input => {
    let out = [];
    let outIndex = -1;
    input.map((d, index) => {
      if (index % 3 === 0) {
        out.push([]);
        outIndex++;
      }
      out[outIndex].push(d);
      return 0;
    });
    return out;
  };

  renderItem = (index, key) => {
    const { classes } = this.props;
    const { data } = this.state;
    let lazyData = this.processLazyLoadingData(data);
    if (index >= lazyData.length) return <p />;
    let arr = lazyData[index];
    return (
      <Grid
        key={key}
        className={classes.list}
        container
        alignItems="stretch"
        spacing={"24px"}
      >
        {arr.map((d, index) => {
          const fields = [
            "operation_hours_mon_fri",
            "operation_hours_sat",
            "operation_hours_sun"
          ];
          fields.forEach(f => {
            //Remove double line break
            if (d[f].indexOf("\n\n") >= 0) d[f] = d[f].replace("\n\n", ", ");
            //Remove single line break
            if (d[f].indexOf("\n") >= 0) d[f] = d[f].replace("\n", ", ");
          });
          return (
            <Grid key={index} item xs={12} md={4}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography className={classes.title}>
                    {d.clinic_name}
                  </Typography>
                  <List>
                    <ListItem className={classes.listItem}>
                      <Avatar className={classes.iconHolder}>
                        <i className={"material-icons " + classes.icon}>
                          location_on
                        </i>
                      </Avatar>
                      <Typography>
                        {mobileDetect.mobile() === null ? (
                          <a
                            className={classes.map}
                            href={
                              "https://www.google.com/maps/search/?q=" +
                              d.address
                            }
                            target="_blank"
                          >
                            {d.address}
                          </a>
                        ) : (
                          <a
                            className={classes.map}
                            href={
                              "maps://www.google.com/maps/search/?q=" +
                              d.address
                            }
                            target="_blank"
                          >
                            {d.address}
                          </a>
                        )}
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <Avatar className={classes.iconHolder}>
                        <i className={"material-icons " + classes.icon}>call</i>
                      </Avatar>
                      <div>
                        {this.flagTelNumberList(d.tel).map((tel, index) => {
                          return (
                            <Typography key={index}>
                              <a className={classes.dial} href={"tel:" + tel}>
                                {this.processNumber(tel)}
                              </a>
                            </Typography>
                          );
                        })}
                      </div>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <Avatar className={classes.iconHolder}>
                        <i className={"material-icons " + classes.icon}>
                          access_time
                        </i>
                      </Avatar>
                      <div>
                        <Typography className={classes.opHours}>
                          {"Mon-Fri: " + d.operation_hours_mon_fri}
                        </Typography>
                        <Typography className={classes.opHours}>
                          {"Sat: " + d.operation_hours_sat}
                        </Typography>
                        <Typography className={classes.opHours}>
                          {"Sun: " + d.operation_hours_sun}
                        </Typography>
                        <Typography className={classes.opHours}>
                          {"Public Holiday: " + d.public_holiday}
                        </Typography>
                      </div>
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  flagTelNumberList = input => {
    //flag tel no with "/" for multiple postfix
    let outArr = [];
    input = input.replace(/\s/g, "");
    let arr = input.split("/");
    let tel = arr[0];
    outArr = [];
    outArr.push(tel);
    let first = true;
    arr.forEach(postfix => {
      if (first) {
        first = false;
        return;
      }
      outArr.push(tel.substring(0, tel.length - postfix.length) + postfix);
    });
    return outArr;
  };

  processNumber = number => {
    return number.slice(0, 4) + " " + number.slice(4);
  };

  filterData = event => {
    if (event.key === "Enter") {
      this.setState({
        keyword: event.target.value
      });
      if (event.target.value) {
        //call filter table data
        this.setState({
          data: this.filterItems(this.state.allData, event.target.value)
        });
      } else {
        this.setState({ data: this.state.allData });
      }
    }
  };

  handleClick = () => {
    this.filterData({ key: "Enter", target: { value: this.state.keyword } });
  };

  render() {
    const { classes } = this.props;
    const { data } = this.state;

    return (
      <div className={classes.root}>
        <div>
          <Grid
            className={classes.search}
            alignItems="center"
            container
            spacing={"24px"}
          >
            <Grid item xs={10} md>
              <FormControl
                fullWidth
                className={classes.formControl}
                margin={"normal"}
              >
                <Input
                  className={classes.input}
                  onBlur={input => {
                    this.setState({ keyword: input.target.value });
                  }}
                  onKeyPress={this.filterData}
                  placeholder="Search for clinic name or address"
                />
              </FormControl>
            </Grid>
            <Grid item xs={1} md>
              <Button
                onClick={this.handleClick}
                mini
                variant="fab"
                color="primary"
              >
                <Icon>search</Icon>
              </Button>
            </Grid>
          </Grid>
          <div className={classes.result}>
            <Typography>{data.length} GP clinics</Typography>
            <ReactList
              itemRenderer={this.renderItem}
              length={this.calcLazyLoadLength(data)}
              updateWhenThisValueChanges={data}
              threshold={1000}
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: mobileDetect.mobile() === null ? 100 : 30
  },
  formControl: {
    margin: 0
  },
  input: {
    padding: 10
  },
  search: {
    marginTop: 20
  },
  result: {
    marginTop: 35
  },
  map: {
    textDecoration: "none",
    color: `${theme.colors.primary.red}`
  },
  dial: {
    textDecoration: "none",
    color: `${theme.colors.primary.red}`
  },
  list: {
    marginTop: 10
  },
  card: {
    height: "100%"
  },
  cardContent: {
    paddingBottom: "0!important"
  },
  title: {
    fontSize: "18px"
  },
  icon: {
    fontSize: "15px",
    color: "#313131",
    marginRight: "15px"
  },
  iconHolder: {
    background: "none",
    width: "auto",
    height: "auto"
  },
  listItem: {
    alignItems: "flex-start"
  },
  opHours: {
    marginBottom: 6
  }
});

function mapStateToProps(state, prop) {
  return {
    clinics: state.clinics
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(clinicActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Search));
