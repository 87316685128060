import * as types from '../actions/types'

const initialState = {
  makers: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MAKERS_SUCCESS: {
      console.log(" redurce call dis");
      return {
        ...state,
        makers: action.makers
      };
    }
    default:
      return state;
  }
};

export default reducer;
