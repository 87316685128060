import bowser from "bowser";

const BrowserDection = {
  init: () => {
    let htmlElement = document.getElementsByTagName("html")[0];
    for (var name in bowser) {
      let value = bowser[name];
      if (value === true) {
        htmlElement.classList.add(name);
      }
    }
  }
};

export default BrowserDection;
