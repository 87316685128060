import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Interweave } from "interweave";
import path from "path-browserify";
import classNames from "classnames";
import React, { Component } from "react";

class Banner extends Component {
  render() {
    const { classes, banner } = this.props;
    return (
      <div>
        <Grid container spacing={"0px"}>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            className={`${classes.hideIfDesktop} ${classes.logo}`}
          >
            <p className={classes.logoHolderTop}>
              {banner.logo.map((img, index) => {
                return (
                  <img
                    key={index}
                    src={img}
                    alt="Logo"
                    className={classes.logoOnTop}
                  />
                );
              })}
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <p>
              <img
                src={banner.background}
                alt="background-image"
                style={{ width: "100%" }}
                className={classes.logoOnLeft}
              />
            </p>
          </Grid>

          <Grid item xs={3} sm={8} md={8}>
            <p className={classes.logoHolderRight}>
              {banner.logo.map((img, index) => {
                return (
                  <img
                    key={index}
                    src={img}
                    alt="Logo"
                    className={classes.logoOnRight}
                  />
                );
              })}
            </p>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  logo: {
    paddingLeft: "20px"
  },
  buttonOnBanner: {
    minWidth: 175,
    marginTop: 30,
    marginRight: 15
  },
  hideIfDesktop: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  },
  logoOnTop: {
    height: 40
  },
  logoOnRight: {
    maxHeight: 120,
    maxWidth: 280
  },

  logoOnLeft: {
    [theme.breakpoints.up("md")]: {
      height: 320
    }
  },
  logoHolderRight: {
    textAlign: "right",
    marginTop: 10,
    marginRight: 10,
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  buttonFirst: {
    marginRight: 10,
    padding: "10px 25px",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      marginRight: "auto"
    }
  },
  buttonSecond: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.colors.primary.red}`,
    color: theme.colors.primary.red,
    boxShadow: "none",
    padding: "10px 25px"
  },
  space: {
    padding: "20px"
  },
  banner: {
    padding: 40,
    minHeight: 240,
    backgroundSize: "cover",
    backgroundPosition: "100% 100%",
    backgroundRepeat: "no-repeat"
  },
  bannerHeader: {
    fontSize: 37,
    [theme.breakpoints.down("xs")]: {
      fontSize: 25
    }
  },
  bannerDesc: {
    lineHeight: 1.5,
    fontSize: 21,
    [theme.breakpoints.down("xs")]: {
      fontSize: 19
    }
  },
  subInformation: {
    padding: 40,
    paddingTop: 20,
    backgroundColor: theme.colors.blackScale.black50
  },
  subTitle: {
    fontSize: 24,
    marginTop: 0,
    marginBottom: 10
  },
  subDesc: {
    color: theme.colors.blackScale.black80,
    paddingTop: 5,
    fontSize: 15
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Banner));
