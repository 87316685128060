import { Field, reduxForm } from "redux-form";
import { renderTextField } from "../../components/CustomReduxFormComponent";
import React from "react";
import Typography from "@mui/material/Typography";
import SimpleControlDialog from "../../components/SimpleControlDialog";

const required = value => (value ? undefined : "Required");

class OtpDialog extends React.Component {
  componentWillReceiveProps(nextProps) {
    //reset form when open
    if (this.props.show !== nextProps.show) this.props.reset();
  }
  submit = values => {
    this.props.submitHandler(values.otp);
  };
  render() {
    return (
      <SimpleControlDialog
        show={this.props.show}
        title="Enter OTP"
        description={this.props.description}
        onClose={this.props.closeHandler}
        closeHandler={this.props.closeHandler}
        valid={this.props.valid}
        onSubmit={this.props.handleSubmit(this.submit)}
        okButtonText="Submit"
        closeButtonText="Cancel"
      >
        <Field
          name="otp"
          autoFocus
          margin="dense"
          component={renderTextField}
          autoComplete="[off]"
          label="OTP"
          type="text"
          onChange={this.props.inputHandler}
          validate={required}
        />
        {this.props.submitError && (
          <Typography
            variant="caption"
            style={{ color: "red", whiteSpace: "pre-line", textAlign: "left" }}
          >
            {this.props.submitErrorMessage}
          </Typography>
        )}
      </SimpleControlDialog>
    );
  }
}
export default reduxForm({
  form: "otp-form"
})(OtpDialog);
