import Util from "../utils/Util";
import assets from "../assets";
import _ from "lodash";
import Enums from "../utils/Enums";

const GRAB_PRODUCT_CODE = "GCP";

export const getAccessToken = state => {
  const token =
    state.user && state.user.user ? state.user.user.access_token : "";
  return token;
};

export const getEclaimState = state => {
  return state.eclaim;
};

export const getCategories = quote => {
  const categories = quote.data.categories;
  return categories.map(cat => ({
    ...cat,
    plansConfiguration: cat.plansConfiguration.filter(c => {
      return !Util.isEmpty(c.tier);
    })
  }));
};

export const getCategoryName = quote => {
  const categories = quote.data.categories;
  return categories.map(cat => cat.name);
};

export const getAvaliableProductBenefits = agentType => {
  const availableProducts = assets.productInfo.availableProducts[agentType];
  return _.filter(assets.benefits.list.default, ({ key }) =>
    _.includes(availableProducts, key)
  );
};

export const isGrabGroup = (appType, HR) =>
  appType === Enums.APP_TYPE.HR &&
  HR &&
  HR.coverage.policy.product_code === GRAB_PRODUCT_CODE;

export const isGrabGroupEmployee = EMPLOYEE =>
  EMPLOYEE &&
  EMPLOYEE.policy &&
  EMPLOYEE.policy.product_code === GRAB_PRODUCT_CODE;
