import React, { Component } from "react";
import { renderRoutes } from "react-router-config";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import { isGrabGroupEmployee } from "../../../selectors/CommonSelectors";

class EmployeeBenefit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listBenefits: []
    };
    this.route = this.props.route;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.props.user && !isGrabGroupEmployee(this.props.user) && (
          <main>
            {/* -- Render Child pages */}
            {renderRoutes(this.route.routes)}
          </main>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%"
  },
  menuItem: {
    textDecoration: "none"
  },
  content: {
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10
  },
  listBenefits: {
    flexGrow: 1
  },
  wrapper: {
    padding: 5
  }
});

function mapStateToProps(state) {
  return {
    agentType: state.user.auth.pcode,
    app: state.app,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(EmployeeBenefit));
