import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";

import Plans from "../../../components/Plans";

const styles = theme => ({
  root: {}
});

function MyDependantCoverage(props) {
  const {
    classes,
    policyEffectiveDate,
    selectedTypes,
    selectedTiers,
    selectionDisplayMode,
    productCode,
    isDependent
  } = props;

  return (
    <div className={classes.root}>
      <Plans
        policyEffectiveDate={policyEffectiveDate}
        types={selectedTypes}
        tiers={selectedTiers}
        selectionDisplayMode={selectionDisplayMode}
        displayAll={false}
        isDependent={isDependent}
        productCode={productCode}
      />
    </div>
  );
}

MyDependantCoverage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MyDependantCoverage);
