// import GCCA from "./GCCA";
// import GADD from "./GADD";
// import GEMM from "./GEMM";
import GPRU from "./GPRU";
// import GOSP from "./GOSP";
// import GDEN from "./GDEN";

// Plans ----------------------
const plans = [
  // GCCA,
  // GEMM,
  GPRU
  // GOSP,
  // GADD,
  // GDEN
];

export default plans;
