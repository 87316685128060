import React from "react";
import Button from "@mui/material/Button";
import { compose, withStateHandlers } from "recompose";
import assets from "../assets";

let images = assets.images;

const GoogleMapComp = () => {};

export default GoogleMapComp;
