import { APIResource, APIResponse, APIError } from "../utils/APIResource";

export default class PceService {
  static callApiFunc = path => () =>
    APIResource.get(path).then(
      response => {
        return Promise.resolve(new APIResponse(response));
      },
      error => {
        return Promise.reject(new APIError(error));
      }
    );

  static fetchProviderClinicsOptions = PceService.callApiFunc(
    "/v1/pce/options/providerClinics"
  );
  static fetchBankOptions = PceService.callApiFunc("/v1/banks");
  static fetchCurrentUserBankAccount = PceService.callApiFunc(
    "/v1/bankAccounts"
  );
}
