import Enums from "../../../../utils/Enums";

export default {
  classpass: {
    logo: [require("../../../img/vas/ClassPass_Logo-Type_Blue.png")],
    key: "classpass",
    name: "ClassPass",
    background: require("../../../img/vas/ClassPass_image.png"),
    header: "ClassPass",
    url: "https://classpass.com/corporate/singapore/prudentialsg",
    content: {
      header: "About ClassPass:",
      description:
        "ClassPass offers more variety than anyone else with access to the best workouts in one membership. Members can easily book classes, watch fitness videos and workout with friends & colleagues.\n" +
        "ClassPass currently has a network of over 22,000+ studio and gym partners that range from all different types of activities including kickboxing, yoga, cycling, gym time, HIIT and more."
    },
    promo: [
      {
        title: "classpass #exclusive1",
        details:
          "New to ClassPass?  \n" +
          "Receive a free 2 weeks trial with 30 credits.  \n \n" +
          "*Not applicable to pre-existing ClassPass customers, see ClassPass exclusive",
        code: Enums.VAS_PROMOCODE.CLASSPASS.CODE1
      },
      {
        title: "classpass #exclusive2",
        details:
          "Existing ClassPass member?  \n" +
          "Receive 10% off each month (for the life of your membership).",
        code: Enums.VAS_PROMOCODE.CLASSPASS.CODE2
      }
    ]
  },

  craft_and_culture: {
    logo: [require("../../../img/vas/Craft___Culture_Logo.jpg")],
    key: "craftandculture",
    name: "Craft & Culture",
    background: require("../../../img/vas/Craft___Culture_Image.jpg"),
    header: "Craft & Culture",
    url: "",
    content: {
      header: "About Craft & Culture:",
      description:
        "A Singaporean-Owned Brewery, specializing in fermented products like Kombucha, Milk Kefir, Water Kefir as well as Natural Probiotic skin care for glowing skin."
    },
    promo: [
      {
        title: "Craft & Culture #exclusive1",
        details:
          "Single bottles, the price is $6 (Regular price $7.50). \n\n *Free delivery  above $120 per location. ",
        code: Enums.VAS_PROMOCODE.CRAFTNCULTURE.CODE1
      },
      {
        title: "Craft & Culture #exclusive2",
        details:
          "Gift box of 15 at $90 (Regular px $97.50). \n\n *Free delivery  above $120 per location. ",
        code: Enums.VAS_PROMOCODE.CRAFTNCULTURE.CODE2
      }
    ]
  },

  eyesight: {
    logo: [require("../../../img/vas/eyesight_logo.png")],
    key: "eyesight",
    name: "eyesight.sg",
    background: require("../../../img/vas/eyesightSG_crop.png"),
    header: "eyesight.sg",
    url: "",
    content: {
      header: "About eyesight.sg:",
      description:
        "At eyesight.sg, we are dedicated to improving lives with primary eye care, knowing that many eye conditions have no signs/symptoms but can be detected early and eradicated through eye health tests.\n" +
        "We offer comprehensive eye examinations and advocate yearly primary eye tests over the mere prescription of optical appliances. We offer genuine advice on the right eye care and solution to optimise your vision, based on your lifestyle and needs."
    },
    promo: [
      {
        title: "eyesight #exclusive1",
        details: "Corporate price list up to 30-40% off contact lenses",
        code: Enums.VAS_PROMOCODE.EYESIGHT.CODE1
      },
      {
        title: "eyesight #exclusive2",
        details: "Made to measure bespoke Eyewear",
        code: Enums.VAS_PROMOCODE.EYESIGHT.CODE2
      },
      {
        title: "eyesight #exclusive3",
        details: "Frame + Lens \n" + "S$58 / S$88/ S$168",
        code: Enums.VAS_PROMOCODE.EYESIGHT.CODE3
      }
    ]
  },

  iwellness: {
    logo: [require("../../../img/vas/iwelllogov1__1_.jpg")],
    key: "iwellness",
    name: "i-wellness",
    background: require("../../../img/vas/iwellness_image.jpeg"),
    header: "i-wellness",
    url: "",
    content: {
      header: "About i-wellness:",
      description:
        "Integrated Wellness Services Pte Ltd is delighted to be part of a specialized team promoting healthy and balanced life. We sincerely believe good health comes from the concept of pre-emptive detection and early intervention. We hope to be able to work with both organizations and individuals in sharing our vision and achieving a win-win scenario for all."
    },
    promo: [
      {
        title: "i-wellness #exclusive1",
        details: "Vibrant Package $45 (see pdf for package deal)",
        code: Enums.VAS_PROMOCODE.IWELLNESS.CODE1
      },
      {
        title: "i-wellness #exclusive2",
        details: "Radiant Package $85",
        code: Enums.VAS_PROMOCODE.IWELLNESS.CODE2
      },
      {
        title: "i-wellness #exclusive3",
        details: "Dynamic $145",
        code: Enums.VAS_PROMOCODE.IWELLNESS.CODE3
      }
    ]
  },

  uob_travel: {
    logo: [require("../../../img/vas/UOBTravel_Logo.png")],
    key: "uobtravel",
    name: "UOB Travel",
    background: require("../../../img/vas/UOBTravel_image.jpg"),
    header: "UOB Travel",
    url: "http://bit.ly/UOBPRUWORKS",
    content: {
      header: "About UOB Travel:",
      description:
        "UOB Travel is a wholly owned subsidiary of the United Overseas Bank Group, with 45 years of presence in the industry. We aim to serve as a value-added travel service platform to our Retail and Wholesale banking customers. Most recently in 2018, we were awarded Top Agent for key partners like Club Med, Insight Vacations and Trafalgar."
    },
    promo: [
      {
        title: "Hotel #exclusive1",
        details:
          "Hotels \n 6% cash rebate \n\n Over 200,000 hotels worldwide \n From international chains to local boutique properties",
        code: Enums.VAS_PROMOCODE.UOBTRAVEL.CODE1
      },
      {
        title: "Packages #exclusive2",
        details:
          "Packages \n Up to 11% cash rebate \n\n Club Med \n Trafalgar \n Insight Vacations \n Kiwiway Vacations\n All Japan Tours",
        code: Enums.VAS_PROMOCODE.UOBTRAVEL.CODE2
      },
      {
        title: "Cruises #exclusive3",
        details:
          "Cruises\nUp to 11% cash rebate Celebrity Cruises\n\nRoyal Caribbean\nDream Cruises\nPrincess Cruises\nUniworld River Cruises",
        code: Enums.VAS_PROMOCODE.UOBTRAVEL.CODE3
      }
    ]
  }
};
