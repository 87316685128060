let currency = [
  {
    label: "SGD",
    value: "SGD"
  }
];

export default currency;

//commenting the below foreign currencies as theres a possibility to use later stage

// {
//   label: "AUD",
//   value: "AUD"
// },
// {
//   label: "CNY",
//   value: "CNY"
// },
// {
//   label: "EUR",
//   value: "EUR"
// },
// {
//   label: "HKD",
//   value: "HKD"
// },
// {
//   label: "IDR",
//   value: "IDR"
// },
// {
//   label: "JPY",
//   value: "JPY"
// },
// {
//   label: "KHR",
//   value: "KHR"
// },
// {
//   label: "KRW",
//   value: "KRW"
// },
// {
//   label: "LAK",
//   value: "LAK"
// },
// {
//   label: "MMK",
//   value: "MMK"
// },
// {
//   label: "MYR",
//   value: "MYR"
// },
// {
//   label: "NZD",
//   value: "NZD"
// },
// {
//   label: "PHP",
//   value: "PHP"
// },
// {
//   label: "THB",
//   value: "THB"
// },
// {
//   label: "USD",
//   value: "USD"
// },
// {
//   label: "VND",
//   value: "VND"
// }
