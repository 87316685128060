import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import moment from "moment";
import Enums from "../../../utils/Enums";
import MyCoverage from "./MyCoverage";
import MyDependantCoverage from "./MyDependantCoverage";

const styles = theme => ({
  root: {
    height: "100%",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
      marginRight: 10,
      marginTop: 80,
      paddingBottom: 10
    }
  },
  content: {
    padding: theme.spacing.unit * 2.5,
    paddingTop: 10
  },
  header: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    },
    justifyContent: "space-between"
  },
  policyNumber: {
    fontStyle: "oblique"
  },
  heading: {
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing.unit}px 0`
    }
  }
});

class MyBenefits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      productDetails: null,
      plans: {
        selectedTypes: [Enums.PLANS_TYPE.BENEFITS]
      }
    };

    this.Statuses = [
      {
        status: "Draft",
        mapTo: ["ESTIMATE", "EMPLOYEE"]
      },
      {
        status: "Proposed",
        mapTo: ["PROPOSAL", "SUBMISSION"]
      },
      {
        status: "Proposed",
        mapTo: ["SUBMITTED"]
      },
      {
        status: "In Force",
        mapTo: ["INFORCE", "PROCESSED"]
      }
    ];

    this.underwritingStatuses = {
      APPROVED: "Approved",
      PENDING: "Pending",
      REJECTED: "Rejected",
      LIMITED: "Limited"
    };
  }

  mappingStatus = stages => {
    let getStatus = this.Statuses.filter(status => {
      return status.mapTo.indexOf(stages) > -1;
    });
    return getStatus[0].status;
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  getPolicyEffectiveDate = gaInfo => {
    let policyEffectiveDate = "";
    gaInfo.forEach(gaInfo => {
      if (moment().isBetween(gaInfo.cc_date, gaInfo.cr_date)) {
        policyEffectiveDate = gaInfo.cc_date;
      }
    });
    return policyEffectiveDate;
  };

  getSelectedTiers = () => {
    let selectedTiers = {};
    const { policy, category } = this.props.user;
    const selectedCategory = policy.data.categories.find(
      c => c.name === category
    );

    if (selectedCategory) {
      const { plansConfiguration } = selectedCategory;

      plansConfiguration.forEach(p => {
        if (p.code === "GFWM") {
          const extendedBenefit = policy.data.extendedBenefit.find(
            c => c.categoryName === selectedCategory.name
          );
          if (extendedBenefit) {
            if (!selectedTiers[p.code] && extendedBenefit.tier)
              selectedTiers[p.code] = [];
            if (extendedBenefit.tier)
              selectedTiers[p.code].push(extendedBenefit.tier);
          }
        } else {
          if (!selectedTiers[p.code] && p.tier) selectedTiers[p.code] = [];
          if (p.tier) selectedTiers[p.code].push(p.tier);
        }
      });
    }

    return selectedTiers;
  };

  getExtendedBenefit = () => {
    const { policy, category } = this.props.user;
    const selectedCategory = policy.data.categories.find(
      c => c.name === category
    );
    const extendedBenefit = policy.data.extendedBenefit.find(
      c => c.categoryName === selectedCategory.name
    );

    return extendedBenefit;
  };

  getSelectedDependentTiers = () => {
    let selectedTiers = {};
    const { user } = this.props;
    const { policy, category } = this.props.user;
    let selectedCategory = policy.data.categories.find(
      c => c.name === category
    );

    // If there is dependant, use category with tiers retrieved from backend
    if (policy && policy.product_code === "GCP") {
      if (user.dependencies && user.dependencies.length > 0) {
        selectedCategory = user.dependencies[0].dependant_category;
      } else {
        selectedCategory = undefined;
      }
    }

    if (selectedCategory) {
      const { plansConfiguration } = selectedCategory;

      plansConfiguration.forEach(p => {
        if (p.enableDependent) {
          if (!selectedTiers[p.code]) selectedTiers[p.code] = [];
          selectedTiers[p.code].push(p.tier);
        }
      });
    }

    return selectedTiers;
  };

  planSumAssuredDetails(planDetails, categories) {
    if (planDetails !== null && categories !== null) {
      let plansConfiguration = categories[0].plansConfiguration;
      return planDetails.filter(function(plan) {
        return plansConfiguration.some(function(planConfig) {
          if (plan.code === planConfig.code) {
            return Object.assign(plan, planConfig);
          }
        });
      });
    }
  }
  render() {
    const { classes, user } = this.props;
    const { policy } = user;
    const { gaInfo } = user;
    const { plans } = this.state;
    const policyEffectiveDate =
      gaInfo == undefined || null ? "" : this.getPolicyEffectiveDate(gaInfo);
    const selectedTiers = this.getSelectedTiers();
    const extendedBenefit = this.getExtendedBenefit();
    const selectedDependentTiers = this.getSelectedDependentTiers();
    const planSumAssured = this.planSumAssuredDetails(
      this.props.user.planDetails,
      user.policy.data.categories
    );

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.header}>
            <Typography
              className={classes.heading}
              variant="h5"
              color="inherit"
            >
              My Coverage
            </Typography>
            {policy && (
              <Typography
                className={classes.heading}
                variant="subheading"
                color="inherit"
              >
                Policy Number :
                <span className={classes.policyNumber}>{policy.policy_no}</span>
              </Typography>
            )}
          </div>
          {policy && (
            <div>
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                indicatorColor="primary"
                classes={{
                  indicator: classes.indicator
                }}
              >
                <Tab label="My Coverage" />
                <Tab label="Dependant's Coverage" />
              </Tabs>
              {this.state.value === 0 && (
                <MyCoverage
                  policyEffectiveDate={policyEffectiveDate}
                  selectedTypes={plans.selectedTypes}
                  selectedTiers={selectedTiers}
                  extendedBenefit={extendedBenefit}
                  selectionDisplayMode={"SHOW"}
                  //policy_status={this.mappingStatus(policy.policy_status)}
                  productCode={policy.product_code}
                  planCategory={policy.data.categories}
                  planSumAssured={planSumAssured}
                  underwriting_status={
                    this.underwritingStatuses[user.underwriting_status]
                  }
                />
              )}
              {this.state.value === 1 && (
                <MyDependantCoverage
                  policyEffectiveDate={policyEffectiveDate}
                  selectedTypes={plans.selectedTypes}
                  selectedTiers={selectedDependentTiers}
                  selectionDisplayMode={"SHOW"}
                  productCode={policy.product_code}
                  isDependent={true}
                  underwriting_status={
                    this.underwritingStatuses[user.underwriting_status]
                  }
                  //policy_status={this.mappingStatus(policy.policy_status)}
                />
              )}
            </div>
          )}
        </div>

        {/* Not enroll in any category */}
        {!policy && (
          <div>
            <Typography
              className={classes.heading}
              variant="subheading"
              color="inherit"
            >
              No Coverage
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(MyBenefits));
