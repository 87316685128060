import React, { Component } from "react";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";

class TncDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agreeTnc: false,
      agreeMarketing: false,
      disableTnC: false
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes, open, handleAgree, submitting, error } = this.props;
    const { agreeTnc, agreeMarketing, disableTnC } = this.state;

    const tnc1Label = (
      <span>
        I agree to Prudential's &nbsp;
        <a href="/termsAndConditions" target="_blank">
          Terms and Conditions
        </a>
      </span>
    );

    const tnc2Label = (
      <span>
        I confirm that I have read, understood and given my consent for
        Prudential and its authorised representatives to collect, use, disclose
        and/or process my personal data for the purpose of contacting me about
        products and services distributed, marketed and/or introduced by
        Prudential and its authorised representatives through marketing
        activities, via all channels, including but not limited to SMS, social
        media, in-app push notification, phone call etc, and using my contact
        details which Prudential and its authorised representatives has in its
        records from time to time, in accordance to the Prudential’s Privacy
        Notice available at &nbsp;
        <a href="https://www.prudential.com.sg/Privacy-Notice" target="_blank">
          https://www.prudential.com.sg/Privacy-Notice
        </a>
        <p>
          I may contact the PRUWorks Hotline at +65 6233 3299 on how I may
          access and correct my personal data or withdraw consent to the
          collection, use or disclosure of my personal data.
        </p>
        <p>
          Any withdrawal of consent from PRUWorks will not affect the marketing
          consent provided in an individual life policy, if applicable.
        </p>
      </span>
    );

    return (
      <Dialog
        open={open}
        //onClose={closeHandler}
        maxWidth="md"
        aria-labelledby="tnc-dialog-title"
        aria-describedby="tnc-dialog-description"
      >
        <DialogTitle id="tnc-dialog-title">
          Terms of Use and Privacy Notice
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                className={classes.tncCheckBox}
                control={
                  <Checkbox
                    className={classes.tncCheckBox}
                    color="primary"
                    checked={agreeTnc}
                    name="agreeTnc"
                    onChange={this.handleChange("agreeTnc")}
                  />
                }
                label={tnc1Label}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                className={classes.tncCheckBox}
                control={
                  <Checkbox
                    className={classes.tncCheckBox}
                    color="primary"
                    checked={agreeMarketing}
                    name="agreeMarketing"
                    onChange={this.handleChange("agreeMarketing")}
                  />
                }
                label={tnc2Label}
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.setState({ disableTnC: true });
              handleAgree(this.state.agreeMarketing, this.state.agreeTnc);
            }}
            disabled={!agreeTnc || submitting || error || disableTnC}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = theme => ({
  tncCheckBox: {
    alignItems: "flex-start"
  }
});

export default withStyles(styles, { withTheme: true })(TncDialog);
