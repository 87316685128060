import Icon from "@mui/material/Icon";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import classNames from "classnames";

class SimpleMessageDialog extends Component {
  dialogType = classes => {
    let type = {
      error: {
        icon: (
          <Icon
            classes={{
              root: classNames(classes.iconError, classes.icon)
            }}
          >
            cancel
          </Icon>
        )
      },
      success: {
        icon: (
          <Icon
            classes={{
              root: classNames(classes.iconiconSuccessError, classes.icon)
            }}
          >
            check_circle
          </Icon>
        )
      }
    };
    return { TYPE: type };
  };

  dialogTitleStyle = type => {
    const { classes } = this.props;
    switch (type) {
      case "error":
        return classes.dialogTitleError;
      case "success":
        return classes.dialogTitleSuccess;
      default:
        return {};
    }
  };

  render() {
    const {
      name,
      title,
      description,
      alignCenter = false,
      isHtml = false,
      open,
      closeHandler,
      closeButtonText,
      classes
    } = this.props;
    let type = this.props.type || "success";
    const { TYPE } = this.dialogType(classes);

    return (
      <Dialog
        open={open}
        onClose={closeHandler}
        maxWidth="xs"
        aria-labelledby={`${name}-title`}
        aria-describedby={`${name}-description`}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent
          className={classNames(
            classes.dialogContent,
            classes.dialogContentCenter && alignCenter
          )}
        >
          {type !== "none" && (
            <DialogTitle
              classes={{
                root: classNames(
                  classes.dialogTitle,
                  this.dialogTitleStyle(type)
                )
              }}
              disableTypography={true}
              id={`${name}-title`}
            >
              {TYPE[type].icon}
              <div>{title}</div>
            </DialogTitle>
          )}
          <Typography
            variant="subheading"
            color="textSecondary"
            id={`${name}-description`}
          >
            {isHtml ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: description
                }}
              />
            ) : (
              description
            )}
          </Typography>
        </DialogContent>
        {closeButtonText && (
          <DialogActions
            classes={{
              root: classes.dialogActions
            }}
          >
            <Button
              onClick={closeHandler}
              variant="contained"
              color="primary"
              className={classes.dialogActionsButton}
            >
              {closeButtonText}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialogContent: {
    maxWidth: 320,
    minWidth: 300,
    "&:first-child": {
      padding: "10px 15px"
    }
  },
  dialogPaper: {
    margin: 0
  },
  dialogContentCenter: {
    textAlign: "center"
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 0,
    paddingTop: 15,
    paddingBottom: 15,
    fontSize: "1.1em",
    fontFamily: theme.typography.fontFamily
  },
  dialogTitleError: {
    color: theme.colors.error
  },
  dialogTitleSuccess: {
    color: theme.colors.success
  },
  dialogActions: {
    marginBottom: 20
  },
  dialogActionsButton: {
    minWidth: 130
  },
  icon: {
    paddingRight: 10
  },
  iconSuccess: {
    color: `${theme.colors.success}`,
    fontSize: "2.5rem"
  },
  iconError: {
    color: `${theme.colors.error}`,
    fontSize: "2.5rem"
  }
});

export default withStyles(styles, { withTheme: true })(SimpleMessageDialog);
