import moment from "moment";
import config from "../../../../config/config";

const ValidationName = Object.freeze({
  MAX_SUBMISSION_DAY: "maxSubmissionDay"
});

const ValidationMessage = Object.freeze({
  [ValidationName.MAX_SUBMISSION_DAY]:
    config.submissionMaxDaysErrorMessage ??
    "The value must not more than MAX_SUBMISSION_DAY"
});

/**
 * @type {Record<typeof ValidationName[keyof typeof ValidationName], (value: any) => boolean | Promise<boolean>} */
const ValidationRule = Object.freeze({
  [ValidationName.MAX_SUBMISSION_DAY]: value => {
    if (!config.submissionMaxDays) return true;

    const days = moment().diff(value, "days");
    const isMoreThanMaxDays = days > config.submissionMaxDays;
    return !isMoreThanMaxDays;
  }
});

export { ValidationName, ValidationRule, ValidationMessage };
