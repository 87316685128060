import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { userActions } from "../../../actions";
import { withStyles } from "@mui/styles";
import assets from "../../../assets";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { pink } from "@mui/material/colors";
import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import Enums from "../../../utils/Enums";
import Util from "../../../utils/Util";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import CallBackService from "../../../services/CallBackService";
import SimpleConfirmDialog from "../../../components/SimpleConfirmDialog";
import LoadingPopup from "../../../components/LoadingPopup";

import {
  getAvaliableProductBenefits,
  isGrabGroupEmployee
} from "../../../selectors/CommonSelectors";

import _ from "lodash";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.route = this.props.route;
    this.state = {
      listBenefits: getAvaliableProductBenefits(props.agentType),
      triggerLoadingPopup: false,
      openConfirmDialog: false
    };
  }

  showListBenefits(classes) {
    let images = assets.images;
    let icons = assets.icons;
    let listComponents = this.state.listBenefits.map((data, index) => {
      return data.key === "more_services" ? (
        <Grid key={index} item xs={12} md={4}>
          <Card className={classes.card_ms}>
            <CardContent className={classes.info_ms}>
              <Typography
                gutterBottom
                variant="subheading"
                className={classes.title_ms}
                component="h2"
              >
                {data.title}
              </Typography>
              <Typography variant="caption" className={classes.description_ms}>
                {data.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <Grid key={index} item xs={12} md={4}>
          <Link
            to={this.route.path + "/details/" + data.key}
            className={classes.menuItem}
          >
            <Card className={classes.card}>
              <CardMedia
                className={classes.media}
                image={images.benefit_consultation}
              >
                <img
                  alt=""
                  className={classes.imageSrc}
                  src={icons.consultation}
                />
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="subheading" component="h2">
                  {data.title}
                </Typography>
                <Typography variant="caption">{data.description}</Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      );
    });
    return listComponents;
  }

  closeCofirmDialog = () => {
    this.setState({ openConfirmDialog: false });
  };

  responseConfirmDialog = () => {
    this.closeCofirmDialog();
    this.callBanner(this.state.partnerName, this.state.bannerUrl);
  };

  callBannerRedirect = banner => {
    this.callBanner(banner.name, banner.link);
  };

  callBanner = (partnerName, bannerUrl) => {
    const data = {
      name: partnerName,
      email: "",
      phone: "",
      promo_code: { code: "DUMMY_EMPLOYEE" },
      redirect: true
    };
    CallBackService.sendEmailRequestCallBack(data, partnerName).then(
      response => {
        if (response.success) {
          this.setState({ triggerLoadingPopup: false });
          window.open(bannerUrl, "_blank", "noopener noreferrer");
        }
      },
      error => {
        this.setState({ triggerLoadingPopup: false });
      }
    );
  };

  render() {
    const { classes, history, user } = this.props;
    const { listBenefits } = this.state;
    const product_code = (user.policy && user.policy.product_code) || undefined;
    let hasBenefits = !Util.isEmpty(listBenefits);

    return (
      <div className={classes.container}>
        <Grid container spacing={"24px"} className={classes.cardContainer}>
          <Grid item xs={12}>
            <Carousel
              className={classes.customDot}
              showArrows={true}
              autoPlay
              showThumbs={false}
              showIndicators={
                user.banner && user.banner.length > 1 ? true : false
              }
            >
              {user.banner &&
                user.banner.map((banner, bannerKey) => {
                  return (
                    <div
                      key={bannerKey}
                      onClick={() => this.callBannerRedirect(banner)}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={`data:image/jpeg;base64,${banner.data}`}
                        className={classes.webBanner}
                        style={{ height: "310px" }}
                      />
                      <img
                        src={`data:image/jpeg;base64,${banner.data_mobile}`}
                        className={classes.mobileBanner}
                      />
                    </div>
                  );
                })}
            </Carousel>
          </Grid>
        </Grid>
        <Grid container spacing={"24px"} className={classes.cardContainer}>
          {user.auth.scope.includes(Enums.SCOPE_TYPE.INS_COV_EMP_R) && (
            <Grid item xs={12} md={4}>
              <Card
                className={classes.cardIconBox}
                onClick={() => {
                  history.push("/auth/myBenefits");
                }}
              >
                <Grid container spacing={"24px"}>
                  <Grid item xs={3}>
                    <img
                      className={classes.socialMedia}
                      src={assets.dashBoardImages.insuranceCoverage}
                      alt="Insurance overview logo"
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.cardIconBoxContent}>
                    <Typography className={classes.contentBoxText}>
                      My Coverage
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <IconButton className={classes.button} color={"primary"}>
                      <Icon>keyboard_arrow_right</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
          {/*second row*/}
          {user.auth.scope.includes(Enums.SCOPE_TYPE.DOCTOR_R) && (
            <Grid item xs={12} md={4}>
              <Card
                className={classes.cardIconBox}
                onClick={() => {
                  history.push("/auth/clinics/search");
                }}
              >
                <Grid container spacing={"24px"}>
                  <Grid item xs={3}>
                    <img
                      className={classes.socialMedia}
                      src={assets.dashBoardImages.doctor}
                      alt="Doctor logo"
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.cardIconBoxContent}>
                    <Typography className={classes.contentBoxText}>
                      Find a Doctor
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <IconButton className={classes.button} color={"primary"}>
                      <Icon>keyboard_arrow_right</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
          {user.auth.scope.includes(Enums.SCOPE_TYPE.DOCUMENT_EMP_R) && (
            <Grid item xs={12} md={4}>
              <Card
                className={classes.cardIconBox}
                onClick={() => {
                  history.push("/auth/myDocuments");
                }}
              >
                <Grid container spacing={"24px"}>
                  <Grid item xs={3}>
                    <img
                      className={classes.socialMedia}
                      src={assets.dashBoardImages.documents}
                      alt="Document logo"
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.cardIconBoxContent}>
                    <Typography className={classes.contentBoxText}>
                      Documents
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <IconButton className={classes.button} color={"primary"}>
                      <Icon>keyboard_arrow_right</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
          {user.auth.scope.includes(Enums.SCOPE_TYPE.CLAIM_R) && (
            <Grid item xs={12} md={4}>
              <Card
                className={classes.cardIconBox}
                onClick={() => {
                  history.push("/auth/myClaims");
                }}
              >
                <Grid container spacing={"24px"}>
                  <Grid item xs={3}>
                    <img
                      className={classes.socialMedia}
                      src={assets.dashBoardImages.myClaim}
                      alt="myClaim logo"
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.cardIconBoxContent}>
                    <Typography className={classes.contentBoxText}>
                      My Claims
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <IconButton className={classes.button} color={"primary"}>
                      <Icon>keyboard_arrow_right</Icon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )}
          {user.auth.scope.includes(Enums.SCOPE_TYPE.EMP_MYBENEFIT_R) && //!Util.isEmpty(listBenefits) &&
            !isGrabGroupEmployee(this.props.user) && (
              <Grid item xs={12} md={4}>
                <Card
                  className={classes.cardIconBox}
                  onClick={() => {
                    history.push("/auth/employeeBenefit");
                  }}
                >
                  <Grid container spacing={"24px"}>
                    <Grid item xs={3}>
                      <img
                        className={classes.socialMedia}
                        src={assets.dashBoardImages.myBenefits}
                        alt="My Benefits logo"
                      />
                    </Grid>
                    <Grid item xs={6} className={classes.cardIconBoxContent}>
                      <Typography className={classes.contentBoxText}>
                        My Benefits
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton className={classes.button} color={"primary"}>
                        <Icon>keyboard_arrow_right</Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
        </Grid>
        <SimpleConfirmDialog
          name="leave-confirm-dialog"
          title="You’re leaving the PruWorks Portal."
          description="We will redirect you to our partner’s website. Click ok to continue."
          closeButtonText="Cancel"
          okButtonText="OK"
          closeHandler={this.closeCofirmDialog}
          okHandler={this.responseConfirmDialog.bind(this)}
          open={this.state.openConfirmDialog}
        />
        <LoadingPopup
          name={this.props.type}
          open={this.state.triggerLoadingPopup}
        />
        {/* Benefits */}
        {/* {hasBenefits &&
          !Util.isCompDisabledForCode(
            product_code,
            Enums.COMPONENT.DASHBOARD_MY_BENEFIT_GRID
          ) && (
            <Grid container spacing={24} className={classes.cardContainer}>
              <Grid item xs={12}>
                <Typography className={classes.titleText} variant="title">
                  My Benefits
                </Typography>
              </Grid>
              {this.showListBenefits(classes)}
            </Grid>
          )} */}

        <div
          style={{
            height: 50
          }}
        />
      </div>
    );
  }
}
const styles = theme => ({
  container: {
    height: "100%",
    paddingBottom: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
      marginRight: 10,
      // marginTop: 80,
      paddingBottom: 10
    }
  },

  cardContainer: {
    padding: 20
  },

  titleText: {
    margin: theme.spacing.unit,
    fontWeight: 400
  },
  titleBoxText: {
    margin: theme.spacing.unit,
    fontSize: 16,
    color: "gray"
  },
  contentBoxText: {
    margin: theme.spacing.unit,
    fontSize: 16
  },
  dependentText: {
    display: "flex",
    fontSize: 14,
    marginLeft: 30,
    marginTop: 20
  },
  titleLinkText: {
    fontWeight: 400,
    fontSize: 14,
    marginLeft: 15
  },
  paperTileStyle: {
    position: "relative",
    float: "left",
    width: "100%",
    minHeight: "200px",
    overflow: "hidden",
    height: "100% !important"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    height: "100%"
  },
  button: {
    margin: theme.spacing.unit,
    top: "20%"
  },
  contentCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  myDocumentLine: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    width: 40,
    height: 40,
    color: "#000"
  },
  pinkAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: pink[500]
  },
  greenAvatar: {
    color: "#fff",
    backgroundColor: green[500]
  },
  divider80: {
    marginLeft: 30,
    width: "80%",
    align: "center"
  },

  normalCard: {
    height: 260
  },

  alignCardBottom: {
    display: "flex",
    flexDirection: "column"
  },

  flexibleCard: {
    height: 260,
    [theme.breakpoints.down("xs")]: {
      minHeight: 350
    }
  },

  cardIconBox: {
    height: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },

  cardIconBoxContent: {
    display: "flex",
    alignItems: "center"
  },

  roundButton: {
    borderRadius: 20
  },

  expandAllCard: {
    flexGrow: 1
  },
  table: {
    minWidth: 200
  },
  media: {
    height: 200,
    position: "relative",
    cursor: "pointer"
  },
  imageSrc: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: "auto"
  },
  socialMedia: {
    width: 60,
    height: 60,
    marginLeft: 20,
    marginTop: 5
  },
  menuItem: {
    textDecoration: "none"
  },
  info_ms: {
    padding: "90px 50px",
    [theme.breakpoints.down("sm")]: {
      padding: "65px 50px"
    }
  },
  card_ms: {
    minHeight: 285,
    [theme.breakpoints.down("sm")]: {
      minHeight: 200
    }
  },
  description_ms: {
    fontSize: "14px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px"
    }
  },
  title_ms: {
    fontSize: "20px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  webBanner: {
    [theme.breakpoints.down("xs")]: {
      display: "none !important"
    }
  },
  mobileBanner: {
    [theme.breakpoints.up("sm")]: {
      display: "none !important"
    }
  },
  customDot: {
    "& .control-dots": {
      margin: "10px 0 0 0"
    },
    "& .dot": {
      height: "15px !important",
      width: "15px !important",
      opacity: "1 !important"
    },
    "& .selected": {
      backgroundColor: "red !important"
    }
  }
});

function mapStateToProps(state, prop) {
  return { user: state.user, agentType: state.user.auth.pcode };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(userActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Dashboard));
