const Colors = {
  primary: {
    red: "#ED1C24",
    grey: "#808285"
  },
  secondary: {
    red100: "#D00015",
    red90: "#D7545C",
    blue100: "#33839E",
    blue90: "#3BB1D5",
    blue80: "#93B4C7",
    blue70: "#03288E",
    blue60: "#2600D9",
    blue50: "#0073B3",
    blue40: "#006DD7",
    blue30: "#3BB1D5",
    blue10: "#72C8FF",
    pink100: "#B3356D",
    pink80: "##C4184A",
    pink50: "#F37492",
    white: "#FFFFFF",
    green100: "#18C494",
    green90: "##54D7CF",
    green80: "#74F3D5",
    green70: "##83C1C0",
    violet: "#4F35BB",
    purple: "#7F3190"
  },
  blackScale: {
    black100: "#333333",
    black90: "#6B6A6D",
    black80: "#9B999A",
    black70: "#C5C4C4",
    black60: "#E4E3E4",
    black50: "#F6F6F6"
  },
  tip: "#1164CE",
  success: "#18C494",
  error: "#D7545C"
};

export default Colors;
