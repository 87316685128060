const productKeys = {
  CRAFT_CULTURE: "craft_and_culture",
  CLASSPASS: "classpass",
  EYESIGHT: "eyesight",
  IWELLNESS: "iwellness",
  UOB_TRAVEL: "uob_travel",
  MORE_SERVICES: "more_services"
};

const {
  EYESIGHT,
  CRAFT_CULTURE,
  CLASSPASS,
  IWELLNESS,
  UOB_TRAVEL,
  MORE_SERVICES
} = productKeys;

const availableProducts = {
  // Agent Distribution Channel

  PRU_AGENCY: [],
  AD: [],

  // Partner Distribution Channel
  PD: [],

  //UOB Assisted (include UOB team 1)
  UOB_ASSISTED: [],
  UOB_TEAM_1: [],

  //UOB Direct (include UOB team 2)
  UOB_DIRECTED: [],
  UOB_TEAM_2: [],
  SCB_ASSISTED: [],

  //Freya(PFA)
  PFA: []
};

export default { productKeys, availableProducts };
