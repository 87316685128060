import React from "react";
import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const ClaimTermAndConditionsPage = ({ classes }) => {
  return (
    <div>
      <Paper className={classes.root} elevation={4}>
        <Typography variant="headline" component="h3">
          Declaration, Authorisation, and Consent
        </Typography>

        <Typography component="p" className={classes.pSpacing}>
          1. I hereby declare that the information that is disclosed on this
          form is to the best of my knowledge and belief, true, complete and
          accurate, and that no material information has been withheld or is any
          relevant circumstances omitted.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          2. I further acknowledge and accept that Prudential Assurance Company
          Singapore (Pte) Limited ("PACS") shall be at liberty to deny liability
          or recover amounts paid, whether wholly or partially, if any of the
          information disclosed on this form is incomplete, untrue or incorrect
          in any respect or if the Policy does not provide cover on which such
          claim is made.
        </Typography>

        <Typography component="p" className={classes.pSpacing}>
          3. I hereby warrant and represent that I have been authorised by the
          policyholder and the applicable insured(s) to submit information
          pertaining to such insured’s claims.
        </Typography>

        <Typography component="p" className={classes.pSpacing}>
          4. I acknowledge and accept that the furnishing of this form, or any
          other forms supplemental thereto, by PACS, is neither an admission
          that there was any insurance in force on the life in question, nor an
          admission of liability nor a waiver of any of its rights and defenses.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          5. I confirm that I have paid in full all the
          bill(s)/invoice(s)/receipt(s) that I have submitted to PACS for
          reimbursement and have not claimed and do not intend to claim from
          other company(ies)/person(s).
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          6. I agree to produce all original bill(s)/invoice(s)/receipt(s) that
          were submitted for reimbursement to PACS for verification as it deems
          necessary.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          7. I acknowledge and accept that PACS expressly reserves its rights to
          require or obtain further information and documentation as it deems
          necessary.
        </Typography>
        <Typography component="p" className={classes.pSpacing}>
          8. For the purposes of (i) assessing, processing and/or investigating
          my claim(s) arising under the Policy or any of my other polic(ies) of
          insurance with PACS and such other purposes ancillary or related to
          the assessing, processing and/or investigating of such claim(s); (ii)
          administering the Policy, (iii) customer servicing, statistical
          analysis, conducting customer due diligence, reporting to regulatory
          or supervisory authorities, auditing and recovery of any debts owing
          to PACS whether in relation to the Policy or any of my other
          polic(ies) of insurance with PACS, (iv) storage and retention, (v)
          meeting requirements of prevailing internal policies of PACS, and/or
          (vi) as set out in PACS Privacy Notice (“Purpose”), I authorise, agree
          and consent to: a) Any person(s) or organisation(s) that has relevant
          information concerning the policyowner and the insured person(s)
          (including any medical practitioner, medical/healthcare provider,
          financial service providers, insurance offices, government
          authorities/regulators, statutory boards, employer, or investigative
          agencies) (“Person(s)/Organisation(s)”), to disclose, release,
          transfer and exchange any information with PACS and its related
          corporations, respective representatives, agents, third party service
          providers, contractors and/or appointed distribution/business partners
          (collectively referred to as “Prudential”), including without
          limitation, personal data, medical information, medical history,
          employment and financial information, including the taking of copies
          of such records; and b) Prudential collecting, using, disclosing,
          releasing, transferring and exchanging personal data about me, the
          policyowner and the insured person(s), with the
          Person(s)/Organisation(s), PACS’s related group of companies, third
          party service providers, insurers, reinsurers, suppliers,
          intermediaries, lawyers/law firms, other financial institutions, law
          enforcement authorities, dispute resolution centres, debt collection
          agencies, loss adjustors or other third parties for the Purpose.
        </Typography>
      </Paper>
    </div>
  );
};

const styles = theme => ({
  pSpacing: {
    margin: `${theme.spacing.unit}px 0`
  },
  root: {
    padding: 16,
    margin: theme.spacing.unit * 3
  },
  spacing: {
    padding: "5px 0",
    margin: `${theme.spacing.unit}px 0`
  }
});

export default withStyles(styles, { withTheme: true })(
  ClaimTermAndConditionsPage
);
