import doc from "./data/documents";
import VASdoc from "./data/documents/VASdoc";
import productInfo from "./data/productInfo";

const images = {
  pru_logo: require("./img/prulogo.png"),
  pru_menu_logo: require("./img/pru_menu_logo.jpg"),
  pru_menu_work_logo: require("./img/pruworks_main_logo.png"),
  avatar: require("./img/avatar.jpg"),
  stepper: require("./img/stepper.gif"),
  blueMarker: require("./img/bluemapicon.png"),
  pru_logo_face: require("./img/pru_logo_face.jpg"),
  benefit_consultation: require("./img/Benefit-Consultation.png"),
  benefit_fitness: require("./img/Benefit-fitness.png"),
  benefit_travel: require("./img/Benefit-Travel.png"),
  background: require("./img/background.png"),
  docdoc_logo: require("./img/docdoc-logo.png")
};

const icons = {
  consultation: require("./img/icon/Consultation-icon_03.png"),
  fitness: require("./img/icon/Fitness-icon_03.png"),
  travel: require("./img/icon/Travel-icon_03.png"),
  claimDoctor: require("./img/icon/claim_logo.png"),
  mailboxClaim: require("./img/icon/email-send-icon.png")
};

const menuIcons = {
  dashboard: require("./img/menu/dashboard.png"),
  insuranceCoverage: require("./img/menu/insurance_coverage.png"),
  employeeBenefits: require("./img/menu/benefits.png"),
  documents: require("./img/menu/documents.png"),
  doctor: require("./img/menu/doctor.png"),
  claim: require("./img/menu/claims.png")
};

const dashBoardImages = {
  insuranceCoverage: require("./img/dashboard/insurance_coverage.jpg"),
  doctor: require("./img/dashboard/doctor.jpg"),
  documents: require("./img/dashboard/document.jpg"),
  background: require("./img/dashboard/dashboard-background.png"),
  myBenefits: require("./img/dashboard-benefits-img.png"),
  myClaim: require("./img/dashboard/my_claim.png")
};

const benefits = {
  details: require("./data/hr/benefit/benefitDetails.js"),
  list: require("./data/hr/benefit/yourBenefits.js")
};

const benefitImages = {
  benefitsImg: require("./img/oval-benefits-img.png"),
  resultsImg: require("./img/oval-results-img.png"),
  serviceImg: require("./img/oval-service-img.png"),
  left_red_chevron: require("./img/red-chevron-left.png")
};

const alert = {
  tip: require("./img/alert/tip.png")
};

export default {
  benefits,
  benefitImages,
  images,
  icons,
  menuIcons,
  dashBoardImages,
  doc,
  VASdoc,
  productInfo,
  alert
};
