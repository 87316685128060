import * as types from './types'
import clinicServices from  '../services/ClinicServices'

export const clinicActions = {
    fetchClinics
};

function fetchClinics() {
    return (dispatch, getState) => {
        clinicServices.fetchClinics().then(response => {
            if (response.success) {
                dispatch({
                    type: types.GET_MAKERS_SUCCESS,
                    makers: response.data
                });
                return Promise.resolve(response);
            }
        },
        error => {
            dispatch({
                type: types.GET_MAKERS_ERROR,
                makers: []
            });
            return Promise.reject(error);
        });
    }
}