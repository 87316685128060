import { createTheme } from "@mui/material/styles";
import Colors from "./color";
import ieCompatible from "./ie";
import extended from "./extended";
const defaultTheme = createTheme();
const fontWeightMedium = 400;

const defaultOverrides = {
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontSize: "14px",
        color: "#000"
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "initial"
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "initial",
        borderRadius: 20
      },
      // deprecated. not exist
      raised: {
        boxShadow: "none"
      },
      // deprecated. not exist
      raisedPrimary: {
        backgroundColor: "#ED1C24"
      },
      contained: {
        boxShadow: "none"
      },
      containedPrimary: {
        backgroundColor: "#ED1C24"
      }
    }
  },
  MuiStepLabel: {
    styleOverrides: {
      labelContainer: {
        [defaultTheme.breakpoints.down("md")]: {
          textAlign: "left"
        }
      }
    }
  },
  MuiStepper: {
    styleOverrides: {
      root: {
        [defaultTheme.breakpoints.down("md")]: {
          paddingBottom: 18
        }
      }
    }
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        width: "100%",
        marginTop: 25,
        marginBottom: 25,
        [defaultTheme.breakpoints.down("md")]: {
          marginTop: 15,
          marginBottom: 15
        }
      }
    }
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        "&:hover": {
          color: "gray"
        },
        "&:focus": {
          color: "white"
        }
      },
      active: {
        color: "white",
        "& $icon": {
          opacity: 1
        }
      }
    }
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        WebkitOverflowScrolling: "touch"
      },
      paper: {
        WebkitOverflowScrolling: "touch"
      }
    }
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backgroundColor: "rgba(0,0,0,0.8)"
      }
    }
  },

  MuiTextField: {
    defaultProps: {
      size: "small"
    }
  },
  MuiSelect: {
    defaultProps: {
      size: "small"
    }
  },
  MuiFormControl: {
    defaultProps: {
      size: "small"
    }
  },
  MuiInputLabel: {
    defaultProps: {
      sx: {
        fontSize: "13px",
        top: 2
      }
    },
    styleOverrides: {
      shrink: ({ ownerState, theme }) => ({
        ...(ownerState.shrink && {
          fontSize: "1rem !important",
          top: "-1 !important"
        })
      })
    }
  }
};

const muiTheme = createTheme({
  background: {
    color: "#F8F8F8"
  },
  typography: {
    // Use the system font.
    fontFamily: "OpenSans",
    // fontFamily,
    fontWeightMedium,
    body1: {
      fontWeight: fontWeightMedium
    }
  },
  // overrides: Object.assign(defaultOverrides, ieCompatible),
  components: Object.assign(defaultOverrides, ieCompatible),
  palette: {
    default: {
      main: Colors.secondary.white,
      fontColor: Colors.blackScale.black100
    },
    primary: {
      main: "#CC0000",
      fontColor: Colors.secondary.white,
      darken: "#AE0108"
    },
    secondary: { main: "#F1F1F1" },
    black: {
      ...Colors.blackScale,
      main: Colors.blackScale.black100
    }
  },
  colors: Colors,
  actionFooter: {
    height: 120
  },
  leftDrawer: {
    width: 250
  },
  miniLeftDrawer: {
    width: 303,
    widthClosed: 52
  },
  extended: extended
});

export default muiTheme;
