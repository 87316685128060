import * as types from "../actions/types";
import utils from "../utils/Util";

const initialState = {
  claimants: [],
  claims: [],
  claimsListError: "",
  showClaimDialog: false,
  showClaimError: false,
  isLoading: false,
  uploadFileErrors: [],
  claimErrors: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CLAIMANT_SUCCESS: {
      return {
        ...state,
        claimants: action.claimants
      };
    }
    case types.GET_CLAIM_SUCCESS: {
      return {
        ...state,
        claims: action.claims,
        isLoading: false
      };
    }
    case types.GET_CLAIM_ERROR: {
      return {
        ...state,
        claims: action.claims,
        claimsListError: action.error,
        isLoading: false
      };
    }
    case types.LOADING_CLAIM: {
      return {
        ...state,
        isLoading: true
      };
    }
    case types.VIEW_CLAIM_DIALOG: {
      return {
        ...state,
        showClaimDialog: true
      };
    }
    case types.CLOSE_CLAIM_DIALOG: {
      return {
        ...state,
        showClaimDialog: false,
        uploadFileErrors: []
      };
    }
    case types.GET_CLAIM_ADD_SUCCESS: {
      return {
        ...state,
        showClaimDialog: false,
        showClaimError: false,
        uploadFileErrors: []
      };
    }
    case types.GET_CLAIM_ADD_ERROR: {
      return {
        ...state,
        showClaimDialog: false,
        showClaimError: true,
        claimErrors: action.errors
      };
    }
    case types.GET_CLAIM_UPLOAD_FILE_ERROR: {
      return {
        ...state,
        showClaimError: false,
        uploadFileErrors: action.errors
      };
    }
    case types.GET_CLAIM_EDIT_SUCCESS: {
      return {
        ...state,
        showClaimDialog: false,
        showClaimError: false
      };
    }
    case types.GET_CLAIM_EDIT_ERROR: {
      return {
        ...state,
        showClaimDialog: false,
        showClaimError: true
      };
    }
    case types.SHOW_CLAIM_DIALOG_ERROR: {
      return {
        ...state,
        showClaimError: true
      };
    }

    case types.CLOSE_CLAIM_DIALOG_ERROR: {
      return {
        ...state,
        showClaimError: false
      };
    }

    case types.CLEAR_UPLOAD_FILE_ERROR: {
      let uploadFileErrors = state.uploadFileErrors;
      if (uploadFileErrors && uploadFileErrors.length > 0) {
        utils.removeArrayElement(uploadFileErrors, action.index);
      }
      return {
        ...state,
        uploadFileErrors: uploadFileErrors
      };
    }

    case types.CLEAR_CLAIM_LIST_ERROR: {
      return {
        ...state,
        claimsListError: ""
      };
    }

    default:
      return state;
  }
};

export default reducer;
