import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import moment from "moment";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { eClaimActions } from "../../../actions";
import { bindActionCreators } from "redux";

import Enums from "../../../utils/Enums";
import SelectInput from "./common/SelectInput";
import TablePaginationActions from "../../../components/TablePaginationActions";

const FORMAT_DATE = "DD/MM/YYYY";

const CLAIM_STATUS_OPTIONS = [
  {
    label: "Select All",
    value: "ALL"
  },
  {
    label: "Action Needed",
    value: "ACTION_NEEDED"
  },
  {
    label: "Approved",
    value: "APPROVED"
  },
  {
    label: "On Hold",
    value: "ON_HOLD"
  },
  {
    label: "Processing",
    value: "PROCESSING"
  },
  {
    label: "Rejected",
    value: "REJECTED"
  },
  {
    label: "Submitted",
    value: "SUBMITTED"
  }
];

class ClaimList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      isHavingAnyClaims: false,
      claimStatus: "ALL",
      filteredClaimList: this.props.filteredClaimList
        ? this.props.filteredClaimList
        : []
    };
  }

  componentDidUpdate() {
    if (
      this.props.filteredClaimList !== this.state.filteredClaimList &&
      this.state.claimStatus == "ALL"
    ) {
      this.setState({ filteredClaimList: this.props.filteredClaimList });
      if (this.props.filteredClaimList) {
        if (
          this.state.claimStatus == "ALL" &&
          this.props.filteredClaimList.length > 0 &&
          this.state.isHavingAnyClaims === false
        ) {
          this.setState({ isHavingAnyClaims: true });
        }

        if (
          this.state.claimStatus == "ALL" &&
          this.props.filteredClaimList.length === 0 &&
          this.state.isHavingAnyClaims === true
        ) {
          this.setState({ isHavingAnyClaims: false });
        }
      }
    }
  }

  formatDate = date => {
    return (date && moment(date, FORMAT_DATE)) || null;
  };

  handle = e => {
    let selectedClaimStatus = e.target.value;
    this.setState({ claimStatus: selectedClaimStatus }, () => {
      let params = {
        filterBy: "claim_status",
        filterValue: selectedClaimStatus,
        pageIndex: 0
      };
      this.props.fetchClaims(params);
    });
  };

  onChangePage = (event, pageIndex) => {
    this.setState({ pageIndex }, () => {
      let params = {
        filterBy: "claim_status",
        filterValue: this.state.claimStatus,
        pageIndex
      };
      this.props.fetchClaims(params);
    });
  };

  render() {
    const {
      classes,
      handleMakeClaim,
      handleViewClaim,
      list,
      getStatusText,
      user
    } = this.props;
    const { auth: { pcode } = {} } = user;
    const isPCEClient =
      pcode === Enums.P_CODE.PRU_CE || pcode === Enums.P_CODE.PRU_CE_EMULATE;
    const claimStatusOptionList = CLAIM_STATUS_OPTIONS;

    if (this.props.filteredClaimList) {
      if (
        this.props.filteredClaimList.length === 0 &&
        this.claimStatus == "ALL"
      ) {
        if (list.length >= 0) {
          this.props.filteredClaimList = list;
        }
      }

      return (
        <div>
          <div style={{ marginBottom: 20 }}>
            <div style={{ display: "flex" }}>
              <Grid container wrap="wrap" spacing={"8px"}>
                <Grid item xs={3}>
                  <Typography
                    className={classes.heading}
                    variant="h5"
                    color="inherit"
                  >
                    My Claims
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    className={classes.label}
                    align="right"
                    style={{ paddingTop: 6, paddingBottom: 7 }}
                  >
                    <strong>Claim Status</strong>
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <SelectInput
                    name={"claimStatus"}
                    value={this.state.claimStatus}
                    onChange={this.handle}
                    list={claimStatusOptionList}
                    className={classes.dropdown}
                  />
                </Grid>

                <Grid item xs={2} align="right">
                  {user.auth.scope.includes(Enums.SCOPE_TYPE.CLAIM_W) && (
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      onClick={handleMakeClaim}
                    >
                      Make A Claim
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>

            {console.log("this.state.claimStatus", this.state.claimStatus)}
            <div style={{ marginTop: 10 }}>
              {this.props.filteredClaimList == 0 &&
                this.state.isHavingAnyClaims === false &&
                list.length !== 0 && (
                  <Typography
                    className={classes.heading}
                    variant="subheading"
                    color="inherit"
                  >
                    No claim submitted yet.
                  </Typography>
                )}
              {this.props.filteredClaimList == 0 &&
                this.state.isHavingAnyClaims === true &&
                list.length !== 0 && (
                  <Typography
                    className={classes.heading}
                    variant="subheading"
                    color="inherit"
                  >
                    No Record Found
                  </Typography>
                )}

              <Grid container spacing={"8px"}>
                {this.props.filteredClaimList.length > 0 &&
                  this.props.filteredClaimList.map((data, idx) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        style={{ padding: 0 }}
                        onClick={handleViewClaim(data.id)}
                        key={idx}
                      >
                        <Card className={classes.card}>
                          <CardContent>
                            <Grid container spacing={"8px"}>
                              <Grid item xs={8}>
                                <Typography>{data.claimant}</Typography>
                                <Typography>
                                  <strong>Reference No: </strong>
                                  {data.reference_number || "-"}
                                </Typography>
                                <Typography>
                                  <strong>Claim No: </strong>
                                  {data.claim_status === "SUBMITTED"
                                    ? "-"
                                    : data.claim_no || "-"}
                                </Typography>
                                <Typography>
                                  <strong>Bill Amount: </strong>
                                  {data.currency} {data.bill_amount}
                                </Typography>
                                <Typography>
                                  <strong>Submitted Date: </strong>
                                  {data.received_date}
                                </Typography>
                                <Typography>
                                  <strong>Date of Visit: </strong>
                                  {data.date_of_consultation}
                                </Typography>
                                {data.hr_claim && (
                                  <Typography style={{ color: "coral" }}>
                                    Submitted by HR
                                  </Typography>
                                )}
                              </Grid>

                              <Grid item xs={4} style={{ textAlign: "right" }}>
                                {getStatusText(data.claim_status)}
                                <Typography>
                                  {data.pay_back_amount
                                    ? `$${data.pay_back_amount}`
                                    : ""}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
              {this.props.filteredClaimList.length !== 0 && (
                <div>
                  <TablePagination
                    className={classes.paginator}
                    colSpan={5}
                    count={this.props.filteredClaimListPaginated.total_records}
                    rowsPerPage={10}
                    page={this.props.filteredClaimListPaginated.current_page}
                    onPageChange={this.onChangePage}
                    ActionsComponent={TablePaginationActions}
                    rowsPerPageOptions={[]}
                    labelDisplayedRows={({ count, page }) => {
                      return `Page ${page + 1} - Total ${
                        count === 0 ? 1 : Math.ceil(count / 10)
                      }`;
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}
const styles = theme => ({
  card: {
    marginTop: 10
  },
  paginator: {
    float: "right"
  },
  button: {
    minWidth: 150
  },
  spacing: {
    padding: 0,
    [theme.breakpoints.down("xs")]: {
      padding: 14
    }
  }
});

function mapStateToProps(state, prop) {
  const eclaim = state.eclaim;
  const user = state.user;
  return {
    list: eclaim.claims.items,
    filteredClaimList: eclaim.claims.items,
    filteredClaimListPaginated: eclaim.claims,
    eclaim,
    user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(eClaimActions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ClaimList));
