import axios from "axios";
const PDFResource = {
  downloadPDFAsBlob: url => {
    return axios({
      method: "get",
      url: url,
      responseType: "blob"
    });
  }
};

export default PDFResource;
