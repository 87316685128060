import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";
import classNames from "classnames";
import WindowedSelect, { createFilter } from "react-windowed-select";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import { emphasize } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      variant="standard"
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      variant="standard"
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={
        props.isDisabled
          ? props.selectProps.classes.singleValueDisabled
          : props.selectProps.classes.singleValue
      }
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={event => {
        props.removeProps.onClick();
        props.removeProps.onMouseDown(event);
      }}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const IndicatorSeparator = () => null;
const DownChevron = () => (
  <svg
    fillOpacity="0.54"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
  >
    <path d="M7 10l5 5 5-5z" />
  </svg>
);
const DropdownIndicator = props => {
  const { className, cx, getStyles, innerProps } = props;
  return (
    <div
      {...innerProps}
      css={getStyles("dropdownIndicator", props)}
      className={cx(
        {
          indicator: true,
          "dropdown-indicator": true
        },
        className
      )}
    >
      <DownChevron />
    </div>
  );
};

const components = {
  DropdownIndicator,
  IndicatorSeparator,
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  MultiValue,
  ValueContainer,
  Menu
};

class SearchableSelectValidatorElement extends ValidatorComponent {
  constructor(props) {
    super(props);

    //validate if uploaded data (select box) is correct or not
    //so we reset it value to empty, otherwise the validator can not valid
    if (
      this.props.value !== "" &&
      this.props.suggestions &&
      this.props.resetValue
    ) {
      if (!this.props.suggestions.find(c => c.value === this.props.value)) {
        //reset this value
        this.props.resetValue();
      }
    }
  }

  renderValidatorComponent() {
    const {
      disabled,
      classes,
      errorMessages,
      validators,
      requiredError,
      errorText,
      helperText,
      validatorListener,
      resetValue,
      id,
      label,
      suggestions,
      theme,
      value,
      ...rest
    } = this.props;
    const { isValid } = this.state;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary
      })
    };

    const valueObj = suggestions.find(c => c.value === value);

    return (
      <FormControl fullWidth margin="normal" variant="standard">
        <WindowedSelect
          classes={classes}
          styles={selectStyles}
          isDisabled={disabled}
          placeholder="Select One"
          // ignoreAccents skips 'stripDiacritics', which takes lot of time to process long options
          filterOption={createFilter({ ignoreAccents: false })}
          textFieldProps={{
            label,
            InputLabelProps: {
              shrink: true,
              className: classNames(!isValid && classes.labelError)
            }
          }}
          options={suggestions}
          components={components}
          isClearable
          value={valueObj}
          {...rest}
        />
        <FormHelperText className={classNames(!isValid && classes.error)}>
          {this.errorText(helperText)}
        </FormHelperText>
      </FormControl>
    );
  }

  errorText(helperText = "") {
    const { isValid } = this.state;
    if (isValid) {
      return helperText;
    }

    return <span>{this.getErrorMessage()}</span>;
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: "flex",
    padding: "10px"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center"
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  singleValueDisabled: {
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.54)"
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
    color: "black"
  },
  paper: {
    marginTop: theme.spacing.unit
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  error: {
    color: "#f44336"
  },
  labelError: {
    color: "#f44336"
  }
});

export default withStyles(styles, { withTheme: true })(
  SearchableSelectValidatorElement
);
