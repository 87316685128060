import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Plans from "../../../components/Plans";

const styles = theme => ({
  root: {},
  statusPaper: {
    margin: `${theme.spacing.unit * 2}px 0`
  },
  statusP: {
    padding: theme.spacing.unit * 2
  }
});

class MyCoverage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoPopover: {
        open: false,
        element: null,
        data: ""
      }
    };
  }

  handleClickInfoButton = (data, id) => event => {
    let infoPopover = {
      ...this.state.infoPopover
    };
    infoPopover.open = !infoPopover.open;
    if (infoPopover.open) {
      infoPopover.element = this.refs[id];
      infoPopover.data = data;
    }
    this.setState({ infoPopover });
  };

  handleCloseDialog() {
    let infoPopover = {
      ...this.state.infoPopover,
      open: false
    };
    this.setState({ infoPopover });
  }

  render() {
    const {
      classes,
      selectedTypes,
      selectedTiers,
      extendedBenefit,
      policyEffectiveDate,
      selectionDisplayMode,
      productCode,
      planSumAssured
    } = this.props;

    return (
      <div className={classes.root}>
        {/*<Paper elevation={4} className={classes.statusPaper}>
          <Typography component="div" className={classes.statusP}>
            Underwriting Status{" "}
            <InfoButton
              ref="info1"
              id="info1"
              data={
                "<ul>" +
                "<li><strong>Approved</strong> - Coverage is approved / Underwriting is not required.</li>" +
                "<li><strong>Pending </strong>- Coverage is pending underwriting. Employees below the age of 65 will be covered up to $200,000 for Group Term Life and $100,000 for Group Crisis Cover Accelerated (where applicable). Coverage for employees 65 and older will only be effected after they have been successfully underwritten.</li>" +
                "<li><strong>Limited </strong>- Coverage is limited.</li>" +
                "<li><strong>Rejected</strong> - Coverage has been declined.</li>" +
                "</ul>"
              }
              handleClickInfoButton={this.handleClickInfoButton}
            />{" "}
            &nbsp;:&nbsp;
            <span>{underwriting_status}</span>
          </Typography>
        </Paper>*/}

        {/*{mobileDetect.phone() !== null ? (
          <SimpleMessageDialog
            isHtml={true}
            type="none"
            name="info"
            title="Info"
            description={infoPopover.data}
            open={infoPopover.open}
            closeHandler={this.handleCloseDialog.bind(this)}
          />
        ) : (
          <InfoPopover
            open={infoPopover.open}
            data={infoPopover.data}
            element={infoPopover.element}
            handleClickInfoButton={this.handleClickInfoButton}
          />
        )}*/}

        <Plans
          types={selectedTypes}
          tiers={selectedTiers}
          extendedBenefit={extendedBenefit}
          policyEffectiveDate={policyEffectiveDate}
          selectionDisplayMode={selectionDisplayMode}
          displayAll={false}
          productCode={productCode}
          planSumAssured={planSumAssured}
        />
      </div>
    );
  }
}

MyCoverage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MyCoverage);
