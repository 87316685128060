import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withStyles } from "@mui/styles";
import assets from "../../../assets";
import Button from "@mui/material/Button";
import callBackService from "../../../services/CallBackService";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import classNames from "classnames";
import Grid from "@mui/material/Grid";
import LoadingPopup from "../../../components/LoadingPopup";
import React, { Component } from "react";
import RequestCallBackModal from "../../../components/RequestCallBackModal";
import SimpleMessageDialog from "../../../components/SimpleMessageDialog";
import Typography from "@mui/material/Typography";

class Specialist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerDocDocRequestACallbackDialog: false,
      triggerResultDialog: false,
      triggerLoadingPopup: false,
      /**
       * Se default response is success
       */
      responseType: "success",
      initData: null
    };

    this.callbackSubmitForm = {
      docdoc: {
        submit: data => {
          this.setState({
            triggerLoadingPopup: true,
            triggerDocDocRequestACallbackDialog: false
          });
          callBackService.sendEmailRequestCallBack(data, "docdoc").then(
            respose => {
              if (respose.success) {
                this.setState({ triggerLoadingPopup: false });
                this.showPopupResult("success");
              }
            },
            error => {
              this.setState({ triggerLoadingPopup: false });
              this.showPopupResult("error");
            }
          );
        }
      }
    };
  }

  /**
   * After sent email success.
   * Show the popup success.
   * We need to close all the popups are opening
   */
  showPopupResult = responseType => {
    this.setState({
      // Show success dialog
      triggerResultDialog: true,
      // Response type: success || error
      responseType: responseType,
      // Name of partner: Validus, DocDoc, Wellteq
      partnerName: "DocDoc",
      // Close all popups are opening
      triggerDocDocRequestACallbackDialog: false
    });
  };

  openCallBackPopup = () => {
    this.setState({ triggerDocDocRequestACallbackDialog: true });
  };

  componentDidMount() {
    /**
     * Check if we have data of current user
     * Then set it to initData for Dialog Request Call Back
     */
    /**
     * TODO
     * Warning: `value` prop on `input` should not be null. Consider using an empty string to clear the component or `undefined` for uncontrolled components.
     */
    if (this.props.user) {
      let firstname = this.props.user.firstname
          ? this.props.user.firstname
          : "",
        lastname = this.props.user.lastname ? this.props.user.lastname : "",
        email = this.props.user.email ? this.props.user.email : "",
        fullName = this.props.user.fullName ? this.props.user.fullName : "";

      this.setState({
        initData: {
          contact_name: fullName
            ? fullName
            : firstname.length > 0 || lastname.length > 0
            ? firstname + " " + lastname
            : "",
          contact_email: email,
          /**
           * We don't have number phone for Employee
           * They have to enter it.
           */
          contact_no: ""
        }
      });
    }
  }

  closePopupResult = () => {
    this.setState({ triggerResultDialog: false });
  };

  call = numberPhone => {
    window.location.href = `tel:${numberPhone}`;
  };

  render() {
    const { classes, intl } = this.props;
    return (
      <div className={classes.content}>
        <img
          src={assets.images.docdoc_logo}
          alt="DocDoc Logo"
          className={classes.logo}
        />
        <Typography variant="title" className={classes.betterWayTitle}>
          Easy Access to Medical Specialists
        </Typography>
        <Typography variant="subheading" className={classes.subHeading}>
          Call us for a Free Discovery Consultation on Monday – Friday between
          8am to 6.30pm and Saturday 9am to 2pm. You can also request a
          callback. *
        </Typography>
        <div className={classes.subDesc}>
          * Your phone call will be made to a third party service provider for a
          free discovery consultation
        </div>
        <div className={classes.action}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => this.call("+6568714111")}
          >
            +65-6871-4111
          </Button>
          <Button
            variant="contained"
            className={classNames(classes.button, classes.buttonSecond)}
            onClick={this.openCallBackPopup}
          >
            Request a Callback
          </Button>
        </div>
        <div className={classes.claimContainer}>
          <Card className={classes.card}>
            <CardHeader
              title="Follow these steps to ensure that you can claim for your specialist visit"
              classes={{
                root: classes.claimHeader,
                title: classes.claimTitle
              }}
            />
            <CardContent
              classes={{
                root: classes.claimContent
              }}
            >
              <Grid container spacing={"0px"}>
                <Grid
                  item
                  xs={4}
                  sm={2}
                  md={2}
                  lg={2}
                  className={classes.claimIconContainer}
                >
                  <img
                    src={assets.icons.claimDoctor}
                    alt="DocDoc Claim"
                    className={classes.claimDoc}
                  />
                </Grid>
                <Grid item xs={8} sm={10} md={10} lg={10}>
                  <div className={classes.stepsContent}>
                    <div className={classes.step}>
                      <Typography
                        variant="title"
                        className={classes.stepHeader}
                      >
                        Step 1
                      </Typography>
                      <Typography
                        variant="subheading"
                        className={classes.stepDescription}
                      >
                        Call DocDoc to get a list of 3 specialists who are
                        relevant to your medical situation
                      </Typography>
                    </div>
                    <div className={classes.step}>
                      <Typography
                        variant="title"
                        className={classes.stepHeader}
                      >
                        Step 2
                      </Typography>
                      <Typography
                        variant="subheading"
                        className={classes.stepDescription}
                      >
                        Visit a GP with your list of specialists and request for
                        a referral letter
                      </Typography>
                    </div>
                    <div className={classes.step}>
                      <Typography
                        variant="title"
                        className={classes.stepHeader}
                      >
                        Step 3
                      </Typography>
                      <Typography
                        variant="subheading"
                        className={classes.stepDescription}
                      >
                        Visit the specialist with your referral letter
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <RequestCallBackModal
          initData={this.state.initData}
          policyMessageID="benefit.requestACallBack.docdoc.policy"
          open={this.state.triggerDocDocRequestACallbackDialog}
          callback={this.callbackSubmitForm.docdoc}
        />
        <SimpleMessageDialog
          type={this.state.responseType}
          name={`request-callback-` + this.state.responseType}
          title={intl.formatMessage({
            id: `benefit.requestACallBack.${this.state.responseType}.title`
          })}
          description={intl.formatMessage(
            {
              id: `benefit.requestACallBack.docdoc.${this.state.responseType}.description`
            },
            { partnerName: this.state.partnerName }
          )}
          closeButtonText={intl.formatMessage({ id: "popup.button.ok" })}
          closeHandler={this.closePopupResult}
          open={this.state.triggerResultDialog}
        />
        <LoadingPopup name="docdoc" open={this.state.triggerLoadingPopup} />
      </div>
    );
  }
}
const styles = theme => ({
  content: {
    paddingTop: 30
  },
  buttonSecond: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.colors.primary.red}`,
    color: theme.colors.primary.red,
    boxShadow: "none",
    padding: "10px 25px"
  },
  iconSuccess: {
    color: `${theme.colors.error}`,
    fontSize: "3rem"
  },
  iconError: {
    color: `${theme.colors.error}`,
    fontSize: "3rem"
  },
  logo: {
    maxWidth: 200
  },
  subHeading: {
    fontWeight: 500
  },
  subDesc: {
    color: theme.colors.blackScale.black90,
    paddingTop: 5,
    fontSize: 12
  },
  betterWayTitle: {
    fontWeight: 500,
    paddingTop: 20,
    paddingBottom: 20
  },
  button: {
    minWidth: 175,
    marginTop: 30,
    marginRight: 15
  },
  btnFindADoctor: {
    backgroundColor: "transparent",
    color: theme.colors.primary.red,
    fontWeight: 500,
    border: `2px solid ${theme.colors.primary.red}`
  },
  claimContainer: {
    marginTop: 30,
    maxWidth: "900px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%"
    }
  },
  claimContent: {
    backgroundColor: theme.colors.blackScale.black50,
    "&:last-child": {
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  claimHeader: {
    backgroundColor: theme.colors.blackScale.black70
  },
  claimTitle: {
    color: theme.colors.secondary.white,
    fontSize: 15
  },
  claimIconContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  claimDoc: {
    maxWidth: 96
  },
  step: {
    paddingBottom: 15
  },
  stepsContent: {
    padding: theme.spacing.unit
  },
  stepHeader: {
    fontWeight: 500
  },
  stepDescription: {
    fontWeight: 500
  }
});

function mapStateToProps(state, prop) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(Specialist))
);
