import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userActions } from "../../actions";
import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import LoadingPopup from "../../components/LoadingPopup";
import Util from "../../utils/Util";

class EmulatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: true,
      isError: false
    };
  }

  handleEmulateSuccess = () => {
    this.setState({
      isProcessing: false,
      isError: false
    });
    this.props.history.push("/auth/myClaims");
  };

  handleEmulateFailure = () => {
    this.setState({
      isProcessing: false,
      isError: true
    });
  };

  componentDidMount() {
    console.log(this.props.user);
    const { match, emulateLogin } = this.props;
    const emulateRef = match.params.ref;
    const token = match.params.token;

    if (Util.isValidUUID(emulateRef)) {
      emulateLogin(emulateRef, token)
        .then(this.handleEmulateSuccess)
        .catch(this.handleEmulateFailure);
    } else {
      this.handleEmulateFailure();
    }
  }

  render() {
    const { classes } = this.props;
    const { isProcessing, isError } = this.state;
    return (
      <div>
        {isError && (
          <Paper className={classes.root} elevation={4}>
            <Typography variant="headline" component="h3">
              Oops!
            </Typography>
            <Typography component="p" className={classes.pSpacing}>
              Sorry, there was a problem while processing the request. Please
              try again.
            </Typography>
          </Paper>
        )}
        <LoadingPopup name="emulate-employee" open={isProcessing} />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing.unit * 3
  },
  pSpacing: {
    margin: `${theme.spacing.unit}px 0`
  }
});

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(userActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(EmulatePage));
