import React from "react";
import Dropzone from "react-dropzone";
import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

const onDropAcceptedWrapper = (
  onIncorrectFilename,
  onDropAccepted
) => files => {
  onDropAccepted(files);
};

const DropZone = ({
  classes,
  accept,
  maxSize,
  description,
  supportTitle,
  supportTitle2,
  multiple,
  onDropRejected,
  onDropAccepted,
  onIncorrectFilename,
  errorMessage
}) => (
  <div className={classes.dropZoneContainer}>
    <Typography
      variant="caption"
      gutterBottom
      tag={"p"}
      className={classes.errorMessage}
    >
      {errorMessage}
    </Typography>
    <Dropzone
      accept={accept}
      maxSize={maxSize}
      onDropAccepted={onDropAcceptedWrapper(
        onIncorrectFilename,
        onDropAccepted
      )}
      onDropRejected={onDropRejected}
      className={classes.dropZone}
      multiple={multiple}
    >
      {({ getRootProps, getInputProps }) => (
        <div className={classes.dropZoneInfoWrapper} {...getRootProps()}>
          <input {...getInputProps()} />
          <div>
            <PhotoCameraIcon className={classes.uploadIcon} />
          </div>
          <Typography
            variant="caption"
            gutterBottom
            tag={"p"}
            className={classes.supportMessage}
          >
            {description}
          </Typography>
        </div>
      )}
    </Dropzone>
    <Typography
      variant="caption"
      gutterBottom
      tag={"p"}
      className={classes.supportMessage}
    >
      {supportTitle}
      <br />
      {supportTitle2}
    </Typography>
  </div>
);

const styles = theme => ({
  dropZoneContainer: {
    margin: "10px 0px"
  },
  subTitle: {
    fontSize: 13
  },
  dropZone: {
    borderWidth: "2px",
    borderColor: "rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: "5px",
    padding: "10px"
  },
  dropZoneInfoWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "50px",
    textAlign: "center",
    border: "5px dotted",
    padding: "10px",
    borderStyle: "dashed",
    borderWidth: "2px",
    borderRadius: "5px",
    cursor: "pointer",
    borderColor: "#333333"
  },
  uploadIcon: {
    height: "25px",
    width: "25px",
    margin: "0 10px"
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginBottom: 10
  },
  supportMessage: {
    marginTop: 10,
    fontSize: "0.9rem"
  }
});

export default withStyles(styles, { withTheme: true })(DropZone);
