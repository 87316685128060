import Enums from "./Enums.js";

const PolicyCompDisabledConfig = {
  PROD: {
    "00104427": [Enums.COMPONENT.FIND_DOC_SP_TAB]
  },
  UAT: {
    "00104301": [Enums.COMPONENT.FIND_DOC_SP_TAB]
  },
  SIT: {
    "00104301": [Enums.COMPONENT.FIND_DOC_SP_TAB]
  },
  LOCAL: {
    "00104301": [Enums.COMPONENT.FIND_DOC_SP_TAB]
  }
};

export default PolicyCompDisabledConfig;
