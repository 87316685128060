import { APIResource, APIError, APIResponse } from "../utils/APIResource";

export default class CallBackService {
  static sendEmailRequestCallBack(data, partnerKey) {
    return APIResource.post(`/v1/partner/${partnerKey}/callback`, data, {})
      .then(resp => {
        return Promise.resolve(new APIResponse(resp));
      })
      .catch(error => {
        return Promise.reject(new APIError(error));
      });
  }
}
