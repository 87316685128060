import * as actionTypes from "./types";

export const appActions = {
  reset,
  setError
};

function reset() {
  return { type: actionTypes.APP_RESET };
}
function setError(error) {
  return {
    type: actionTypes.APP_SET_ERROR,
    payload: error
  };
}
