import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import muiTheme from "../themes";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import Drawer from "@mui/material/Drawer";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { withRouter } from "react-router";
import { userActions } from "../actions";
import Avatar from "@mui/material/Avatar";
import {
  getAvaliableProductBenefits,
  isGrabGroupEmployee
} from "../selectors/CommonSelectors";

class MiniLeftDrawer extends Component {
  constructor(props) {
    super(props);
    let { routes } = props;
    this.routes = routes;

    this.state = {
      listBenefits: getAvaliableProductBenefits(props.agentType)
    };
  }

  onLogoutClick = () => {
    this.props.logout();
    this.props.history.push("/");
  };

  renderIcon = menu => {
    const { classes } = this.props;
    return menu.icon !== undefined ? (
      <Icon>{menu.icon}</Icon>
    ) : (
      <Avatar
        src={menu.image}
        alt={menu.name}
        className={classes.iconImageWrapper}
        imgProps={{ className: classes.iconImage }}
      />
    );
  };

  render() {
    const { user, drawerOpen, classes, location, theme } = this.props;
    const { listBenefits } = this.state;
    const filteredRoute = this.routes.filter(
      route =>
        user.auth.scope.includes(route.access) || route.access === undefined
    );
    const userAvatar = (
      <div
        className={classNames(
          classes.profile,
          !drawerOpen && classes.profileClose
        )}
      >
        <Typography className={classes.profileText}>{user.fullName}</Typography>
        <div className={classes.profileActions}>
          <Button
            variant="contained"
            color="primary"
            to={"/auth/myInfo"}
            onClick={this.props.handleDrawerClose}
            component={props => <Link {...props} />}
            className={classes.button}
          >
            My Info
          </Button>
        </div>
        <div className={classes.profileActions}>
          <Button
            className={classes.logout}
            color="primary"
            onClick={this.onLogoutClick}
          >
            Log out
          </Button>
        </div>
      </div>
    );

    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          )
        }}
        open={drawerOpen}
      >
        <div className={classes.drawerInner}>
          <MenuList>
            <MenuItem className={classes.menuItemInner}>
              <div
                className={classNames(
                  classes.drawerHeader,
                  !drawerOpen && classes.drawerHeaderClose
                )}
                onClick={this.props.handleMenuToggle}
              >
                <ListItemText
                  className={classNames(
                    !drawerOpen && classes.drawerTitleClose
                  )}
                />
                {!drawerOpen ? <ChevronRight /> : <ChevronLeft />}
              </div>
            </MenuItem>
          </MenuList>

          {userAvatar}

          <MenuList style={{ marginTop: 20 }}>
            {filteredRoute.map((child, index) => {
              if (child.menu && child.menu.show) {
                // if (
                //   child.menu.validate &&
                //   child.menu.validate.employeeBenefit &&
                //   (Util.isEmpty(listBenefits) ||
                //     isGrabGroupEmployee(this.props.user))
                // ) {
                //   return null;
                // }
                return (
                  <Link
                    key={index}
                    to={child.path}
                    className={classes.menuItem}
                  >
                    <MenuItem
                      className={classNames(
                        location.pathname === child.path ||
                          (location.pathname.includes("dashboard") &&
                            child.path.includes("dashboard"))
                          ? classes.menuItemActive
                          : ""
                      )}
                    >
                      <ListItemIcon className={classes.icon}>
                        {this.renderIcon(child.menu)}
                      </ListItemIcon>
                      <ListItemText
                        primary={child.name}
                        classes={{ primary: classes.itemText }}
                        className={classes.itemTextWrapper}
                      />
                    </MenuItem>
                  </Link>
                );
              } else return null;
            })}
          </MenuList>
        </div>
        <div style={{ flexGrow: 1 }} />
        {drawerOpen && (
          <div className={classes.infoContainer}>
            <Paper className={classes.paperRoot} elevation={0}>
              <div className={classes.callContent}>
                <Button
                  href="tel:1800 835 9733"
                  variant="fab"
                  color="primary"
                  aria-label="add"
                >
                  <Icon>phone</Icon>
                </Button>
                <div className={classes.callInfo}>
                  <Typography className={classes.helpText}>
                    Call PruCustomer Line
                  </Typography>
                  <a
                    href="tel:1800 835 9733"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      href="tel:1800 835 9733"
                      color="primary"
                      className={classes.phoneText}
                    >
                      1800 835 9733
                    </Typography>
                    <Typography className={classes.helpText}>
                      (Mon-Fri, 8:30am to 5:30pm)
                    </Typography>
                  </a>
                  <a
                    href="tel:+65 6233 3299"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      href="tel:+65 6233 3299"
                      color="primary"
                      className={classes.phoneText}
                    >
                      +65 6233 3299
                    </Typography>
                    <Typography className={classes.helpText}>
                      (if you're dialling from overseas)
                    </Typography>
                  </a>
                </div>
              </div>

              <div className={classes.emailContent}>
                <Typography className={classes.emailUsText}>
                  Email us at
                </Typography>
                <a
                  href="mailto:SGP.EB.Enquiry@prudential.com.sg"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    href="mailto:SGP.EB.Enquiry@prudential.com.sg"
                    color="primary"
                    className={classes.emailText}
                  >
                    SGP.EB.Enquiry@prudential.com.sg
                  </Typography>
                </a>
              </div>

              <div className={classes.policyContainer}>
                <Typography component="p" className={classes.policyText}>
                  POLICY NUMBER:{" "}
                </Typography>
                {user.policy && user.policy.policy_no && (
                  <Typography component="p" className={classes.policyNumber}>
                    {user.policy.policy_no}{" "}
                  </Typography>
                )}
              </div>
            </Paper>
            <Typography component="p" className={classes.tncBtn}>
              <a
                className={classes.tncBtn}
                href="/termsAndConditions"
                target="_blank"
              >
                Terms & Conditions
              </a>
            </Typography>
          </div>
        )}
        <div style={{ height: 60 }} />
      </Drawer>
    );
  }
}

const styles = theme => ({
  menuItem: {
    textDecoration: "none"
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: "1",
    maxHeight: 172,
    transition: "all 0.2s linear"
  },
  profileClose: {
    opacity: "0",
    maxHeight: 0
  },
  profileAvatar: {
    width: 80,
    height: 80
  },
  profileText: {
    textAlign: "center",
    fontSize: 24,
    padding: 10,
    width: "80%"
  },
  profileActions: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around"
  },
  drawerTitleClose: {
    display: "none"
  },
  drawerPaper: {
    position: "fixed",
    marginTop: `64px`,
    height: "calc(100% - 64px)",
    width: theme.miniLeftDrawer.width,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    width: 52,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: theme.miniLeftDrawer.width
  },
  drawerHeader: {
    minHeight: "auto",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between"
  },
  drawerHeaderClose: {
    justifyContent: "flex-start"
  },
  menuItemAvatar: {
    width: 32,
    height: 32,
    maxHeight: 32,
    opacity: 1,
    transition: "all 0.2s ease-out"
  },
  showItem: {
    maxHeight: 24,
    opacity: 1
  },
  hideItem: {
    maxHeight: 0,
    opacity: 0
  },
  menuItemActive: {
    backgroundColor: "#f2f2f2 !important",
    borderRight: "7px solid red"
  },
  button: {
    marginTop: 10,
    width: 250,
    fontSize: 20
  },
  logout: {
    marginTop: 10,
    fontWeight: "bold",
    width: 250,
    fontSize: 20
  },
  paperRoot: {
    border: "solid 1px #E4E3E4",
    backgroundColor: "#F6F6F6",
    borderRadius: 10,
    margin: "auto",
    marginBottom: 20,
    padding: 5,
    textAlign: "center",
    width: "80%"
  },
  helpText: {
    "font-size": 11,
    color: "#6A6B6D",
    textAlign: "center"
  },
  phoneText: {
    "font-weight": 600,
    "font-size": 16
  },
  iconImageWrapper: {
    overflow: "unset",
    width: "unset",
    height: "unset"
  },
  iconImage: {
    width: 24,
    height: 25
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  callContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: theme.extended.isIE() ? "space-around" : "space-evenly",
    margin: theme.spacing.unit,
    paddingBottom: "14px",
    backgroundColor: muiTheme.colors.blackScale.black50,
    borderBottom: "solid 1px " + muiTheme.colors.blackScale.black60
  },
  callText: {
    color: "#6A6B6D",
    textAlign: "center",
    fontSize: 11
  },
  callNumber: {
    color: "#ED1B24",
    textAlign: "center",
    fontSize: 20,
    lineHeight: 1.56,
    letterSpacing: 0.5,
    fontWeight: 600
  },
  policyContainer: {
    display: "flex",
    justifyContent: theme.extended.isIE() ? "space-around" : "space-evenly",
    margin: `15px 30px `,
    fontSize: 14
  },
  policyText: {
    color: "#6A6B6D"
  },
  policyNumber: {
    fontWeight: "600"
  },
  emailContent: {
    alignItems: "center",
    justifyContent: theme.extended.isIE() ? "space-around" : "space-evenly",
    margin: theme.spacing.unit,
    paddingBottom: "14px",
    backgroundColor: muiTheme.colors.blackScale.black50,
    borderBottom: "solid 1px " + muiTheme.colors.blackScale.black60,
    "overflow-wrap": "normal"
  },
  emailUsText: {
    "font-size": 11,
    color: "#6A6B6D",
    textAlign: "center"
  },
  emailText: {
    "font-weight": 600,
    "font-size": 12
  }
});

function mapStateToProps(state) {
  return {
    agentType: state.user.auth.pcode,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(userActions.logout())
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles, { withTheme: true })(MiniLeftDrawer))
);
