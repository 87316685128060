import Enums from "../../../../utils/Enums";

const GPCGBenefits = {
  headers: [
    {
      title: `Benefits / Plan Type`,
      mandatory: true,
      attributes: {
        style: {
          width: "30%"
        }
      }
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>Group Pre Crisis Guard</strong>`,
          dependentData: `<strong>Group Pre Crisis Guard</strong>`
        },
        {
          data: `S$ 75,000`,
          dependentData: `S$ 32,500`,
          attributes: {
            colspan: 2
          }
        },
        {
          data: `S$ 30,000`,
          dependentData: `S$ 15,000`,
          attributes: {
            colspan: 2
          }
        },
        {
          data: `S$ 22,500`,
          dependentData: `S$ 11,250`,
          attributes: {
            colspan: 1
          }
        },
        {
          data: `S$ 12,000`,
          dependentData: `S$ 6,000`,
          attributes: {
            colspan: 1
          }
        },
        {
          data: `S$ 7,500`,
          dependentData: `S$ 3,750`,
          attributes: {
            colspan: 2
          }
        }
      ]
    }
  ],
  footnotes: []
};

let GPCGPremium = {
  headers: [
    {
      title: `Age Last Birthday`,
      mandatory: true
    },
    {
      title: `Plan 1`,
      tier: 1
    },
    {
      title: `Plan 2`,
      tier: 2
    },
    {
      title: `Plan 3`,
      tier: 3
    },
    {
      title: `Plan 4`,
      tier: 4
    },
    {
      title: `Plan 5`,
      tier: 5
    }
  ],
  rows: [
    {
      id: 1,
      columns: [
        {
          data: `<strong>16 - 19</strong>`
        },
        {
          data: `S$ 175.00`
        },
        {
          data: `S$ 70.00`
        },
        {
          data: `S$ 53.00`
        },
        {
          data: `S$ 28.00`
        },
        {
          data: `S$ 18.00`
        }
      ]
    },
    {
      id: 2,
      columns: [
        {
          data: `<strong>20 - 24</strong>`
        },
        {
          data: `S$ 175.00`
        },
        {
          data: `S$ 70.00`
        },
        {
          data: `S$ 53.00`
        },
        {
          data: `S$ 28.00`
        },
        {
          data: `S$ 18.00`
        }
      ]
    },
    {
      id: 3,
      columns: [
        {
          data: `<strong>25 - 29</strong>`
        },
        {
          data: `S$ 175.00`
        },
        {
          data: `S$ 70.00`
        },
        {
          data: `S$ 53.00`
        },
        {
          data: `S$ 28.00`
        },
        {
          data: `S$ 18.00`
        }
      ]
    },
    {
      id: 4,
      columns: [
        {
          data: `<strong>30 - 34</strong>`
        },
        {
          data: `S$ 243.00`
        },
        {
          data: `S$ 97.00`
        },
        {
          data: `S$ 73.00`
        },
        {
          data: `S$ 35.00`
        },
        {
          data: `S$ 25.00`
        }
      ]
    },
    {
      id: 5,
      columns: [
        {
          data: `<strong>35 - 39</strong>`
        },
        {
          data: `S$ 390.00`
        },
        {
          data: `S$ 156.00`
        },
        {
          data: `S$ 117.00`
        },
        {
          data: `S$ 63.00`
        },
        {
          data: `S$ 39.00`
        }
      ]
    },
    {
      id: 6,
      columns: [
        {
          data: `<strong>40 - 44</strong>`
        },
        {
          data: `S$ 633.00`
        },
        {
          data: `S$ 253.00`
        },
        {
          data: `S$ 190.00`
        },
        {
          data: `S$ 102.00`
        },
        {
          data: `S$ 64.00`
        }
      ]
    },
    {
      id: 7,
      columns: [
        {
          data: `<strong>45 - 49</strong>`
        },
        {
          data: `S$ 1,143.00`
        },
        {
          data: `S$ 457.00`
        },
        {
          data: `S$ 343.00`
        },
        {
          data: `S$ 183.00`
        },
        {
          data: `S$ 115.00`
        }
      ]
    },
    {
      id: 8,
      columns: [
        {
          data: `<strong>50 - 54</strong>`
        },
        {
          data: `S$ 1,985.00`
        },
        {
          data: `S$ 794.00`
        },
        {
          data: `S$ 596.00`
        },
        {
          data: `S$ 318.00`
        },
        {
          data: `S$ 199.00`
        }
      ]
    },
    {
      id: 9,
      columns: [
        {
          data: `<strong>55 - 59</strong>`
        },
        {
          data: `S$ 3,075.00`
        },
        {
          data: `S$ 1,230.00`
        },
        {
          data: `S$ 923.00`
        },
        {
          data: `S$ 492.00`
        },
        {
          data: `S$ 308.00`
        }
      ]
    },
    {
      id: 10,
      columns: [
        {
          data: `<strong>60 - 64</strong>`
        },
        {
          data: `S$ 4,688.00`
        },
        {
          data: `S$ 1,875.00`
        },
        {
          data: `S$ 1,407.00`
        },
        {
          data: `S$ 750.00`
        },
        {
          data: `S$ 469.00`
        }
      ]
    },
    {
      id: 11,
      columns: [
        {
          data: `<strong>65 - 69</strong>`,
          info: `<em>For renewals only</em>`
        },
        {
          data: `S$ 6,250.00`
        },
        {
          data: `S$ 2,515.00`
        },
        {
          data: `S$ 1,860.00`
        },
        {
          data: `S$ 1,000.00`
        },
        {
          data: `S$ 610.00`
        }
      ]
    }
  ],
  footnotes: [
    {
      data: `Note: Coverage for Group Crisis Cover Accelerated benefit ceases at age 70 last birthday.`
    }
  ]
};

//   ----------------------------------------------------------------------
const GEAC = {
  id: 1,
  code: "GEAC",
  label: "Group Pre Crisis Guard (GPCG)",
  types: [
    {
      id: 1,
      order: 1,
      type: Enums.PLANS_TYPE.BENEFITS,
      label: "Plans by Benefits",
      data: [GPCGBenefits]
    },
    {
      id: 2,
      order: 2,
      type: Enums.PLANS_TYPE.PREMIUMS,
      label: "Plans by Premium",
      data: [GPCGPremium]
    }
  ]
};

export default GEAC;
